import React, { useState } from 'react';
import './MailBoxPage.css';
import Sidebar from '../../components/Sidebar/Sidebar';

import MailboxList from './components/MailboxList';
import MailBoxElement from './components/MailBoxElement';
import ReturnButton from '../../components/Buttons/ReturnButton/ReturnButton';
import { Link, useNavigate } from 'react-router-dom';
import Store from '../../../Store';

const MailBoxPage = () => {
    const navigate = useNavigate();
    const [conversation, setConversation] = useState();

    return (
        <div className="mailbox-user-wrapper">
            <div className="mailbox-user-top__wrapper">
                <h3 className="mailbox-user-top__text">{Store.getText('inboxHeader')}</h3>
                <ReturnButton
                    className="mailbox-user-top__return-button"
                    onClick={() => navigate(-1)}
                />
                <Sidebar
                    isSidebarExpandable
                    darkBg
                    // setError={props.setError}
                    // pairId={currentLanguagePair.id}
                ></Sidebar>
            </div>
            {!conversation && <MailboxList setConversation={setConversation} />}
            {conversation && (
                <MailBoxElement
                    conversation={conversation}
                    setConversation={setConversation}
                />
            )}
        </div>
    );
};

export default MailBoxPage;
