function getLevelColor(level) {
  switch (level) {
    case 1:
      return "var(--first-level-color)";
    case 2:
      return "var(--second-level-color)";
    case 3:
      return "var(--third-level-color)";
  }
}

export default getLevelColor;
