import React from "react";
import styled from "styled-components";
import background from "../../../images/zdj_4.jpg";
import numberCloud from "../../../images/dymek_cyfra.png";
import whiteCloudUpRight from "../../../images/boxes/dymek_bialy_prawo_gora.png";
import { Colors } from "../../../colors/Colors";

const Photo = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
`;
const ColorChanger = styled.div`
  background: rgba(36, 71, 35, 0.7);
  height: 100%;
  display: grid;
  min-height: 100vh;
`;

const Cloud = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0px 30px 0px 10px;
`;

const Flex = styled.div`
  display: flex;
  margin: 30px 30px 30px 100px;
  justify-content: space-between;
`;

const Flex3 = styled.div`
  display: flex;
  margin: 30px 30px 100px 100px;
  flex-wrap: wrap;
`;

const H1 = styled.h1`
  font-weight: lighter;
  font-size: 30px;
  max-width: 200px;
  margin: 0px;
  padding: 10px 0 10px 0px;
  text-align: center;
  color: ${Colors.darkDarkBlue};
`;

const Flex2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  margin: 0px 6%;
  justify-content: space-around;
  color: white;

  @media (max-width: 1200px) {
    gap: 0px;
  }
`;

const CloudWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 40px 0 0;
`;

const H4 = styled.div`
  color: white;
  font-size: 20px;
  padding-bottom: 10px;
`;

const H1__2 = styled.h1`
  padding: 4px 17px 0 17px;
  text-align: center;
  color: ${Colors.darkDarkBlue};
`;

const Moblie = styled.div`
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`;

const Desktop = styled.div`
  display: block;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const FirstDiv = styled.div`
  display: flex;
  margin-top: -150px;

  @media (max-width: 1200px) {
    margin-top: 0px;
  }
`;

const ThirdDiv = styled.div`
  display: flex;
  margin-top: -100px;

  @media (max-width: 1200px) {
    margin-top: 0px;
  }
`;

const FourthDiv = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    margin-top: 10px;
  }
`;

const Flex__1 = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  color: white;
  margin: 0 100px;

  @media (max-width: 1200px) {
    margin: 0 10px;
  }
`;

const Flex__2 = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0 200px;
  color: white;

  @media (max-width: 1800px) {
    margin: 0 100px;
  }

  @media (max-width: 1200px) {
    margin: 0 10px;
  }
`;

const TextWrapper = styled.div`
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const Why = ({ data }) => {
  return (
    <Photo
      img={background}
      style={{ scrollMarginTop: "28px" }}
      name="why"
      id="why"
    >
      <ColorChanger>
        <Desktop>
          <Flex>
            <div>
              <p style={{ maxWidth: "800px", color: "white" }}>{data.textUp}</p>
            </div>
            <div>
              <Cloud img={whiteCloudUpRight}>
                <H1>{data.title}</H1>
              </Cloud>
            </div>
          </Flex>
        </Desktop>
        <Moblie>
          <div
            style={{
              margin: "30px",
              maxWidth: "1200px",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <div style={{ maxWidth: "200px" }}>
              <Cloud img={whiteCloudUpRight}>
                <H1>{data.title}</H1>
              </Cloud>
            </div>
          </div>
          <div
            style={{
              margin: "20px",
            }}
          >
            <p style={{ maxWidth: "800px", color: "white" }}>{data.textUp}</p>
          </div>
        </Moblie>

        <TextWrapper>
          <Flex__1>
            <div
              style={{
                display: "flex",
              }}
            >
              <CloudWrapper>
                <Cloud img={numberCloud}>
                  <H1__2>1</H1__2>
                </Cloud>
              </CloudWrapper>
              <div style={{ maxWidth: "500px" }}>
                <H4>{data.cards[0].headline}</H4>
                <p style={{ fontSize: "16px" }}> {data.cards[0].text}</p>
              </div>
            </div>
          </Flex__1>
          <Flex__2>
            <div
              style={{
                display: "flex",
              }}
            >
              <CloudWrapper>
                <Cloud img={numberCloud}>
                  <H1__2>2</H1__2>
                </Cloud>
              </CloudWrapper>
              <div style={{ maxWidth: "500px" }}>
                <H4>{data.cards[1].headline}</H4>
                <p style={{ maxWidth: "700px", fontSize: "16px" }}>
                  {data.cards[1].text}
                </p>
              </div>
            </div>
          </Flex__2>
          <Flex__1>
            <div
              style={{
                display: "flex",
              }}
            >
              <CloudWrapper>
                <Cloud img={numberCloud}>
                  <H1__2>3</H1__2>
                </Cloud>
              </CloudWrapper>
              <div style={{ maxWidth: "500px" }}>
                <H4>{data.cards[2].headline}</H4>
                <p style={{ maxWidth: "700px", fontSize: "16px" }}>
                  {data.cards[2].text}
                </p>
              </div>
            </div>
          </Flex__1>
          <Flex__2>
            <div
              style={{
                display: "flex",
              }}
            >
              <CloudWrapper>
                <Cloud img={numberCloud}>
                  <H1__2>4</H1__2>
                </Cloud>
              </CloudWrapper>
              <div style={{ maxWidth: "500px" }}>
                <H4>{data.cards[3].headline}</H4>
                <p style={{ maxWidth: "700px", fontSize: "16px" }}>
                  {data.cards[3].text}
                </p>
              </div>
            </div>
          </Flex__2>
        </TextWrapper>
        <div style={{ marginBottom: "50px" }}>
          <p
            style={{ maxWidth: "900px", margin: "5% 5% 0 5%", color: "white" }}
          >
            {data.textBottom}
          </p>
        </div>
      </ColorChanger>
    </Photo>
  );
};

export default Why;
