import { useNavigate } from "react-router-dom";
import Store from "../../../Store";
import api from "../../api/api";
import "./RegisterPage.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import MainButton from "../../components/Buttons/MainButton/MainButton";

function RegisterPage(props) {
  const navigate = useNavigate();
  const [inputEmail, setInputEmail] = useState("");
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordShow, setInputPasswordShow] = useState(false);
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState("");
  const [inputPasswordRepeatShow, setInputPasswordRepeatShow] = useState(false);
  const [inputKey, setInputKey] = useState("");

  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  const login = async (email, password) => {
    const res = await api.post("/auth/login", {
      email: email,
      password: password,
    });

    const token = res.data.token;
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    Store.setToken(token);
    window.gtag ('event', "login")

    // navigate("/select-language");
  };

  const handleRegisterFormSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isPasswordMatch) {
        await api.post("/user/register", {
          email: inputEmail.trim(),
          password: inputPassword,
          accessKey: inputKey,
          username: inputUsername,
        });

        await login(inputEmail, inputPassword);
        navigate("/platform?afterRegister=1");
      }
      window.gtag ('event', "register")

    } catch (err) {
      props.setError(err.message);
    }
  };

  const handlePasswordBlur = () => {
    setIsPasswordMatch(
      inputPasswordRepeat !== "" && inputPassword !== ""
        ? inputPassword === inputPasswordRepeat
        : true
    );
  };

  return (
    
    <div className="register-page">
      <form className="register-page__form" onSubmit={handleRegisterFormSubmit}>
        <div className="register-page__buttons-wrapper">
          <Link to="/">
            <button type="button" className="register-page__closing-button">
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </Link>
          <button type="button" className="register-page__info-button">
            ?
            <div className="register-page__info-text-wrapper">
            <p className="register-page__info-text">
                  {Store.getText("contactText")}
                  <span className="register-page__span-block">{Store.getText("contactEmail")}</span>
                </p>
                </div>
          </button>
        </div>
        <h2 className="register-page__form-title">
          {Store.getText("registerButtonText")}
        </h2>
        <p className="register-page__form-slogan register-page__form-slogan--register">
          {Store.getText("registerSlogan")}
        </p>
        <div className="register-page__main-form-content">
          <div className="register-page__input-wrapper">
            <label className="register-page__input-label">
              {Store.getText("activationCodeLabel")}
            </label>
            <input
              className="register-page__input"
              type="text"
              onChange={(ev) => setInputKey(ev.target.value)}
              value={inputKey}
            ></input>
          </div>
          <div className="register-page__input-wrapper">
            <label className="register-page__input-label">
              {Store.getText("emailLabel")}
            </label>
            <input
              className="register-page__input"
              type="email"
              onChange={(ev) => setInputEmail(ev.target.value.toLowerCase())}
              value={inputEmail}
            ></input>
          </div>

          <div className="register-page__input-wrapper">
            <label className="register-page__input-label">
              {Store.getText('username')}
            </label>
            <input
              className="register-page__input"
              type="text"
              onChange={(ev) => setInputUsername(ev.target.value.trim())}
              value={inputUsername}
            ></input>
          </div>
          <div className="register-page__input-wrapper">
            <label className="register-page__input-label">
              {Store.getText("passwordLabel")}
            </label>
            <div className="register-page__password-wrapper">
              <input
                className="register-page__input"
                type={inputPasswordShow ? "text" : "password"}
                onChange={(ev) => setInputPassword(ev.target.value)}
                value={inputPassword}
                onBlur={handlePasswordBlur}
              ></input>
              <button
                className="register-page__password-icon-button"
                type="button"
                title={
                  inputPasswordShow
                    ? Store.getText("hidePassword")
                    : Store.getText("showPassword")
                }
                onClick={() => setInputPasswordShow(!inputPasswordShow)}
              >
                {inputPasswordShow ? (
                  <FontAwesomeIcon icon={faEyeSlash}/>
                ) : (
                  <FontAwesomeIcon icon={faEye}/>

                )}
              </button>
            </div>
          </div>
          <div></div>
          <div className="register-page__input-wrapper">
            <label className="register-page__input-label">
              {Store.getText("repeatPasswordLabel")}
            </label>
            <div className="register-page__password-wrapper">
              <input
                className="register-page__input"
                type={inputPasswordRepeatShow ? "text" : "password"}
                onChange={(ev) => setInputPasswordRepeat(ev.target.value)}
                onBlur={handlePasswordBlur}
                value={inputPasswordRepeat}
              ></input>
              <button
                className="register-page__password-icon-button"
                type="button"
                title={
                  inputPasswordRepeatShow
                    ? Store.getText("hidePassword")
                    : Store.getText("showPassword")
                }
                onClick={() =>
                  setInputPasswordRepeatShow(!inputPasswordRepeatShow)
                }
              >
                {inputPasswordRepeatShow ? (
                  <FontAwesomeIcon icon={faEyeSlash}/>
                ) : (
                  <FontAwesomeIcon icon={faEye}/>

                )}
              </button>
            </div>
            {isPasswordMatch ? (
              ""
            ) : (
              <p className="register-page__alert">
                {Store.getText("passwordsNotMatchAlert")}
              </p>
            )}
          </div>
        </div>
        <MainButton className="orange-button up" type="submit">
          {Store.getText("registerButtonText")}
        </MainButton>
      </form>
    </div>
  );
}

export default RegisterPage;
