import { useState } from "react";
import Store from "../../../../Store";
import MainButton from "../../Buttons/MainButton/MainButton";
import MainModal from "../MainModal/MainModal";
import api from "../../../api/api";
import "./ChangePasswordModal.css";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function ChangePasswordModal(props) {
  const [inputCurrentPasswordShow, setInputCurrentPasswordShow] = useState(false);

  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordShow, setInputPasswordShow] = useState(false);
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState("");
  const [inputPasswordRepeatShow, setInputPasswordRepeatShow] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");

  const navigate = useNavigate();

  const handlePasswordBlur = () => {
    setIsPasswordMatch(
      inputPasswordRepeat !== "" && inputPassword !== ""
        ? inputPassword === inputPasswordRepeat
        : true
    );
  };

  async function changePassword(ev) {
    try {
      ev.preventDefault();

      if (isPasswordMatch) {
        const res = await api.post(`/user/current/change-password`, {
          password: inputPassword,
          currentPassword,
        });
        props.setIsModalOpen(false);
        navigate("/platform");
      }
    } catch (err) {
      props.setError(err.message);
    }
  }

  return (
    <MainModal
      isModalOpen={props.isModalOpen}
      setIsModalOpen={props.setIsModalOpen}
    >
      <div className="change-password-modal">
        <h2 className="change-password-modal__title">{Store.getText('changePassword')}</h2>
        <form
          className="change-password-modal__form"
          onSubmit={(e) => changePassword(e)}
        >
          <div className="change-password-modal__input-wrapper">
            <label className="change-password-modal__input-label">
            {Store.getText('oldPasswordLabel')}
            </label>
            <div className="change-password-modal__password-wrapper">
              <input
                className="change-password-modal__input"
                type={inputCurrentPasswordShow ? "text" : "password"}
                onChange={(ev) => setCurrentPassword(ev.target.value)}
                value={currentPassword}
              ></input>
                            <button
                className="change-password-modal__password-icon-button"
                type="button"
                title={
                  inputCurrentPasswordShow
                    ? Store.getText("hidePassword")
                    : Store.getText("showPassword")
                }
                onClick={() => setInputCurrentPasswordShow(!inputCurrentPasswordShow)}
              >
                {inputCurrentPasswordShow  ? (
                  <FontAwesomeIcon icon={faEyeSlash}/>
                ) : (
                  <FontAwesomeIcon icon={faEye}/>

                )}
              </button>
            </div>
          </div>
          <div className="change-password-modal__input-wrapper">
            <label className="change-password-modal__input-label">
              {Store.getText('passwordLabel')}
            </label>
            <div className="change-password-modal__password-wrapper">
              <input
                className="change-password-modal__input"
                type={inputPasswordShow ? "text" : "password"}
                onChange={(ev) => setInputPassword(ev.target.value)}
                value={inputPassword}
                onBlur={handlePasswordBlur}
              ></input>
              <button
                className="change-password-modal__password-icon-button"
                type="button"
                title={
                  inputPasswordShow
                    ? Store.getText("hidePassword")
                    : Store.getText("showPassword")
                }
                onClick={() => setInputPasswordShow(!inputPasswordShow)}
              >
                {inputPasswordShow  ? (
                  <FontAwesomeIcon icon={faEyeSlash}/>
                ) : (
                  <FontAwesomeIcon icon={faEye}/>

                )}
              </button>
            </div>
          </div>
          <div></div>
          <div className="change-password-modal__input-wrapper">
            <label className="change-password-modal__input-label">
            {Store.getText('repeatPasswordLabel')}

            </label>
            <div className="change-password-modal__password-wrapper">
              <input
                className="change-password-modal__input"
                type={inputPasswordRepeatShow ? "text" : "password"}
                onChange={(ev) => setInputPasswordRepeat(ev.target.value)}
                onBlur={handlePasswordBlur}
                value={inputPasswordRepeat}
              ></input>
              <button
                className="change-password-modal__password-icon-button"
                type="button"
                title={
                  inputPasswordRepeatShow
                    ? Store.getText("hidePassword")
                    : Store.getText("showPassword")
                }
                onClick={() =>
                  setInputPasswordRepeatShow(!inputPasswordRepeatShow)
                }
              >
                {inputPasswordRepeatShow ?  (
                  <FontAwesomeIcon icon={faEyeSlash}/>
                ) : (
                  <FontAwesomeIcon icon={faEye}/>

                )}
              </button>
            </div>
            {isPasswordMatch ? (
              ""
            ) : (
              <p className="change-password-modal__alert">
                {Store.getText("passwordsNotMatchAlert")}
              </p>
            )}
          </div>
          <MainButton type="submit">{Store.getText('changePassword')}</MainButton>
        </form>
      </div>
    </MainModal>
  );
}

export default ChangePasswordModal;
