import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import logo from "../../product/images/logo_smartado_wh.png";
import { Colors } from "../../product/colors/Colors";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { LanguageChanger } from "./LanguageChanger";
import { Hamburger } from "./Hamburger";

const HamBurgerStyle = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
`;
const Wrapper = styled.header`
  height: 0;
  left: 0;
  width: fit-content;
  background: rgba(36, 71, 35, 0.7);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 0 0px;
  height: 80px;
  color: white;
  font-weight: bold;
  padding-right: 30px;

  @media (max-width: 1200px) {
    padding-right: 5%;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 30px;
`;

const Flex2 = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 20px;
`;

const LogoBox = styled.div`
  height: 80px;
  overflow: hidden;
`;
const CustomLink = styled.a`
  color: white;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: 400;
  transition: 0.35s ease;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: white;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: 0.3s ease-in-out 0s;
    transition: 0.3s ease-in-out 0s;
  }

  &:hover {
    transform: scaleX(1.1);
    color: white;
    &::before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  @media (max-width: 900px) {
    display: none;
  }
`;
const Navbar = ({ data }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang } = useParams();
  let locationElement = location.pathname.split("/")[2];
  const [addBorder, setAddBorder] = useState(locationElement);

  useEffect(() => {
    setAddBorder(locationElement);
  }, [addBorder, setAddBorder, locationElement, location, navigate]);

  return (
    <>
      <Wrapper>
        <Flex>
          <Link to="/">
            <LogoBox>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "auto",
                  height: "100%",
                  backgroundColor: Colors.darkBlue,
                  padding: "15px 10px",
                }}
              />
            </LogoBox>
          </Link>
          {/* <CustomLink
            className={addBorder === "course" ? "border " : ""}
            onClick={() => navigate(`/${lang}${data[0].path}`)}
          >
            {data[0].title}
          </CustomLink>
          <CustomLink
            className={
              addBorder === undefined ||
              addBorder === "login" ||
              addBorder === "register"
                ? "border "
                : ""
            }
            onClick={() => navigate(`/${lang}${data[1].path}`)}
          >
            {data[1].title}
          </CustomLink>
          <CustomLink
            className={addBorder === "product" ? "border " : ""}
            onClick={() => navigate(`/${lang}${data[2].path}`)}
          >
            {data[2].title}
          </CustomLink>
          <CustomLink
            className={addBorder === "about" ? "border " : ""}
            onClick={() => navigate(`/${lang}${data[3].path}`)}
          >
            {data[3].title}
          </CustomLink> */}
        </Flex>
        <Flex2>
          <LanguageChanger />
          {/* <HamBurgerStyle>
            <Hamburger data={data} />
          </HamBurgerStyle> */}
        </Flex2>
      </Wrapper>
    </>
  );
};

export default Navbar;
