import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import api from "../../../api/api";
import "./MainPageDesktop.css";
import logo from "../../../..//common/assets/logo-white.png";
import Store from "../../../../Store";
import flag from "../dymek_angielski.png";
import getLanguageFlag from "../../../common/functions/getLanguageFlag";
import levelOne from "../dymek-lvl1.png";
import levelTwo from "../dymek-lvl2.png";
import levelThree from "../dymek-lvl3.png";
import ColouredSpeechBubble from "../../../components/SpeechBubbles/ColouredSpeechBubble/ColouredSpeechBubble";
import YourActivityBox from "../../../components/YourActivityBox/YourActivityBox";
import MainModal from "../../../components/Modals/MainModal/MainModal";
import getLanguageCapitalWeatherCodes from "../../../common/functions/getLanguageCapitalWeatherCodes";
import getLevelColor from "../../../common/functions/getLevelColor";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal/ConfirmationModal";
import getLanguageFunFact from "../../../common/functions/getLanguageFunFact";
import Sidebar from "../../../components/Sidebar/Sidebar";
import getLanguageMap from "../../../common/functions/getLanguageMap";
import SecondaryButton from "../../../components/Buttons/SecondaryButton/SecondaryButton";
import CoursesModal from "../../../components/Modals/CoursesModal/CoursesModal";

function MainPageDesktop(props) {
  const [currentLanguagePair, setCurrentLanguagePair] = useState();
  const [currentLevel, setCurrentLevel] = useState();
  const [currentLessonNumber, setCurrentLessonNumber] = useState();
  const [currentLesson, setCurrentLesson] = useState();

  const [funfact, setFunfact] = useState();

  const [currentLanguageShort, setCurrentLanguageShort] = useState();

  const [languagesAvailable, setLanguagesAvailable] = useState([]);
  const [notAvailableLanguages, setNotAvailableLanguages] = useState([]);

  const [currentProgress, setCurrentProgress] = useState(0);

  const pageRef = useRef();

  const params = useParams();

  const navigate = useNavigate();

  function handleLogOut() {
    Store.removeToken();
    navigate("/"); // whichever component you want it to route to
  }

  function appendWeatherWidget(langShort) {
    const weatherCode = getLanguageCapitalWeatherCodes(langShort).html;
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      getLanguageCapitalWeatherCodes(langShort).script
    );
    document.getElementById("weather-widget").innerHTML = weatherCode;
    document.getElementById("weather-widget").appendChild(script);
  }

  async function loadData() {
    const lastLesson = await api.get("/lesson/last-lesson");
    const langAvailableRes = await api.get(
      `/language-pair/language-from/${params.languageShort}/available`
    );
    const langAllRes = await api.get(
      `/language-pair/language-from/${params.languageShort}`
    );

    setLanguagesAvailable(langAvailableRes.data);
    const availableIds = langAvailableRes.data.map((lang) => lang.id);
    setNotAvailableLanguages(
      langAllRes.data.filter((lang) => availableIds.indexOf(lang.id) === -1)
    );

    if (lastLesson.data && 
      lastLesson.data.languagePair.languageFrom.languageShort.includes(params.languageShort)
      ) {

        setCurrentLesson(lastLesson.data);
      setCurrentLanguageShort(
        lastLesson.data.languagePair.languageTo.languageShort
      );
      setCurrentLanguagePair(lastLesson.data.languagePair);
      setCurrentLevel(lastLesson.data.level);
      setCurrentLessonNumber(lastLesson.data.number);
    } else {
      setCurrentLanguageShort(
        langAvailableRes.data[0].languageTo.languageShort
      );
      setCurrentLanguagePair(langAvailableRes.data[0]);
      setCurrentLevel(1);
      setCurrentLessonNumber(1);
    }
  }

  // function selectLanguage(id, level) {
  //   const language = languagesAvailable.find((lang) => lang.id === id);
  //   setCurrentLanguageShort(language.languageTo.languageShort);
  //   setCurrentLanguagePair(language.id);
  //   setCurrentLevel(level);
  //   setIsModalOpen(false);
  // }

  async function loadProgress(pairId, level) {
    const resLessons = await api.get(
      `/lesson/language-pair/${pairId}/level/${level}`
    );
    let sumProgress = 0;
    resLessons.data.forEach((lesson) => {
      sumProgress += lesson.progress;
    });
    setCurrentProgress((sumProgress * 100) / resLessons.data.length);
  }

  function getBackgroundImageClass(languageShort) {
    let short = languageShort;
    return `main-page-desktop__background--${short}`;
  }

  useEffect(() => {
    Store.setUserPlatformLanguage(params.languageShort);
    setFunfact(getLanguageFunFact());
    loadData();
  }, [params.languageShort]);

  useEffect(() => {
    if (currentLevel && currentLanguagePair) {
      loadProgress(currentLanguagePair.id, currentLevel);
    }
  }, [currentLevel, currentLanguagePair]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  return (
    currentLanguageShort && currentLanguagePair && (
      <div className="main-page-desktop" ref={pageRef}>
        <Sidebar
          isSidebarExpandable
          darkBg
          setError={props.setError}
          pairId={currentLanguagePair.id}
        ></Sidebar>
        <div
          className={`main-page-desktop__background ${getBackgroundImageClass(
            currentLanguageShort
          )}`}
        >
          <div className="main-page-desktop__nav-wrapper">
              <img src={logo} className="main-page-desktop__logo" />
          </div>
          <div className="main-page-desktop__main-wrapper">
            <div className="main-page-desktop__content-wrapper">
              <ColouredSpeechBubble className="coloured-speech-bubble--left-top">
                <h1 className="main-page-desktop__speech-bubble-main-header">
                  {Store.getText("mainPageWelcomeBoxHeaderOne")}{" "}
                  {Store.getUsername()}!
                </h1>
                <h2 className="main-page-desktop__speech-bubble-header">
                  {Store.getText(
                    "mainPageWelcomeBoxHeaderTwo" +
                      ((new Date().getDate() % 3) + 1)
                  )}
                </h2>
                <p className="main-page-desktop__text">
                  {Store.getText(
                    "mainPageWelcomeBoxTextFirstLine" +
                      ((new Date().getDate() % 3) + 1)
                  )}
                </p>
                {currentLesson && <p className="main-page-desktop__text">
                  {Store.getText("mainPageWelcomeBoxTextSecondLine")}{" "}
                  {currentLesson.name}
                </p>}
              </ColouredSpeechBubble>
              <YourActivityBox
                level={currentLevel}
                pairId={currentLanguagePair.id}
                style={{ marginTop: "30px" }}
              />
            </div>
            <div className="main-page-desktop__content-wrapper">
              <div className="main-page-desktop__levels-wrapper">
                <div className="main-page-desktop__speech-bubbles-image"></div>
                <div className="main-page-desktop__level-buttons">
                  <div
                    className="main-page-desktop__level-button-wrapper"
                    onClick={() => setCurrentLevel(1)}
                  >
                    <img
                      src={levelOne}
                      className={`main-page-desktop__level-button-img ${
                        currentLevel === 1
                          ? "main-page-desktop__level-button-img--active"
                          : ""
                      }`}
                    />
                    <button
                      className={`main-page-desktop__level-button ${
                        currentLevel === 1
                          ? "main-page-desktop__level-button--active"
                          : ""
                      }`}
                    >
                      {Store.getText("level")} 1
                    </button>
                  </div>
                  <div
                    className="main-page-desktop__level-button-wrapper"
                    onClick={() => setCurrentLevel(2)}
                  >
                    <img
                      src={levelTwo}
                      className={`main-page-desktop__level-button-img ${
                        currentLevel === 2
                          ? "main-page-desktop__level-button-img--active"
                          : ""
                      }`}
                    />
                    <button
                      className={`main-page-desktop__level-button ${
                        currentLevel === 2
                          ? "main-page-desktop__level-button--active"
                          : ""
                      }`}
                    >
                      {Store.getText("level")} 2
                    </button>
                  </div>
                  <div
                    className="main-page-desktop__level-button-wrapper"
                    onClick={() => setCurrentLevel(3)}
                  >
                    <img
                      src={levelThree}
                      className={`main-page-desktop__level-button-img ${
                        currentLevel === 3
                          ? "main-page-desktop__level-button-img--active"
                          : ""
                      }`}
                    />
                    <button
                      className={`main-page-desktop__level-button ${
                        currentLevel === 3
                          ? "main-page-desktop__level-button--active"
                          : ""
                      }`}
                    >
                      {Store.getText("level")} 3
                    </button>
                  </div>
                </div>
                <div className="main-page-desktop__progress-bar-wrapper">
                  <div className="main-page-desktop__progress-bar">
                    <ProgressBar
                      completed={currentProgress}
                      height="30px"
                      bgColor="#95C34B"
                      customLabel=" "
                      className="progress-bar"
                    />
                  </div>
                  <p className="main-page-desktop__progress-bar-desc">
                    {Store.getText("mainPageProgressBarDescription")}{" "}
                    {currentLevel}
                  </p>
                </div>
              </div>
              <div className="main-page-desktop__lessons-wrapper">
                <Link
                  className="main-page-desktop__big-square-button"
                  style={{ backgroundColor: getLevelColor(currentLevel) }}
                  to={`/platform/activity/${currentLanguagePair.id}/${currentLevel}`}
                >
                  {Store.getText("mainPageContinueLearningButtonText")}
                </Link>
                <Link
                  className="main-page-desktop__transparent-button"
                  to={`/platform/lessons/${currentLanguagePair.id}/${currentLevel}`}
                >
                  {/* Poziom {currentLevel} - lekcje */}
                  {Store.getText("mainPageAllLessonsgButtontext")}
                </Link>
              </div>
            </div>
            <div className="main-page-desktop__content-wrapper">
              <div className="main-page-desktop__content-div">
                <CoursesModal
                  setCurrentLanguageShort={setCurrentLanguageShort}
                  setCurrentLanguagePair={setCurrentLanguagePair}
                  setCurrentLevel={setCurrentLevel}
                  languagesAvailable={languagesAvailable}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  notAvailableLanguages={notAvailableLanguages}
                  setError={props.setError}
                ></CoursesModal>
                <h3 className="main-page-desktop__content-title">
                  {Store.getText("mainPageActiveCourseHeader")}
                </h3>
                <ul className="main-page-desktop__courses-list">
                  <li className="main-page-desktop__courses-list-element">
                    <img
                      src={getLanguageFlag(currentLanguageShort)}
                      className="main-page-desktop__course-flag"
                    />
                    {Store.getLanguageNameByShort(currentLanguageShort)}
                  </li>
                </ul>
                <button
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                  className="main-page-desktop__show-more-button"
                >
                  {Store.getText("mainPageOtherCoursesButtonTextPre")}<br/>

                  {`${Store.getText("mainPageOtherCoursesButtonText")}`}
                </button>
              </div>
              <ColouredSpeechBubble
                className="coloured-speech-bubble--bottom-right"
                style={{
                  padding: "40px",
                  textAlign: "center",
                  fontSize: "2.6rem",
                }}
              >
                <div className="fun-facts-widget">
                  <h2 className="fun-facts-widget__title">
                    {Store.getText("mainPageFunFactsHeader")}
                  </h2>
                  {funfact && (
                    <div className="fun-facts-widget__content-wrapper">
                      <img
                        src={funfact.map}
                        className="fun-facts-widget__img"
                      />
                      <p className="fun-facts-widget__text">
                        {funfact.funfact}
                      </p>
                    </div>
                  )}
                </div>
              </ColouredSpeechBubble>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default MainPageDesktop;
