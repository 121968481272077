import { useNavigate, useLocation } from "react-router";
import api from "./platform/api/api";
import Store from "./Store";
import { useEffect } from "react";

function LoginChecker(props) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = Store.getToken();
    if (token) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else if(location.pathname.indexOf('/platform') !== - 1) navigate('/login');

   
  }, []);
  return <>{props.children}</>;
}

export default LoginChecker;
