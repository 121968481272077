import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import logo from "../../product/images/logo_smartado_wh.png";
import { Colors } from "../../product/colors/Colors";
import { useLocation, useNavigate } from "react-router-dom";

const Wrapper = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 1%;
  color: white;
  font-weight: bold;
  background-color: ${Colors.darkBlue};

  @media (max-width: 600px) {
    gap: 10px;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Flex2 = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const Link = styled.a`
  color: white;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: 400;
  transition: 0.35s ease;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: white;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: 0.3s ease-in-out 0s;
    transition: 0.3s ease-in-out 0s;
  }

  &:hover {
    transform: scaleX(1.1);
    color: white;

    &::before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
`;
const LogoBox = styled.div`
  height: 80px;
  overflow: hidden;
`;

const CpoyWrapper = styled.div`
  color: white;
  font-size: 13px;
  font-family: roboto;
  font-weight: normal;

  @media (max-width: 600px) {
    font-size: 10px;
  }
`;

const Footer = ({ data }) => {
  return (
    <Wrapper>
      <CpoyWrapper>© 2023 by LINGUA INSTITUTE SPZOO</CpoyWrapper>
      <Flex2>
        <Link href={data.rulesLink} target="_blank">
          {data.rules}
        </Link>
        <Link href={data.privacyLink} target="_blank">
          {data.privacy}
        </Link>
      </Flex2>
    </Wrapper>
  );
};

export default Footer;
