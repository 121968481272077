import styled from "styled-components";
import { Colors } from "../../../colors/Colors";


export const StyledSection = styled.section`
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */

  max-width: 800px;
  width: 100%;
  margin:  0 auto;
  @media (max-width: 930px) {
    margin: auto;
  }
`;
