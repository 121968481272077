import { useState } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../GrammarModal.css";

const timesSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Present Simple</h3>
    <p className="grammar__text">
      Prítomný jednoduchý čas použiješ v prvom rade vtedy, keď chceš vyjadriť
      niečo, čo je pravdivé teraz v prítomnosti.
    </p>
    <p className="grammar__text">
      Nemusí sa striktne jednať o niečo, čo sa v budúcnosti meniť nebude.
    </p>
    <p className="grammar__text">
      Podstatné je však to, že je daná vec pravdivá a aktuálna v momente, keď ju
      vyslovuješ.
    </p>
    <p className="grammar__text">
      Daj si však veľký pozor na to, že prítomný jednoduchý čas sa nepoužíva na
      vyjadrenie činností, ktoré sa dejú práve teraz.
    </p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Present simple</th>
          <th className="grammar__table-header">Prítomný čas jednoduchý</th>
          <th className="grammar__table-header">Vysvetlenie</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            She <b>lives</b> in Bratislava.
          </td>
          <td className="grammar__table-data">Ona býva v Bratislave.</td>
          <td className="grammar__table-data">
            Prítomný čas jednoduchý naznačuje to, že býva v Bratislave teraz.
            Môže sa stať, že sa to v budúcnosti zmení, avšak teraz je to pravd
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            I <b>am</b> thirty years old.
          </td>
          <td className="grammar__table-data">Mám tridsať rokov.</td>
          <td className="grammar__table-data">
            To, že mám tridsať rokov, je pravdou teraz, preto je vo vete present
            simple. Tu sa nejedná o tvrdenie so všeobecnou platnosťou, ale o
            tvrdenie, ktoré sa bude v budúcnosti s určitosťou meniť.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            He <b>doesn´t like</b> tomatoes.
          </td>
          <td className="grammar__table-data">Nemá rád paradajky.</td>
          <td className="grammar__table-data">
            Tu sa môže na prvý pohľad zdať, že sa jedná o všeobecné tvrdenie.
            Avšak, aj keď veta tvrdí, že dotyčný jednoducho nemá rád paradajky,
            tak nie je vylúčené, že sa to v budúcnosti nezmení. Môže sa to
            zmeniť, ale aj nemusí.
          </td>
        </tr>
      </tbody>
    </table>
  </div>,
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Past Simple</h3>
    <p className="grammar__text">
      Jednoduchý minulý čas sa v angličtine nazýva aj préteritum (z latinského
      praeteritum „to, čo skončilo“). Môžeš ho teda najčastejšie využiť na
      vyjadrenie deja, ktorý začal a aj skončil v určitom čase v minulosti.
      Väčšinou sa viaže na konkrétny čas v minulosti, aj keď nemusel byť
      vyslovene spomenutý.
    </p>
    <p className="grammar__text">
      Na naznačenie konkrétneho času môžeš použiť príslovky ako:
    </p>
    <p className="grammar__text">Yesterday (včera)</p>
    <p className="grammar__text">Last year (minulý rok)</p>
    <p className="grammar__text">Last week (minulý týždeň)</p>
    <p className="grammar__text">Last night (včera večer)</p>
    <p className="grammar__text">Príkladové vety:</p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Past simple</th>
          <th className="grammar__table-header">Minulý čas jednoduchý</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            We <b>saw</b> a movie yesterday.
          </td>
          <td className="grammar__table-data">
            Včera <b>sme</b> videli (jeden) film.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            I <b>didn´t see</b> you at the party yesterday.
          </td>
          <td className="grammar__table-data">
            Včera <b>som</b> ťa na oslave <b>nevidel</b>.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            Last year, I <b>didn´t move</b> to Spain.
          </td>
          <td className="grammar__table-data">
            Minulý rok <b>som sa nepresťahoval</b> do Španielska.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            Last year, I <b>didn´t</b> buy a new car.
          </td>
          <td className="grammar__table-data">
            Minulý rok som <b>si nekúpil</b> nové auto.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>Did</b> you <b>cook</b> dinner last night?
          </td>
          <td className="grammar__table-data">
            <b>Varil</b> si včera večeru?
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            I <b>washed</b> my hair yesterday.
          </td>
          <td className="grammar__table-data">
            Včera <b>som</b> si umyl vlasy.
          </td>
        </tr>
      </tbody>
    </table>
  </div>,
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Present perfect</h3>
    <p className="grammar__text">
      Present perfect je kombináciou prítomného času (present) a vyjadrenia
      úplnej / dokončenej činnosti (perfect).
    </p>
    <p className="grammar__text">
      Používa sa na vyjadrenie minulej udalosti / činnosti, ktorá má súvis s
      prítomnosťou.
    </p>
    <p className="grammar__text">
      Najčastejšie sa používa na vyjadrenie dejov, skúseností, alebo zmien,
      ktoré sa udiali a pri ktorých je dôležitý výsledok a nie čas, kedy sa
      udiali.
    </p>
    <p className="grammar__text">
      Štruktúra kladnej oznamovacej vety v present perfect vyzerá nasledovne:
    </p>
    <p className="grammar__text">
      Osoba/predmet + pomocné sloveso have/ has + minulé trpné príčastie slovesa
    </p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">
            Anglická veta v present perfect
          </th>
          <th className="grammar__table-header">
            Bežný preklad predprítomného času
          </th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            I <b>have seen</b> that movie.
          </td>
          <td className="grammar__table-data">Videl som ten film.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            You <b>have seen</b> that movie.
          </td>
          <td className="grammar__table-data">Videl si ten film.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            He (she/ it) <b>has seen</b> that movie.
          </td>
          <td className="grammar__table-data">Videl ten film.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            We <b>have seen</b> that movie.
          </td>
          <td className="grammar__table-data">Videli sme ten film.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            You <b>have seen</b> that movie.
          </td>
          <td className="grammar__table-data">Videli ste ten film.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            They <b>have seen</b> that movie.
          </td>
          <td className="grammar__table-data">Videli ten film.</td>
        </tr>
      </tbody>
    </table>
  </div>,
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Present continuous</h3>
    <p className="grammar__text">
      Na popis činností, ktoré prebiehajú práve teraz, sa v angličtine (na
      rozdiel od slovenčiny) nepoužíva prítomný jednoduchý čas (present simple),
      ale prítomný priebehový čas – present continuous.
    </p>
    <p className="grammar__text">
      Vo vetách s prítomným priebehovým časom sa môžeš často stretnúť s
      príslovkami, ktoré naznačujú, že sa jedná o dej, ktorý prebieha práve
      teraz:
    </p>
    <p className="grammar__text">now (teraz)</p>
    <p className="grammar__text">today (dnes)</p>
    <p className="grammar__text">right now (práve teraz)</p>
    <p className="grammar__text">
      at the moment (v tomto momente/ práve v tejto chvíli)
    </p>
    <p className="grammar__text">Ako vyzerá tento čas vo vetách?</p>
    <p className="grammar__text">Tu je pár príkladov:</p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Present continuous</th>
          <th className="grammar__table-header">Prítomný čas priebehový</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            He is studying English right now.
          </td>
          <td className="grammar__table-data">Práve sa učí angličtinu.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            I am working in the office at the moment.
          </td>
          <td className="grammar__table-data">
            Práve teraz pracujem v kancelárii.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            The children are sleeping now.
          </td>
          <td className="grammar__table-data">Deti práve spia.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            They are cooking dinner right now.
          </td>
          <td className="grammar__table-data">Teraz varia večeru.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            You are talking too fast. I don´t understand you.
          </td>
          <td className="grammar__table-data">
            Hovoríš príliš rýchlo. Nerozumiem ti.
          </td>
        </tr>
      </tbody>
    </table>
  </div>,
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Past continuous</h3>
    <p className="grammar__text">
      Past continuous použiješ, ak hovoríš o činnosti, ktorá prebiehala v
      minulosti dlhšiu dobu a bola prerušená jednorazovou krátkou činnosťou.
    </p>
    <p className="grammar__text">
      Táto kratšia činnosť býva väčšinou vyjadrená v jednoduchom minulom čase
      (past simple).
    </p>
    <p className="grammar__text">Ako to vyzerá v praxi?</p>
    <p className="grammar__text">
      Pozrime sa na konkrétny príklad (vety). Ako si môžeš všimnúť, past
      continuous je v nich zvýraznený tučným a za ním nasleduje past simple.
    </p>
    <p className="grammar__text">Príklady:</p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">
            Past continuous a past simple
          </th>
          <th className="grammar__table-header">
            Minulý priebehový čas a minulý jednoduchý čas
          </th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            I <b>was</b> having lunch when the phone rang.
          </td>
          <td className="grammar__table-data">
            Práve <b>som obedoval</b>, keď mi zazvonil telefón.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            What <b>were</b> you <b>doing</b> when you hurt your knee?
          </td>
          <td className="grammar__table-data">
            Čo <b>si robil</b>, keď si si poranil koleno?
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            While I <b>was shopping,</b> somebody stole my bike.
          </td>
          <td className="grammar__table-data">
            Zatiaľ, čo <b>som nakupovala</b>, mi niekto ukradol bicykel.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            I <b>was having</b> a beautiful dream when the alarm clock rang.
          </td>
          <td className="grammar__table-data">
            <b>Sníval sa</b> mi krásny sen, keď v tom zazvonil budík.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            I <b>was having</b> a shower when somebody knocked on the door
          </td>
          <td className="grammar__table-data">
            <b>Sprchoval som</b> sa, keď niekto zaklopal na dvere.
          </td>
        </tr>
      </tbody>
    </table>
  </div>,
];

const possessivePronounsSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">
      NESAMOSTATNÉ PRIVLASTŇOVACIE ZÁMENÁ
    </h3>
    <p className="grammar__text">
      Angličtina si stále vystačí s jediným tvarom {"->"} my:
    </p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">
            Tvary slovenského zámena môj
          </th>
          <th className="grammar__table-header">Tvary anglického zámena my</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>môj</b> pes
          </td>
          <td className="grammar__table-data">
            <b>my</b> dog
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>moja</b> mačka
          </td>
          <td className="grammar__table-data">
            <b>my</b> cat
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>moje</b> dieťa
          </td>
          <td className="grammar__table-data">
            <b>my</b> kid
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>moji</b> psi
          </td>
          <td className="grammar__table-data">
            <b>my</b> dogs
          </td>
        </tr>
      </tbody>
    </table>

    <p className="grammar__text">
      Prehľad osobných zámen a zodpovedajúcich nesamostatných privlastňovacích
      zámen:
    </p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Osobné zámená</th>
          <th className="grammar__table-header">
            Privlastňovacie zámená (nesamostatné)
          </th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>I</b> – ja
          </td>
          <td className="grammar__table-data">
            <b>my</b> – môj, moja, moje.. atď
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>You</b> – ty
          </td>
          <td className="grammar__table-data">
            <b>your</b> – tvoj, tvoja, tvoje.. atď
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>He</b> – on
          </td>
          <td className="grammar__table-data">
            <b>his</b> – jeho
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>She</b> – ona
          </td>
          <td className="grammar__table-data">
            <b>her</b> – jej
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>It</b> – ono
          </td>
          <td className="grammar__table-data">
            <b>its</b> – jeho, jej (neživotné)
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>We</b> – my
          </td>
          <td className="grammar__table-data">
            <b>our</b> – náš, naša, naše.. atď
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>You</b> – vy
          </td>
          <td className="grammar__table-data">
            <b>your</b> – váš, vaša, vaše.. atď
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>They</b> – oni
          </td>
          <td className="grammar__table-data">
            <b>their</b> – ich
          </td>
        </tr>
      </tbody>
    </table>

    <p className="grammar__text">Príklady:</p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">
            NESAMOSTATNÉ PRIVLASTŇOVACIE ZÁMENÁ
          </th>
          <th className="grammar__table-header"></th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            This is <b>my</b> wife. Her name is Mary.
          </td>
          <td className="grammar__table-data">
            Toto je moja žena. Volá sa Mary.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            Is this <b>your</b> bike?
          </td>
          <td className="grammar__table-data">Je to tvoj bicykel?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            Is <b>his</b> name Peter?
          </td>
          <td className="grammar__table-data">Volá sa Peter?</td>
        </tr>
      </tbody>
    </table>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">
      SAMOSTATNÉ PRIVLASTŇOVACIE ZÁMENÁ
    </h3>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Osobné zámená</th>
          <th className="grammar__table-header">
            Privlastňovacie zámená (samostatné)
          </th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>I</b> – ja
          </td>
          <td className="grammar__table-data">
            <b>mine</b> – môj, moja, moje.. atď
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>You</b> – ty
          </td>
          <td className="grammar__table-data">
            <b>yours</b> – tvoj, tvoja, tvoje.. atď
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>He</b> – on
          </td>
          <td className="grammar__table-data">
            <b>his</b> – jeho
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>She</b> – ona
          </td>
          <td className="grammar__table-data">
            <b>hers</b> – jej
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>It</b> – ono
          </td>
          <td className="grammar__table-data">
            <b>its</b> – jeho, jej (neživotné)
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>We</b> – my
          </td>
          <td className="grammar__table-data">
            <b>ours</b> – náš, naša, naše.. atď
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>You</b> – vy
          </td>
          <td className="grammar__table-data">
            <b>yours</b> – váš, vaša, vaše.. atď
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <b>They</b> – oni
          </td>
          <td className="grammar__table-data">
            <b>theirs</b> – ich
          </td>
        </tr>
      </tbody>
    </table>
    <p className="grammar__text">Príklady:</p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">
            SAMOSTATNÉ PRIVLASTŇOVACIE ZÁMENÁ
          </th>
          <th className="grammar__table-header"></th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            This book is <b>mine</b> and that book is <b>yours</b>.
          </td>
          <td className="grammar__table-data">
            Táto kniha je moja a tamtá kniha je tvoja.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            This fashion magazine is not <b>hers</b>.
          </td>
          <td className="grammar__table-data">
            Tento módny časopis nie je jej (nepatrí jej).
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            Is this property yours or <b>theirs</b>.
          </td>
          <td className="grammar__table-data">
            Je tento pozemok váš alebo ich?
          </td>
        </tr>
      </tbody>
    </table>
  </div>,
];

const verbToBeSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Kladná věta</h3>
    <p className="grammar__text">
      V této tabulce najdete časování anglického slovesa „to be“. „To be“ je
      infinitiv a v žádném dalším čase se tento tvar již neobjeví. Časování je
      uvedeno pro přítomný čas prostý.
    </p>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. j.č.</td>
          <td className="grammar__table-data">I am</td>
          <td className="grammar__table-data">Já jsem</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. j.č.</td>
          <td className="grammar__table-data">You are</td>
          <td className="grammar__table-data">Ty jsi</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">He is</td>
          <td className="grammar__table-data">On je</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">She is</td>
          <td className="grammar__table-data">Ona je</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">It is</td>
          <td className="grammar__table-data">To je</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. m.č.</td>
          <td className="grammar__table-data">We are</td>
          <td className="grammar__table-data">My jsme</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. m.č.</td>
          <td className="grammar__table-data">You are</td>
          <td className="grammar__table-data">Vy jste</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. m.č.</td>
          <td className="grammar__table-data">They are</td>
          <td className="grammar__table-data">Oni jsou</td>
        </tr>
      </tbody>
    </table>
    <p className="grammar__text">Příklady:</p>
    <p className="grammar__text">I am happy. – Jsem šťastný.</p>
    <p className="grammar__text">You are a rich. – Ty jsi bohatý.</p>
    <p className="grammar__text">He is a teacher. – On je učitel.</p>
    <p className="grammar__text">We are from England. – Jsme z Anglie.</p>
    <p className="grammar__text">They are mechanics. – Oni jsou mechanici.</p>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Záporná věta</h3>
    <p className="grammar__text">
      V záporných větách se k slovesu „be“ přidává zápor „not“
    </p>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. j.č.</td>
          <td className="grammar__table-data">I am not</td>
          <td className="grammar__table-data">Já nejsem</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. j.č.</td>
          <td className="grammar__table-data">You are not</td>
          <td className="grammar__table-data">Ty nejsi</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">He is not</td>
          <td className="grammar__table-data">On není</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">She is not</td>
          <td className="grammar__table-data">Ona není</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">It is not</td>
          <td className="grammar__table-data">To není</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. m.č.</td>
          <td className="grammar__table-data">We are not</td>
          <td className="grammar__table-data">My nejsme</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. m.č.</td>
          <td className="grammar__table-data">You are not</td>
          <td className="grammar__table-data">Vy nejste</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. m.č.</td>
          <td className="grammar__table-data">They are not</td>
          <td className="grammar__table-data">Oni nejsou</td>
        </tr>
      </tbody>
    </table>
    <p className="grammar__text">Příklady:</p>
    <p className="grammar__text">I am not a doctor. – Nejsem doktor.</p>
    <p className="grammar__text">You are not a rich. – Nejsi bohatý.</p>
    <p className="grammar__text">
      Our dog is not hungry. – Náš pes není hladový.
    </p>
    <p className="grammar__text">
      We are not better then your team. – My nejsme lepší než Tvůj tým.
    </p>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Tázací věta</h3>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. j.č.</td>
          <td className="grammar__table-data">I am</td>
          <td className="grammar__table-data">Já jsem</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. j.č.</td>
          <td className="grammar__table-data">You are</td>
          <td className="grammar__table-data">Ty jsi</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">He is</td>
          <td className="grammar__table-data">On je</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">She is</td>
          <td className="grammar__table-data">Ona je</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">It is</td>
          <td className="grammar__table-data">To je</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. m.č.</td>
          <td className="grammar__table-data">We are</td>
          <td className="grammar__table-data">My jsme</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. m.č.</td>
          <td className="grammar__table-data">You are</td>
          <td className="grammar__table-data">Vy jste</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. m.č.</td>
          <td className="grammar__table-data">They are</td>
          <td className="grammar__table-data">Oni jsou</td>
        </tr>
      </tbody>
    </table>
    <p className="grammar__text">Příklady:</p>
    <p className="grammar__text">Am I a good actor? – Jsem dobrý herec?</p>
    <p className="grammar__text">Are you a teacher? – Jsi učitel?</p>
    <p className="grammar__text">Where is Karin? – Kde je Karin?</p>
    <p className="grammar__text">Are they merried? – Jsou ženatí?</p>
  </div>,
];

const verbCanSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Kladná věta</h3>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. j.č.</td>
          <td className="grammar__table-data">I can</td>
          <td className="grammar__table-data">Já můžu, já umím</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. j.č.</td>
          <td className="grammar__table-data">You can</td>
          <td className="grammar__table-data">Ty můžeš, ty umíš</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">He can</td>
          <td className="grammar__table-data">On může, on umí</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">She can</td>
          <td className="grammar__table-data">On může, on umí</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">It can</td>
          <td className="grammar__table-data">Ono může, ono umí</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. j.č.</td>
          <td className="grammar__table-data">We can</td>
          <td className="grammar__table-data">My můžeme, my umíme</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. j.č.</td>
          <td className="grammar__table-data">You can</td>
          <td className="grammar__table-data">Vy můžete, vy umíte</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">They can</td>
          <td className="grammar__table-data">Oni/Ony mohou, oni/ony umí</td>
        </tr>
      </tbody>
    </table>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Záporná věta</h3>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. j.č.</td>
          <td className="grammar__table-data">I can not swim</td>
          <td className="grammar__table-data">Já neumím plavat.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. j.č.</td>
          <td className="grammar__table-data">You can not swim</td>
          <td className="grammar__table-data">Ty neumíš plavat.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">He can not swim</td>
          <td className="grammar__table-data">On neumí plavat.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">She can not swim</td>
          <td className="grammar__table-data">Ona neumí plavat.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">It can not swim</td>
          <td className="grammar__table-data">Ono neumí plavat.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. m.č.</td>
          <td className="grammar__table-data">We can not swim</td>
          <td className="grammar__table-data">My neumíme plavat.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. m.č.</td>
          <td className="grammar__table-data">You can not swim</td>
          <td className="grammar__table-data">Vy neumíte plavat.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. m.č.</td>
          <td className="grammar__table-data">They can not swim</td>
          <td className="grammar__table-data">Oni neumí plavat.</td>
        </tr>
      </tbody>
    </table>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Tázací věta</h3>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. j.č.</td>
          <td className="grammar__table-data">Can I swim?</td>
          <td className="grammar__table-data">Umím plavat?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. j.č.</td>
          <td className="grammar__table-data">Can you swim?</td>
          <td className="grammar__table-data">Umíš plavat?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">Can he swim?</td>
          <td className="grammar__table-data">Umí (on) plavat?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">Can she swim?</td>
          <td className="grammar__table-data">Umí plavat?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. j.č.</td>
          <td className="grammar__table-data">Can it swim?</td>
          <td className="grammar__table-data">Umí plavat?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. os. m.č.</td>
          <td className="grammar__table-data">Can we swim?</td>
          <td className="grammar__table-data">Umíme plavat?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. os. m.č.</td>
          <td className="grammar__table-data">Can you swim?</td>
          <td className="grammar__table-data">Umíte plavat?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. os. m.č.</td>
          <td className="grammar__table-data">Can they swim?</td>
          <td className="grammar__table-data">Umějí plavat?</td>
        </tr>
      </tbody>
    </table>
  </div>,
];

function TimesTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Najpoužívanejšie časy</h2>
      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {timesSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={currentSubtopic === timesSubtopicArray.length - 1}
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function PrepositionsTopic() {
  return (
    <div>
      <h2 className="grammar__topic-name">Predložky</h2>
      <div className="grammar__content-wrapper">
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">in</h3>
          <p className="grammar__text">
            keď vyjadrujem veľké a všeobecné situácie
          </p>
          <ul className="grammar__list">
            <li className="grammar__list-element">
              čas: roky, mesiace, dekády, storočia
            </li>
            <li className="grammar__list-element">miesto: krajiny, mestá</li>
          </ul>
          <p className="grammar__text">Príklady:</p>
          <ul className="grammar__list">
            <li className="grammar__list-element">
              All houses in this neighbourhood are lovely.
            </li>
            <li className="grammar__list-element">
              School starts in September.
            </li>
          </ul>
        </div>

        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">on</h3>
          <p className="grammar__text">
            keď vyjadrujeme menšie, viac špecifické situácie alebo veci
          </p>
          <ul className="grammar__list">
            <li className="grammar__list-element">čas: deň, dátum</li>
            <li className="grammar__list-element">miesto: ulice</li>
          </ul>
          <p className="grammar__text">Príklady:</p>
          <ul className="grammar__list">
            <li className="grammar__list-element">
              I will be there on Monday.
            </li>
            <li className="grammar__list-element">
              Bratislava is a city on a Danube river.
            </li>
          </ul>
        </div>

        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">at</h3>
          <p className="grammar__text">
            keď vyjadrujeme veľmi konkrétne a špecifické situácie alebo miesta
          </p>
          <ul className="grammar__list">
            <li className="grammar__list-element">čas: hodina</li>
            <li className="grammar__list-element">
              miesto: adresa, konkrétne miesto, poloha
            </li>
          </ul>
          <p className="grammar__text">Príklady:</p>
          <ul className="grammar__list">
            <li className="grammar__list-element">
              Young woman is waiting at the bus stop.
            </li>
            <li className="grammar__list-element">
              Go straigt and at the end of this road turn right.
            </li>
          </ul>
        </div>

        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">over a above</h3>
          <p className="grammar__text">
            sa používajú na vyjadrenie toho, že nejaká vec sa nachádza vyššie,
            prípadne je nad nejakou vecou alebo je niečoho viac.
          </p>
          <ul className="grammar__list">
            <li className="grammar__list-element">
              Keď je niečo fyzicky vyššie ako iná vec, môžeme použiť obidve
              predložky a neurobíme žiadnu chybu.
            </li>
          </ul>
          <p className="grammar__text">Príklady:</p>
          <ul className="grammar__list">
            <li className="grammar__list-element">
              My parents have a little cross over/above the door.
            </li>
            <li className="grammar__list-element">
              I have a big board over/above my desk.
            </li>
          </ul>
        </div>

        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">under and below</h3>
          <p className="grammar__text">
            sa používajú na vyjadrenie toho, že nejaká vec sa nachádza nižšie,
            prípadne je pod nejakou vecou alebo niečoho je menej.
          </p>
          <ul className="grammar__list">
            <li className="grammar__list-element">
              Keď je niečo fyzicky nižšie ako iná vec, môžeme použiť obidve
              predložky a neurobíme žiadnu chybu.
            </li>
          </ul>
          <p className="grammar__text">Príklady:</p>
          <ul className="grammar__list">
            <li className="grammar__list-element">
              My bag is under/below the desk.
            </li>
            <li className="grammar__list-element">
              The plate is under/below the cabinet.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

function PossessivePronounsTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Privlastňovacie zámená</h2>
      <p className="grammar__text">
        Privlastňovacie zámená sa v angličtine delia na nesamostatné a
        samostatné. Čo to znamená si hneď ilustrujeme na príklade:
      </p>
      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {possessivePronounsSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={
            currentSubtopic === possessivePronounsSubtopicArray.length - 1
          }
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function VerbToBeTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Sloveso To be</h2>
      <p className="grammar__text">
        Sloveso To be je naprosto základním slovesem, které se studenti
        začátečníci učí jako první.
      </p>
      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {verbToBeSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={currentSubtopic === verbToBeSubtopicArray.length - 1}
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function VerbCanTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Sloveso Can</h2>
      <p className="grammar__text">
        Jedná se o jedno z nejdůležitějších a v angličtině nejpoužívanějších
        sloves. Modální sloveso Can do češtiny nejčastěji překládáme jako „umět“
        nebo „moci„.
      </p>
      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {verbCanSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={currentSubtopic === verbCanSubtopicArray.length - 1}
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function EnglishGrammarSK(props) {
  switch (props.choosenTopic) {
    case "timesTopic":
      return <TimesTopic />;
    case "prepositionsTopic":
      return <PrepositionsTopic />;
    case "possessivePronounsTopic":
      return <PossessivePronounsTopic />;
    case "verbToBeTopic":
      return <VerbToBeTopic />;
    case "verbCanTopic":
      return <VerbCanTopic />;
    default:
      return (
        <div className="grammar">
          <h1 className="grammar__title">Gramatika</h1>
          <div className="grammar__topics-wrapper">
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("timesTopic")}
            >
              Najpoužívanejšie časy
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("prepositionsTopic")}
            >
              Predložky
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("possessivePronounsTopic")}
            >
              Privlastňovacie zámená
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("verbToBeTopic")}
            >
              Sloveso To be
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("verbCanTopic")}
            >
              Sloveso Can
            </button>
          </div>
        </div>
      );
  }
}

export default EnglishGrammarSK;
