function CheckedIcon() {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 0C7.66262 0 0 7.66262 0 17C0 26.3374 7.66262 34 17 34C26.3374 34 34 26.3374 34 17C34 7.66262 26.3374 0 17 0ZM14.9427 24.7323L7.4982 17.2879L10.3153 14.4709L15.073 19.2286L24.6244 10.5458L27.3053 13.4933L14.9427 24.7323Z"
                fill="#64FF84"
            />
        </svg>
    );
}

export default CheckedIcon;
