import AdminSidebar from '../components/AdminSidebar/AdminSidebar';
import './styles.css';

import { Route, Routes } from 'react-router-dom';

import UserTable from './UserTable/UserTable';
import WordPairTable from './WordPairTable/WordPairTable';
import WordPairTableV2 from './WordPairTableV2/WordPairTableV2';
import LessonImages from './LessonImages/LessonImages';
import ActivityStats from './ActivityStats/ActivityStats';
import PollStats from './Poll/PollStats';
import TutorialStats from './TutorialStats/TutorialStats';
import MailSettings from './MailSettings/MailSettings';
import TaskLessons from './TaskLessons/TaskLessons';

function Admin({ setError }) {
    return (
        <div className="admin-page">
            <AdminSidebar />
            <main className="admin-page__main">
                <Routes>
                    <Route
                        exact
                        index
                        element={<UserTable setError={setError} />}
                    ></Route>
                    <Route
                        exact
                        path="word-pair"
                        element={<WordPairTable setError={setError} />}
                    ></Route>
                    <Route
                        exact
                        path="word-pair-v2"
                        element={<WordPairTableV2 setError={setError} />}
                    ></Route>
                    <Route
                        exact
                        path="lesson-images"
                        element={<LessonImages setError={setError} />}
                    ></Route>
                    <Route
                        exact
                        path="activity-stats"
                        element={<ActivityStats setError={setError} />}
                    ></Route>
                    <Route
                        exact
                        path="tutorial-stats"
                        element={<TutorialStats setError={setError} />}
                    ></Route>
                    <Route
                        exact
                        path="polls"
                        element={<PollStats setError={setError} />}
                    ></Route>
                    <Route
                        exact
                        path="mail-settings"
                        element={<MailSettings setError={setError} />}
                    ></Route>
                    <Route
                        exact
                        path="mail-settings"
                        element={<MailSettings setError={setError} />}
                    ></Route>
                    <Route
                        exact
                        path="task-lessons"
                        element={<TaskLessons setError={setError} />}
                    ></Route>
                </Routes>
            </main>
        </div>
    );
}

export default Admin;
