import { useEffect, useState } from 'react';
import MainModal from '../MainModal/MainModal';
import api from '../../../api/api';
import './CertificateModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { downloadBase64File } from '../../../common/functions/downloadBase64File';
import { getMockImage } from './helpers/getMockImage/getMockImage';
import Store from '../../../../Store';

function CertificateModal({ pairId, isModalOpen, setIsModalOpen }) {
    const [isAvailable, setIsAvailable] = useState(true);
    const [isPossibleForDownload, setIsPossibleForDownload] = useState(false);
    const [isAlreadyGenerated, setIsAlreadyGenerated] = useState(false);
    const [name, setName] = useState('');

    const downloadCert = () => {
        api.get(`/certificate/${pairId}/token`)
            .then((tokenRes) => {
                const token = tokenRes.data;
                return api.get(`/certificate?token=${token}`);
            })
            .then((certRes) => {
                downloadBase64File(certRes.data, `certificate.pdf`);
            });
    };

    const sendCert = () => {
        api.post(`/certificate`, { languagePairId: pairId, name }).then(
            () => setIsModalOpen(false)
        );
    };

    useEffect(() => {
        Promise.all([
            api.get(`/certificate/${pairId}/available`),
            api.get(`/certificate/${pairId}/generated`),
            api.get(`/certificate/${pairId}/sent`),
        ]).then(([availableRes, generatedRes, sentRes]) => {
            setIsAvailable(availableRes.data);
            setIsAlreadyGenerated(generatedRes.data);
            setIsPossibleForDownload(sentRes.data);
        });
    }, []);

    return (
        <MainModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            className="certificate-modal"
        >
            <div
                className={`certificate-modal__wrapper ${
                    isAvailable ? '' : 'certificate-modal__wrapper--disabled'
                }`}
            >
                <h2 className="certificate-modal__header">
                    {Store.getText('certificateHeader')}
                </h2>
                {!isAvailable && (
                    <div className="certificate-modal__disabled-message">
                        {Store.getText('certificateNotAllowedHeader')}
                    </div>
                )}
                <div className="certificate-modal__content-wrapper">
                    <img
                        src={getMockImage()}
                        className="certificate-modal__cert-image"
                    />
                    <div className="certificate-modal__cert-form-column">
                        <form className="certificate-modal__cert-form">
                            <p className="certificate-modal__text">
                                {Store.getText('certificateDescription')}
                            </p>
                            <div className="certificate-modal__input-wrapper">
                                <input
                                    placeholder={Store.getText(
                                        'certificateName'
                                    )}
                                    className="certificate-modal__input"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <button
                                    className="certificate-modal__input-button"
                                    disabled={!name || isAlreadyGenerated}
                                    onClick={sendCert}
                                >
                                    {Store.getText('certificateSend')}
                                </button>
                            </div>
                            <div className="certificate-modal__warning">
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="certificate-modal__warning-icon"
                                />
                                <p className="certificate-modal__text">
                                    {Store.getText('certificateWarning')}
                                </p>
                            </div>
                        </form>
                        <div className="certificate-modal__download-wrapper">
                            <p className="certificate-modal__text">
                                {Store.getText('certificateSendInfo')}
                            </p>
                            <button
                                className="certificate-modal__download-button"
                                disabled={!isPossibleForDownload}
                                onClick={downloadCert}
                            >
                                {Store.getText('certificateDownload')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </MainModal>
    );
}

export default CertificateModal;
