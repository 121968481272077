import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { downloadBase64File } from '../../common/functions/downloadBase64File';
import api from '../../api/api';

export const DownloadCertificatePage = () => {
    const navigate = useNavigate();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    useEffect(() => {
        api.get(`/certificate?token=${token}`).then((certRes) => {
            downloadBase64File(certRes.data, `certificate.pdf`);
            navigate('/');
        });
    }, []);
    return <></>;
};
