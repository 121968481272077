import Store from '../../../../../../Store';
import ro from './images/ro.png'

export const mockImages = {
    ro
};

export const getMockImage = () => {
    const short = Store.getUserPlatformLangShort();
    return mockImages[short];
};
