import { useEffect, useMemo, useState } from 'react';
import api from '../../../platform/api/api';
import DatePicker from 'react-datepicker';
import './ActivityStats.css';
import 'react-datepicker/dist/react-datepicker.css';

import MainButton from '../../../platform/components/Buttons/MainButton/MainButton';
import moment from 'moment';

const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

function ActivityStats(props) {
    const [userActivity, setUserActivity] = useState({});

    const [languagePairs, setLanguagePairs] = useState([]);
    const [from, setFrom] = useState(moment().startOf('d').toDate());
    const [to, setTo] = useState(moment().endOf('d').toDate());

    const [languagePairId, setLanguagePairId] = useState();

    const [daysLearing, setDaysLearning] = useState();
    const [words, setWords] = useState();

    const userActivityScore = useMemo(() => {
        return average(
            Object.entries(userActivity).map(([key, value]) => value.length)
        );
    }, [userActivity]);

    const userActivityMap = useMemo(() => {
        return Object.entries(userActivity).reduce((memo, [key, value]) => {
            if (!memo[value.length]) memo[value.length] = 0;
            memo[value.length]++;
            return memo;
        }, {});
    }, [userActivity]);

    useEffect(() => {
        api.get(`/language-pair`).then((res) => {
            const sorted = res.data.sort((a, b) => a.id - b.id);
            setLanguagePairs(sorted);
            setLanguagePairId(sorted[0].id);
        });
    }, []);

    const find = () => {
        if ((languagePairId, from, to)) {
            const msFrom = moment(from).startOf('d').valueOf();
            const msTo = moment(to).endOf('d').valueOf();

            api.get(
                `/language-pair/activity-stats/${languagePairId}/${msFrom}/${msTo}`
            ).then((res) => {
                console.log(res.data.users);
                computeMetrics(res.data.users);
            });
        }
    };

    const count = (arr) => {
        const counts = {};

        for (const num of arr) {
            counts[num] = counts[num] ? counts[num] + 1 : 1;
        }

        return counts;
    };

    const computeMetrics = (data) => {
        const daysLearing = data.map((user) => {
            const userCreatedAt = moment(user.timestamps.createdAt);
            let activitiesDates = user.userLessonActivities
                .filter(
                    (activity) =>
                        moment(activity.timestamps.createdAt).diff(
                            userCreatedAt,
                            'd'
                        ) < 30
                )
                .map((activity) =>
                    moment(activity.timestamps.createdAt).format('YYYY-MM-DD')
                );
            activitiesDates = [...new Set(activitiesDates)];

            return activitiesDates.length;
        });

        const wordsCompleted = data.map((user) => {
            let words = user.userLessonActivities.filter(
                (activity) => activity.type === 'word'
            );
            words = [...new Set(words)];

            return words.length;
        });

        setDaysLearning({
            count: count(daysLearing),
            p50: quantile(daysLearing, 0.5).toFixed(2),
            p80: quantile(daysLearing, 0.8).toFixed(2),
            p90: quantile(daysLearing, 0.9).toFixed(2),
            p95: quantile(daysLearing, 0.95).toFixed(2),
        });
        setWords({
            count: count(wordsCompleted),
            p50: quantile(wordsCompleted, 0.5).toFixed(2),
            p80: quantile(wordsCompleted, 0.8).toFixed(2),
            p90: quantile(wordsCompleted, 0.9).toFixed(2),
            p95: quantile(wordsCompleted, 0.95).toFixed(2),
        });
    };

    const asc = (arr) => arr.sort((a, b) => a - b);

    const quantile = (arr, q) => {
        const sorted = asc(arr);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const value = sorted[base];
        const sameValuesStart = sorted.findIndex((val) => val === value);
        const sameValues = sorted.filter((val) => val === value);
        const rest = pos - base;
        let quant;
        if (sorted[base + 1] !== undefined) {
            quant = sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
            quant = sorted[base];
        }

        if(sameValues.length === 1) return quant;

        const X = pos - sameValuesStart;
        const N = sameValues.length;

        return quant - q + X/N;

    };
    return (
        <div className="activity-stats-page">
            <div
                style={{
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '100%',
                    gridGap: '10px',
                    marginBottom: '20px',
                }}
            >
                <select
                    value={languagePairId}
                    onChange={(e) => setLanguagePairId(e.target.value)}
                >
                    {languagePairs.map((pair) => (
                        <option value={pair.id}>
                            {pair.languageFrom.language}-
                            {pair.languageTo.language}
                        </option>
                    ))}
                </select>
                <div>
                    <p>From</p>
                    <DatePicker
                        selected={from}
                        onChange={(date) => setFrom(date)}
                    />
                </div>
                <div>
                    <p>To</p>
                    <DatePicker
                        selected={to}
                        onChange={(date) => setTo(date)}
                    />
                </div>
                <button onClick={find}>Szukaj</button>
            </div>
            {/* <div className="activity-stats-page__user-score">
                Średnia: {userActivityScore.toFixed(1)}
            </div> */}

            {daysLearing && (
                <>
                    <p style={{ marginBottom: '10px', fontWeight: '700' }}>
                        Dni nauki(30 dni od rejestracji)
                    </p>

                    <div style={{ display: 'flex' }}>
                        <div className="activity-stats-page__user-score">
                            Percentyl 50%: {daysLearing.p50}
                        </div>
                        <div className="activity-stats-page__user-score">
                            Percentyl 80%: {daysLearing.p80}
                        </div>
                        <div className="activity-stats-page__user-score">
                            Percentyl 90%: {daysLearing.p90}
                        </div>
                        <div className="activity-stats-page__user-score">
                            Percentyl 95%: {daysLearing.p95}
                        </div>
                    </div>
                </>
            )}

            {words && (
                <>
                    <p style={{ marginBottom: '10px', fontWeight: '700' }}>
                        Liczba słów
                    </p>

                    <div style={{ display: 'flex' }}>
                        <div className="activity-stats-page__user-score">
                            Percentyl 50%: {words.p50}
                        </div>
                        <div className="activity-stats-page__user-score">
                            Percentyl 80%: {words.p80}
                        </div>
                        <div className="activity-stats-page__user-score">
                            Percentyl 90%: {words.p90}
                        </div>
                        <div className="activity-stats-page__user-score">
                            Percentyl 95%: {words.p95}
                        </div>
                    </div>
                </>
            )}

            {daysLearing && (
                <>
                    <p>Dni nauki(30 dni od rejestracji)</p>

                    <table className="activity-stats-page__table">
                        <thead>
                            <th className="activity-stats-page__table-header">
                                Liczba dni
                            </th>
                            <th className="activity-stats-page__table-header">
                                Liczba użytkowników
                            </th>
                        </thead>
                        {Object.keys(daysLearing.count)
                            .sort((a, b) => parseInt(a) - parseInt(b))
                            .map((key) => (
                                <tr className="activity-stats-page__table-row">
                                    <td className="activity-stats-page__table-cell">
                                        {key}
                                    </td>
                                    <td className="activity-stats-page__table-cell">
                                        {daysLearing.count[key]}
                                    </td>
                                </tr>
                            ))}
                    </table>
                </>
            )}

            {words && (
                <>
                    <p style={{ marginBottom: '10px', fontWeight: '700' }}>
                        Liczba słów
                    </p>

                    <table className="activity-stats-page__table">
                        <thead>
                            <th className="activity-stats-page__table-header">
                                Liczba słówek
                            </th>
                            <th className="activity-stats-page__table-header">
                                Liczba użytkowników
                            </th>
                        </thead>
                        {Object.keys(words.count)
                            .sort((a, b) => parseInt(a) - parseInt(b))
                            .map((key) => (
                                <tr className="activity-stats-page__table-row">
                                    <td className="activity-stats-page__table-cell">
                                        {key}
                                    </td>
                                    <td className="activity-stats-page__table-cell">
                                        {words.count[key]}
                                    </td>
                                </tr>
                            ))}
                    </table>
                </>
            )}
        </div>
    );
}

export default ActivityStats;
