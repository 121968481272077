import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import transparentCloud from "../../../images/boxes/dymek_obrys_prawy_gorny.png";
import { Cloud } from "../../reusable/exportedStyles/exportedStyles";

const H1 = styled.h1`
  font-weight: lighter;
  font-size: 30px;
  max-width: auto;
  padding: 10px 20px 10px 10px;
  text-align: center;
  line-height: 1.2;
  color: ${Colors.yellow};
`;

const Wrapper = styled.div`
  width: 280px;
  overflow: hidden;
  height: 100px;
  margin: 30px;
  float: right;
`;

const CourseCloud = ({ title }) => {
  return (
    <div>
      <Wrapper>
        <div style={{ width: "250px", margin: "0px" }}>
          <Cloud img={transparentCloud}>
            <H1>{title}</H1>
          </Cloud>
        </div>
      </Wrapper>
    </div>
  );
};

export default CourseCloud;
