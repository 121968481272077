import moment from 'moment';
import React from 'react';

export const CONVERSATION_STATUS = {
    NEW: 'New',
    COMPLETED: 'Completed',
    NEW_IN_CONVERSATION: 'New in conversation',
    SEEN: 'Seen',
};

const getTypeText = ({ type }) => {
    switch (type) {
        case 'question':
            return 'Zapytaj eksperta';
        case 'activity':
            return 'Praca domowa';
        default:
            return '';
    }
};

const MailBoxTable = ({ data, setConversation }) => {
    const getStatusStyle = (status) => {
        switch (status) {
            case CONVERSATION_STATUS.NEW:
                return 'status status-new';
            case CONVERSATION_STATUS.COMPLETED:
                return 'status status-completed';
            case CONVERSATION_STATUS.NEW_IN_CONVERSATION:
                return 'status status-new_in_conversation';
            case CONVERSATION_STATUS.SEEN:
                return 'status status-seen';
            default:
                return null;
        }
    };

    return (
        <div className="mailbox-table">
            <div className="mailbox-table__style">
                <table cellSpacing="0">
                    <thead className="mailbox-thead">
                        <tr style={{ width: '100%' }}>
                            <th style={{ width: '15%' }}>Nazwa Użytkownika</th>
                            <th style={{ width: '50%' }}>Temat</th>
                            <th style={{ width: '20%' }}>Data</th>
                            <th style={{ width: '15%' }}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map((element) => (
                                <tr
                                    key={element.topic + '' + element.username}
                                    onClick={() => setConversation(element)}
                                >
                                    <td>{element.user.username}</td>
                                    <td>
                                        <b>{getTypeText(element)}:</b>{' '}
                                        {element.name} (Lekcja:{' '}
                                        {element.lesson.number}.
                                        {element.lesson.subNumber} Język:{' '}
                                        {
                                            element.languagePair.languageFrom.languageShort
                                        }
                                        -
                                        {
                                            element.languagePair.languageTo.languageShort
                                        })
                                    </td>

                                    <td>
                                        {moment(
                                            element.timestamps.createdAt
                                        ).format('DD-MM-YYYY')}
                                    </td>
                                    <td>
                                        <span
                                            className={getStatusStyle(
                                                element.consultantStatus
                                            )}
                                        >
                                            {element.consultantStatus}
                                        </span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr></tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MailBoxTable;
