import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Store from '../../../../Store';
import getLevelColor from '../../../common/functions/getLevelColor';
import ActivityButton from '../../Buttons/ActivityButton/ActivityButton';
import MainButton from '../../Buttons/MainButton/MainButton';
import CheckedIcon from './CheckedIcon';
import SendModal from './components/SendModal/SendModal';
import './TaskActivity.css';

function TaskActivity({ activityData, makeAnswer, isLessonRepeated }) {
    const [isExampleExpanded, setIsExampleExpanded] = useState(false);
    const [taskAnswer, setTaskAnswer] = useState('');

    const [sendModalOpen, setSendModalOpen] = useState(false);

    const onTextareChange = (e) => {
        setTaskAnswer(e.target.value.replace(/(?:\r\n|\r|\n)/g, ''));
    };

    const onModalClose = () => {
        setSendModalOpen(false);
        makeAnswer(true, taskAnswer);
    };

    const onSend = () => {
        setSendModalOpen(true);
    };

    const onSkip = () => {
        makeAnswer(true);
    };

    return (
        <div className="task-activity">
            <SendModal
                isModalOpen={sendModalOpen}
                onModalClose={onModalClose}
            />
            <p className="task-activity_text task-activity_text--bold">
               {Store.getText('taskActivityTitle')}
            </p>
            <p className="task-activity_text">
                {Store.getText('taskActivityDescription')}{' '}
            </p>
            <p className="task-activity_text task-activity_text--margin">
            {Store.getText('taskActivitySecondDescription')}
            </p>
            <h2 className="task-activity__title">
            {Store.getText('taskActivityName')}{' '} {activityData.taskContent}
            </h2>
            <div className="task-activity__textarea-wrapper">
                <textarea
                    className="task-activity__textarea"
                    onChange={onTextareChange}
                    value={taskAnswer}
                    placeholder={activityData.taskPlaceholder}
                    style={{
                        borderColor: getLevelColor(activityData.level),
                    }}
                    disabled={isLessonRepeated}
                ></textarea>

                {isLessonRepeated && (
                    <div className="task-activity__checked-icon-wrapper">
                        <CheckedIcon />
                        <span>{Store.getText('taskActivityDone')}</span>
                    </div>
                )}
            </div>
            <div className="task-activity__example-wrapper">
                {isExampleExpanded && (
                    <div
                        className="task-activity__example-text"
                        style={{
                            backgroundColor: getLevelColor(activityData.level),
                        }}
                    >
                        {activityData.taskExample}
                    </div>
                )}
                <span
                    style={{ color: getLevelColor(activityData.level) }}
                    className="task-activity__example-expander"
                    onClick={() => setIsExampleExpanded((prev) => !prev)}
                >
                    <FontAwesomeIcon
                        icon={faLightbulb}
                        style={{ color: getLevelColor(activityData.level) }}
                        className="task-activity__example-icon"
                    />
                    {Store.getText('taskActivityExample')}
                </span>
            </div>
            <div className="task-activity__button-wrapper">
                <MainButton
                    className="task-activity__button task-activity__button--skip"
                    onClick={onSkip}
                >
                    {Store.getText('taskActivitySkip')}
                </MainButton>
                <MainButton
                    className="task-activity__button"
                    onClick={onSend}
                    disabled={!taskAnswer || isLessonRepeated}
                >
                    {Store.getText('taskActivitySend')}
                </MainButton>
            </div>
        </div>
    );
}

export default TaskActivity;
