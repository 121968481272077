import Store from "../../../Store"



function getLanguageCapitalWeatherCodes(languageShort) {

    const LANGUAGE_CAPITAL_MAP = {
        'pl': {
            html: `<div id="ww_0577086882346" v='1.20' loc='id' a='{"t":"horizontal","lang":"${Store.getUserPlatformLangShort()}","ids":["wl7640"],"cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_sot":"celsius","sl_ics":"one_a","font":"Arial","el_phw":3}'>Weather for the Following Location: <a href="https://2ua.org/pol/warsaw/map/" id="ww_0577086882346_u" target="_blank">Warsaw map, Poland</a></div>`,
            script: 'https://srv2.weatherwidget.org/js/?id=ww_0577086882346'
        },
        'en': {
            html: `<div id="ww_9d5e0086f63f1" v='1.20' loc='id' a='{"t":"horizontal","lang":"${Store.getUserPlatformLangShort()}","ids":["wl4419"],"cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_sot":"celsius","sl_ics":"one_a","font":"Arial"}'>Weather Data Source: <a href="https://wetterlabs.de/wetter_london/heute/" id="ww_9d5e0086f63f1_u" target="_blank">Wetter Heute London</a></div>`,
            script: 'https://srv2.weatherwidget.org/js/?id=ww_9d5e0086f63f1'
        },
        'it': {
            html: `<div id="ww_becc9a4b70660" v='1.20' loc='id' a='{"t":"horizontal","lang":"${Store.getUserPlatformLangShort()}","ids":["wl2405"],"cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_sot":"celsius","sl_ics":"one_a","font":"Arial"}'>Weather Data Source: <a href="https://wetterlabs.de/wetter_rom/heute/" id="ww_becc9a4b70660_u" target="_blank">Wetter Heute Rom</a></div>`,
            script: 'https://srv2.weatherwidget.org/js/?id=ww_becc9a4b70660'
        },
        'de': {
            html: `<div id="ww_b18c63d6411a2" v='1.20' loc='id' a='{"t":"horizontal","lang":"${Store.getUserPlatformLangShort()}","ids":["wl3971"],"cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_sot":"celsius","sl_ics":"one_a","font":"Arial"}'>Weather Data Source: <a href="https://wetterlabs.de/wetter_berlin/heute/" id="ww_b18c63d6411a2_u" target="_blank">Wetter Heute Berlin</a></div>`,
            script: 'https://srv2.weatherwidget.org/js/?id=ww_b18c63d6411a2'
        },
        'es': {
            html: `<div id="ww_e1fedd5103f5c" v='1.20' loc='id' a='{"t":"horizontal","lang":"${Store.getUserPlatformLangShort()}","ids":["wl6516"],"cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_sot":"celsius","sl_ics":"one_a","font":"Arial"}'>Weather for the Following Location: <a href="https://2ua.org/es/esp/md/madrid/mapa/" id="ww_e1fedd5103f5c_u" target="_blank">Madrid mapa, España</a></div>`,
            script: 'https://srv2.weatherwidget.org/js/?id=ww_e1fedd5103f5c'
        },
        'fr': {
            html: `<div id="ww_689cfe2b364bc" v='1.20' loc='id' a='{"t":"horizontal","lang":"${Store.getUserPlatformLangShort()}","ids":["wl4529"],"cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_sot":"celsius","sl_ics":"one_a","font":"Arial"}'>Weather Data Source: <a href="https://wetterlabs.de/wetter_paris/heute/" id="ww_689cfe2b364bc_u" target="_blank">Wetter Heute Paris</a></div>`,
            script: 'https://srv2.weatherwidget.org/js/?id=ww_689cfe2b364bc'
        },
        'nl': {
            html: `<div id="ww_07793ede4e8ab" v='1.20' loc='id' a='{"t":"horizontal","lang":"${Store.getUserPlatformLangShort()}","ids":["wl7872"],"cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_sot":"celsius","sl_ics":"one_a","font":"Arial"}'>Weather for the Following Location: <a href="https://2ua.org/nld/amsterdam/map/" id="ww_07793ede4e8ab_u" target="_blank">Amsterdam map, Netherlands</a></div>`,
            script: 'https://srv2.weatherwidget.org/js/?id=ww_07793ede4e8ab'
        },
        'ru': {
            html: `<div id="ww_90b60d5bccca4" v='1.20' loc='id' a='{"t":"horizontal","lang":"${Store.getUserPlatformLangShort()}","ids":["wl3996"],"cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_sot":"celsius","sl_ics":"one_a","font":"Arial"}'>Weather Data Source: <a href="https://wetterlabs.de/wetter_moskau/5_tage/" id="ww_90b60d5bccca4_u" target="_blank">wetter 5 tage Moskau</a></div>`,
            script: 'https://srv1.weatherwidget.org/js/?id=ww_90b60d5bccca4'
        },
        'pt': {
            html: `<div id="ww_149c3ae7456b4" v='1.20' loc='id' a='{"t":"horizontal","lang":"${Store.getUserPlatformLangShort()}","ids":["wl3876"],"cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_sot":"celsius","sl_ics":"one_a","font":"Arial"}'>Weather Data Source: <a href="https://wetterlabs.de/wetter_lissabon/14_tage/" id="ww_149c3ae7456b4_u" target="_blank">14 tage wetter Lissabon</a></div>`,
            script: 'https://srv1.weatherwidget.org/js/?id=ww_149c3ae7456b4'
        },
    }
    return LANGUAGE_CAPITAL_MAP[languageShort]
}
export default getLanguageCapitalWeatherCodes