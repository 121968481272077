import { useState } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../GrammarModal.css";

const gendersAndArticlesSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Rody</h3>
    <p className="grammar__text">
      Španielčina má na rozdiel od slovenčiny iba dva rody – mužský a ženský.
      Slová v slovenčine a španielčine sa preto zvyčajne nezhodujú! Napr. láska
      je rodu ženského, el amor však rodu mužského.
    </p>
    <p className="grammar__text">
      Obyčajne bývajú podstatné mená rodu mužského zakončené písmenom o a ženské
      písmenom a:
    </p>
    <ul className="grammar__list">
      <li className="grammar__list-element">el chico – chlapec</li>
      <li className="grammar__list-element">la chica – dievča</li>
    </ul>
    <p className="grammar__text">Môžu sa však končiť aj inak:</p>
    <ul className="grammar__list">
      <li className="grammar__list-element">el amor – láska</li>
      <li className="grammar__list-element">el corazón – srdce</li>
      <li className="grammar__list-element">el profesor – profesor, učiteľ</li>
      <li className="grammar__list-element">la frase – fráza, veta</li>
      <li className="grammar__list-element">la tarde – popoludnie</li>
      <li className="grammar__list-element">la ciudad – mesto</li>
    </ul>
    <p className="grammar__text">
      A pri niektorých podstatných menách je rod napriek koncovke iný (napriek
      tomu, že sa končí na a alebo o):
    </p>
    <ul className="grammar__list">
      <li className="grammar__list-element">el día – deň</li>
      <li className="grammar__list-element">el problema – problém</li>
      <li className="grammar__list-element">el idioma – jazyk</li>
      <li className="grammar__list-element">el poema – báseň</li>
      <li className="grammar__list-element">el sofá – pohovka, gauč</li>
      <li className="grammar__list-element">la foto – fotografia</li>
      <li className="grammar__list-element">la mano – ruka</li>
    </ul>
  </div>,
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Členy</h3>
    <p className="grammar__text">
      Spravidla (ale nie vždy) stoja pred podstatným menom vo vete určitý alebo
      neurčitý člen. V španielčine máme, v závislosti na rode, určité členy el a
      la a neurčité un, una.
    </p>
    <p className="grammar__text">
      Určitý člen sa používa, keď hovoríme o veciach jedinečných alebo bližšie
      určených, zatiaľ čo členy un a una používame pri veciach nových, o ktorých
      sa doteraz nehovorilo. Je to všetko však zložitejšie a zbytočne by vás to
      plietlo.
    </p>
    <p className="grammar__text">
      Príklad: Hay un parque cerca mi casa. El parque se llama Zámocký park.
      Blízko môjho domu je park. Ten park sa volá Zámocký park.
    </p>
    <p className="grammar__text">
      V druhej vete používame určitý člen, pretože hovoríme o parku, ktorý sme
      už predstavili v predchádzajúcej vete. Táto veta by sa dala napísať
      pomocou osobného zámena: Él se llama Zámocký park.
    </p>
  </div>,
];

const timesSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Indicativo</h3>
    <p className="grammar__text">
      Oznamovací spôsob v španielčine zahŕňa aj náš opytovací a podmieňovací i s
      určitými odlišnosťami.
    </p>

    <ul className="grammar__list">
      <li className="grammar__list-element">
        Presente de indicativo – Prítomný čas
      </li>
      <li className="grammar__list-element">
        Préterito perfecto de indicativo – Predprítomný čas
      </li>
      <li className="grammar__list-element">
        Préterito imperfecto de indicativo – Minulý čas (opisný)
      </li>
      <li className="grammar__list-element">
        Préterito indefinido de indicativo – Minulý čas
      </li>
      <li className="grammar__list-element">
        Préterito pluscuamperfecto de indicativo – Predminulý čas
      </li>
      <li className="grammar__list-element">
        Futuro simple de indicativo – Budúci čas
      </li>
      <li className="grammar__list-element">
        Futuro perfecto de indicativo – Predbudúci čas
      </li>
      <li className="grammar__list-element">
        Condicional simple – Jednoduchý "podmieňovací" čas
      </li>
      <li className="grammar__list-element">
        Condicional perfecto – Zložený "podmieňovací" čas
      </li>
    </ul>
    <p className="grammar__text">Iné formy</p>
    <p className="grammar__text">
      Španielčina pozná aj prítomný priebehový čas podobný tomu anglickému. Nemá
      vlastný názov, ale hovorí sa mu "Gerundio" a prekladá sa ako náš
      prechodník. Tvorí sa pomocou slovesa ESTAR + GERUNDIO (prik. Estoy
      hablando de él / Hovorím o ňom)
    </p>
    <p className="grammar__text">Interpunkcia</p>
    <p className="grammar__text">
      Každá opytovacia veta v španielčina musí končiť a aj začínať otáznikom s
      tým, že otáznik na začiatku vety je prevrátený.
    </p>

    <p className="grammar__text">¿Qué pasa? - Čo sa deje?</p>
  </div>,
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Imperativo</h3>
    <p className="grammar__text">
      Rozkazovací spôsob v španielčine patrí medzi veľmi náročný okruh, ktorý
      študujúcim španielčinu robí nemalé problémy. Rozkazovací spôsob sa používa
      najčastejšie v osobách Tú, Usted, Vosotros, Ustedes a niekedy no zriedka
      Nosotros.
    </p>
  </div>,
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Subjuntivo</h3>
    <p className="grammar__text">
      Spojovací čas je v španielčina veľmi častý. Najčastejšie vyjadruje túžby,
      žiadosti, rozkazy, etc. Existujú 4:
    </p>
    <p className="grammar__text">
      Presente de subjuntivo - spojovací prítomný čas
    </p>
    <p className="grammar__text">
      Imperfecto de subjuntivo - spojovací minulý čas
    </p>
    <p className="grammar__text">
      Pluscuamperfecto de subjuntivo - spojovací predminulý čas
    </p>
    <p className="grammar__text">
      Préterito perfecto de subjuntivo - spojovací predprítomný čas
    </p>
    <p className="grammar__text">
      Príklady: Quiero que vengan. - Chcem, aby prišli.
    </p>
    <p className="grammar__text">
      Si lo supieras, lo dirías. - Ak by si to vedel, povedal by si to.
    </p>
    <p className="grammar__text">
      Ojalá no hubiera ganado. - Kiežby by nebol vyhral.
    </p>
    <p className="grammar__text">
      Te agradezco que hayas venido. - Ďakujem, že si prišiel.
    </p>
  </div>,
];

const diminutiveWordsSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Zdrobnené slová</h3>
    <p className="grammar__text">
      Zdrobneniny (diminutivos) majú okrem vyjadrenia významu zmenšeného rozmeru
      aj význam lichotivý, maznavý, niekedy súcitný.
    </p>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Na vyjadrenie zdrobnenín sa používajú prípony:
      </p>
      <p className="grammar__text">-ito, -a</p>
      <p className="grammar__text">-illo, -a</p>
    </div>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Tío ti<b>íto</b>
      </p>
      <p className="grammar__text">
        Hermana herman<b>ita</b>
      </p>
      <p className="grammar__text">
        Pronto pront<b>ito</b>
      </p>
      <p className="grammar__text">
        Ventana ventan<b>illa</b>
      </p>
    </div>
    <p className="grammar__text">
      Niektoré slová tvoria zdrobneniny pomocou: -cillo, -cito, ide najmä o
      slová viacslabičné, ktoré sa končia na –r, -n, -e.
    </p>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Corazón corazon<b>cito</b>
      </p>
      <p className="grammar__text">
        Pobre pobre<b>cito</b>
      </p>
      <p className="grammar__text">
        Madre madre<b>cita</b>
      </p>
      <p className="grammar__text">
        Flor flore<b>cita</b>
      </p>
    </div>
    <div className="grammar__inner-section">
      <p className="grammar__text">Slová zveličené</p>
      <p className="grammar__text">
        Zveličené slová (aumentativos) sa najčastejšie tvoria v španielčine
        príponami:
      </p>
      <p className="grammar__text">-ón</p>
      <p className="grammar__text">-azo</p>
      <p className="grammar__text">-acho</p>
      <p className="grammar__text">-ote</p>
    </div>
    <p className="grammar__text">
      Poznámka: Prípony nadobúdajú tvary ženského rodu aj množného čísla.
    </p>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Hombre hombr<b>azo</b> / hombr<b>acho</b>
      </p>
      <p className="grammar__text">
        Golpe golpet<b>azo</b>
      </p>
      <p className="grammar__text">
        Mujer mujer<b>ona</b>
      </p>
    </div>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Prípona –azo sa zvyčajne používa na vyjadrenie úderu, rany a pod.:
      </p>
      <p className="grammar__text">
        Martillo (kladivo) → Martillazo (úder kladivom)
      </p>
      <p className="grammar__text">Cañón (delo) → cañonazo (rana z dela)</p>
      <p className="grammar__text">Puerta (dvere) → portazo (úder dverami)</p>
      <p className="grammar__text">Látigo (bič) → latigazo (švihnutie bičom)</p>
    </div>
    <p className="grammar__text">
      <b>Úlohy na záver:</b>
    </p>
    <ol className="grammar__ordered-list">
      <li className="grammar__ordered-list-element">
        Vytvorte z týchto slov zdrobneniny a použite ich vo vetách:
        <p>Lola, chico, hermana, abuelo, temprano, tarde, momento, zapatos</p>
      </li>
      <li className="grammar__ordered-list-element">
        Vytvorte z týchto slov zveličené slová a použite ich vo vetách:{" "}
        <p>Homre, mujer, látigo, puerta, cama, martillo</p>
      </li>
    </ol>
  </div>,
];

const tensesCreationSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">
      Zložený minulý čas (Pretérito Perfecto)
    </h3>
    <p className="grammar__text">
      Zložený minulý čas sa skladá z prítomného času slovesa ‘haber’ a príčastia
      plnovýznamového slovesa. Prítomný čas ‘haber’:
    </p>
    <div className="grammar__inner-section">
      <p className="grammar__text">yo he – tú has – él, ella, usted ha</p>
      <p className="grammar__text">
        nosotros, nosotras hemos -– vosotros, vosotras habéis -– ellos, ellas,
        ustedes han
      </p>
    </div>
    <p className="grammar__text">
      Príčastie sa tvorí koncovkou ‘–ado’ (u slovies na ‘–ar’) alebo ‘–ido’ (u
      slovies na ‘–er’, ‘-ir’)
    </p>
    <div className="grammar__inner-section">
      <p className="grammar__text">Príklady:</p>
      <p className="grammar__text">cantar -– cantado</p>
      <p className="grammar__text">comer –- comido</p>
      <p className="grammar__text">estar –- estado</p>
      <p className="grammar__text">tener –- tenido</p>
      <p className="grammar__text">salir - salido</p>
    </div>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Niektoré slovesá majú príčastie minulé nepravidelné:
      </p>
      <p className="grammar__text">ser –- sido</p>
      <p className="grammar__text">abrir –- abierto</p>
      <p className="grammar__text">decir –- dicho</p>
      <p className="grammar__text">hacer -– hecho</p>
      <p className="grammar__text">escribir -– escrito</p>
      <p className="grammar__text">poner –- puesto</p>
      <p className="grammar__text">ver -– visto</p>
      <p className="grammar__text">romper –- roto</p>
      <p className="grammar__text">volver – vuelto</p>
    </div>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Zložený minulý čas sa používa na vyjadrenie v minulosti ukončených
        dejov, ktoré majú nejakú súvislosť s prítomnosťou.
      </p>
    </div>
    <div className="grammar__inner-section">
      <p className="grammar__text">Príklady:</p>
      <p className="grammar__text">Hola, Pepe. ¿Dónde has estado?</p>
      <p className="grammar__text">¿Habéis terminado?</p>
      <p className="grammar__text">¿Qué han hecho Pepe y María hoy?</p>
      <p className="grammar__text">¿Has estado alguna vez en España?</p>
      <p className="grammar__text">Nunca he visitado Madrid.</p>
      <p className="grammar__text">¿Has pagado la cuenta?</p>
      <p className="grammar__text">
        POZNÁMKA: V niektorých častiach Španielska, napríklad v Madride, sa
        zložený minulý čas používa na vyjadrenie všetkých minulých dejov:
      </p>
    </div>
    <div className="grammar__inner-section">
      <p className="grammar__text">¿Cuándo han llegado? – Han llegado ayer.</p>
      <p className="grammar__text">
        ¿Has terminado tus estudios? – Sí, los he terminado el año pasado.
      </p>
      <p className="grammar__text">
        V iných regiónoch Španielska a v mnohých krajinách Latinskej Ameriky sa
        zložený minulý čas používa veľmi zriedkavo. Namiesto neho sa používa
        jednoduchý minulý čas.
      </p>
    </div>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">
      Jednoduchý minulý čas (Pretérito Indefinido)
    </h3>
    <p className="grammar__text">Pravidelné koncovky:</p>

    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header"></th>
          <th className="grammar__table-header">Slovesá na –ar</th>
          <th className="grammar__table-header">Slovesá na –er</th>
          <th className="grammar__table-header">Slovesá na -ir</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data"></td>
          <td className="grammar__table-data">hablar</td>
          <td className="grammar__table-data">comer</td>
          <td className="grammar__table-data">vivir</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Yo</td>
          <td className="grammar__table-data">hablé</td>
          <td className="grammar__table-data">comí</td>
          <td className="grammar__table-data">viví</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Tú</td>
          <td className="grammar__table-data">hablaste</td>
          <td className="grammar__table-data">comiste</td>
          <td className="grammar__table-data">viviste</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Él, ella, usted </td>
          <td className="grammar__table-data">habló</td>
          <td className="grammar__table-data">comió</td>
          <td className="grammar__table-data">vivió</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Nosotros/as</td>
          <td className="grammar__table-data">hablamos</td>
          <td className="grammar__table-data">comimos</td>
          <td className="grammar__table-data">vivimos</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Vosotros/as</td>
          <td className="grammar__table-data">hablasteis</td>
          <td className="grammar__table-data">comisteis</td>
          <td className="grammar__table-data">vivisteis</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Ellos/as ustedes</td>
          <td className="grammar__table-data">hablaron</td>
          <td className="grammar__table-data">comieron</td>
          <td className="grammar__table-data">vivieron</td>
        </tr>
      </tbody>
    </table>

    <div className="grammar__inner-section">
      <p className="grammar__text">
        U niektorých slovies končiacich na ‘-ir’, ako napríklad pedir alebo
        sentir, sa mení ‘–e-’ na ‘–i-’ v tretej osobe jednotného aj množného
        čísla. U slovies končiacich na ‘-ir’, ako napríklad dormir, sa mení
        ‘–o-’ na ‘–u-’ v tretej osobe jednotného aj množného čísla.
      </p>
    </div>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Jednoduchý minulý čas používame, keď chceme vyjadriť dej, ktorý sa
        odohral v presne danej a známej chvíli v minulosti.
      </p>
    </div>
    <ul className="grammar__list">
      <li className="grammar__list-element">
        ¿Qué hiciste el domingo? – Fui al parque.
      </li>
      <li className="grammar__list-element">
        ¿Has hablado con el jefe? – Sí, hablé con él ayer.
      </li>
      <li className="grammar__list-element">
        ¿Cuándo descubrió Colón América? – Hizo su primer viaje en 1492.
      </li>
    </ul>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">
      Imperfektum (Pretérito Imperfecto)
    </h3>
    <p className="grammar__text">Pravidelné koncovky:</p>

    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Osoba</th>
          <th className="grammar__table-header">Slovesá na -ar</th>
          <th className="grammar__table-header">Slovesá na -er</th>
          <th className="grammar__table-header">Slovesá na -ir</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Yo</td>
          <td className="grammar__table-data">estudiaba</td>
          <td className="grammar__table-data">comía</td>
          <td className="grammar__table-data">vivía</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Tú</td>
          <td className="grammar__table-data">estudiabas</td>
          <td className="grammar__table-data">comías</td>
          <td className="grammar__table-data">vivías</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Él, ella, usted</td>
          <td className="grammar__table-data">vivías</td>
          <td className="grammar__table-data">comía</td>
          <td className="grammar__table-data">vivía</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Nosotros/as</td>
          <td className="grammar__table-data">estudiábamos</td>
          <td className="grammar__table-data">comíamos</td>
          <td className="grammar__table-data">vivíamos</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Vosotros/as</td>
          <td className="grammar__table-data">estudiabais</td>
          <td className="grammar__table-data">comíais</td>
          <td className="grammar__table-data">vivíais</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Ellos, ellas, ustedes</td>
          <td className="grammar__table-data">estudiaban</td>
          <td className="grammar__table-data">comían</td>
          <td className="grammar__table-data">vivían</td>
        </tr>
      </tbody>
    </table>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Imperfektum sa používa na vyjadrenie deja, ktorý bol prerušený iným
        dejom. Tiež ním vyjadrujeme minulé zvyky alebo opakované deje.
      </p>
    </div>
    <div className="grammar__inner-section">
      <p className="grammar__text">Príklad:</p>
    </div>
    <ul className="grammar__list">
      <li className="grammar__list-element">
        ¿Qué hiciste el sábado? - Nada. Llovía, mi novio trabajaba, ponían una
        película muy buena en la tele, así que me quedé toda la tarde en casa.
      </li>
      <li className="grammar__list-element">
        Cuando yo era joven me gustaba mucho jugar al fútbol.
      </li>
    </ul>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Budúci čas (Futuro Imperfecto)</h3>
    <p className="grammar__text">
      Koncovky budúceho času sa pridávajú k infinitívu slovesa a sú rovnaké vo
      všetkých troch konjugáciách:
    </p>

    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Osoba</th>
          <th className="grammar__table-header">Koncovka</th>
          <th className="grammar__table-header">estar</th>
          <th className="grammar__table-header">ser</th>
          <th className="grammar__table-header">ir</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Yo</td>
          <td className="grammar__table-data">-é</td>
          <td className="grammar__table-data">estaré</td>
          <td className="grammar__table-data">seré</td>
          <td className="grammar__table-data">iré</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Tú</td>
          <td className="grammar__table-data">-ás</td>
          <td className="grammar__table-data">estarás</td>
          <td className="grammar__table-data">serás</td>
          <td className="grammar__table-data">irás</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Él, ella, usted</td>
          <td className="grammar__table-data">-á</td>
          <td className="grammar__table-data">estará</td>
          <td className="grammar__table-data">será</td>
          <td className="grammar__table-data">irá</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Nosotros/as</td>
          <td className="grammar__table-data">-emos</td>
          <td className="grammar__table-data">estaremos</td>
          <td className="grammar__table-data">seremos</td>
          <td className="grammar__table-data">iremos</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Vosotros/as</td>
          <td className="grammar__table-data">-éis</td>
          <td className="grammar__table-data">estaréis</td>
          <td className="grammar__table-data">seréis</td>
          <td className="grammar__table-data">iréis</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Ellos/as ustedes</td>
          <td className="grammar__table-data">-á</td>
          <td className="grammar__table-data">estarán</td>
          <td className="grammar__table-data">serán</td>
          <td className="grammar__table-data">irán</td>
        </tr>
      </tbody>
    </table>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Niektoré slovesá majú v budúcom čase nepravidelný koreň, ale pravidelné
        koncovky.
      </p>
    </div>
    <ul className="grammar__list">
      <li className="grammar__list-element">
        querer querré, querrás, querrá, querremos, querréis, querrán
      </li>
      <li className="grammar__list-element">decir diré, dirás atď.</li>
      <li className="grammar__list-element">hacer haré, harás atď.</li>
      <li className="grammar__list-element">haber habré, habrás atď.</li>
      <li className="grammar__list-element">saber sabré, sabrás atď.</li>
      <li className="grammar__list-element">poder podré, podrás atď.</li>
      <li className="grammar__list-element">poner pondré, pondrás atď.</li>
      <li className="grammar__list-element">salir saldré, saldrás atď.</li>
      <li className="grammar__list-element">tener tendré, tendrás atď.</li>
      <li className="grammar__list-element">venir vendré, vendrás atď.</li>
    </ul>
    <div className="grammar__inner-section">
      <p className="grammar__text">
        Čas futuro imperfecto v španielčine vyjadruje budúci dej vo vzťahu k
        okamihu, o ktorom sa hovorí, alebo predpoklad či pochybnosť o
        prítomnosti.
      </p>
    </div>
  </div>,
];

function GendersAndArticlesTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Rody a členy v španielčine</h2>
      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {gendersAndArticlesSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={
            currentSubtopic === gendersAndArticlesSubtopicArray.length - 1
          }
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function TimesTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Časovanie</h2>
      <p className="grammar__text">
        Na rozdiel od Slovenčiny je časovanie v španielčine veľmi náročné. V
        hovorovej reči sa využíva menej časov ako pozná gramatika, ale aj tak sa
        často vyskytujú. Španielčina rozlišuje slovesné spôsoby inak a to
      </p>
      <p className="grammar__text">Indicativo (Oznamovací)</p>
      <p className="grammar__text">Imperativo (Rozkazovací)</p>
      <p className="grammar__text">Subjuntivo (Prekladaný ako "Spojovací")</p>
      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {timesSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={currentSubtopic === timesSubtopicArray.length - 1}
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function DiminutiveWordsTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Slová zdrobnené a zveličené</h2>

      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {diminutiveWordsSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={currentSubtopic === diminutiveWordsSubtopicArray.length - 1}
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function PronunciationTopic() {
  return (
    <div>
      <h2 className="grammar__topic-name">Výslovnosť</h2>

      <div className="grammar__content-wrapper">
        <p className="grammar__text">
          Španielska výslovnosť nie je vôbec zložitá. V španielčine existujú
          rovnako ako v slovenčine samohlásky a, e,i,o,u. Ich výslovnosť je
          takmer taká istá ako v slovenčine. V španielčine neexistujú niektoré
          slovenské hlásky a nikdy sa nevyslovujú: h, v, c, z, ž, ť, ď, š.
        </p>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">B, V</h3>
          <p className="grammar__text">
            - ich výslovnosť sa nelíši a vyslovujú sa rovnako.
          </p>
          <p className="grammar__text">
            - na začiatku slova a po spoluhláskach m, n, sa vyslovujú ako
            slovenské [b]:
          </p>
          <p className="grammar__text">Napr. Burgos [ Burgos], vino [ bino]</p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">Ç</h3>
          <p className="grammar__text">
            - pred e, i, sa vyslovuje ako neznelá spoluhláska [θ] (hrot jazyka
            sa mierne vysunie pomedzi predné zuby, pričom medzi jazykom a zubami
            je úzka štrbina).
          </p>
          <p className="grammar__text">
            - v Latinskej Amerike a v niektorých oblastiach Španielska sa
            vyslovuje ako slovenské [s]:
          </p>
          <p className="grammar__text">
            Napr. Cervantes [θervantes], Barcelona [barθelona]
          </p>
          <p className="grammar__text">
            - pred a, o, u, alebo spoluhláskou sa vyslovuje ako naše slovenské
            [k]:
          </p>
          <p className="grammar__text">
            Napr. Carmen [karmen], Colombia [ Kolombia] , Cristina [Kristina]
          </p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">G</h3>
          <p className="grammar__text">
            - pred a, o, u a pred spoluhláskami sa vyslovuje približne ako
            slovenské [g]:
          </p>
          <p className="grammar__text">
            Napr. Goya [goja], Gabriel [Gabriel], galería [galeria]
          </p>
          <p className="grammar__text">- pred e, i, ako slovenské [ch]:</p>
          <p className="grammar__text">
            Napr. Girona [Chirona], gigante [chigante], género [chénero]
          </p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">GUI, GUE</h3>
          <p className="grammar__text">- sa vyslovuje ako [ge], [gi]:</p>
          <p className="grammar__text">
            Napr. Guinea [ginea], guitarra [gitarra], Miguel [migel]
          </p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">GÜE, GÜI, GUA,GUO</h3>
          <p className="grammar__text">
            - sa vyslovuje ako [gue], [gui], [gua], [guo]:
          </p>
          <p className="grammar__text">
            Napr. Camagüey [Kamaguei], bilingüe [bilingue], Guantanamera
            [Guantanamera]
          </p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">CH</h3>
          <p className="grammar__text">- sa vyslovuje ako slovenské [č]:</p>
          <p className="grammar__text">
            Napr. chimenea [čimenea], churros [čurros]
          </p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">D</h3>
          <p className="grammar__text">
            - na začiatku slova, po pauze a po l a n znie ako slovenské [d] a na
            konci slova takmer zaniká:
          </p>
          <p className="grammar__text">
            Napr. doy [doi], domingo [domingo], Madrid [Madri:d]
          </p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">H</h3>
          <p className="grammar__text">- sa nevyslovuje:</p>
          <p className="grammar__text">Napr. Hola [ola]</p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">LL</h3>
          <p className="grammar__text">
            - znie podobne ako slovenské slovenské [ j], ale jazyk sa priblíži k
            podnebiu tak, že sa výslovnosť blíži [ ľ]. V Argentíne a Uruguaji
            ako [ž]:
          </p>
          <p className="grammar__text">
            Napr. llamar [jamar], Murillo [murijo]
          </p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">J</h3>
          <p className="grammar__text">- sa vyslovuje ako [ch]:</p>
          <p className="grammar__text">Napr. jamás [chamás], junto [chunto]</p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">ñ</h3>
          <p className="grammar__text">- sa vyslovuje ako [ň]:</p>
          <p className="grammar__text">Napr. Nuñez [nuňeθ], España [espaňa]</p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">Q</h3>
          <p className="grammar__text">
            - sa vyskytuje len v spojeniach que, qui, [ke], [ki]:
          </p>
          <p className="grammar__text">
            Napr. química [kímika], don Quijote [don kichote]
          </p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">S</h3>
          <p className="grammar__text">
            - sa vyslovuje rovnako ostro ako v slovenčine:
          </p>
          <p className="grammar__text">
            Napr. salmón [salmón], salvar [salvar]
          </p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">X</h3>
          <p className="grammar__text">- sa vyslovuje ako [ks]:</p>
          <p className="grammar__text">Napr. texto [texto], examen [eksamen]</p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">R</h3>
          <p className="grammar__text">
            - na začiatku slova a po n, s, l sa vyslovuje ako viac kmitavé [rr].
            Rovnako sa vyslovuje aj písané dvojité rr:
          </p>
          <p className="grammar__text">
            Napr. Ramón [rramón], corrida [korrida]
          </p>
        </div>

        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">Z</h3>
          <p className="grammar__text">
            - pred samohláskami a, e, i, o, u vyslovuje ako [θ]:
          </p>
          <p className="grammar__text">
            Napr. zapatero [θapatero], zona [θona]
          </p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">Y</h3>
          <p className="grammar__text">- sa vyslovuje ako slovenské [j]:</p>
          <p className="grammar__text">Napr. Uruguay [Uruguaj], yate [jate] </p>
        </div>
      </div>
    </div>
  );
}

function NounsTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Podstatné mená</h2>

      <div className="grammar__content-wrapper">
        <p className="grammar__text">
          Sú plnovýznamové slová, ktoré pomenúvajú osoby (la mujer, el hombre),
          zvieratá (el perro, el caballo), rastliny (la rosa, el árbol), veci
          (la mesa, el libro), ich vlastnosti (la amabilidad, la bondad), deje
          (la corrida, la enseñanza), vnútorné stavy (la alegría, la pasión) a
          vzťahy (el amor).
        </p>
        <div className="grammar-inner-section">
          <p className="grammar__text">DELENIE:</p>
          <p className="grammar__text">
            - konkrétne (el gato, la casa, el cuaderno)
          </p>
          <p className="grammar__text">a) všeobecné (el armario, el cine)</p>
          <p className="grammar__text">b) vlastné (España, Moscú)</p>
          <p className="grammar__text">- abstraktné (el calor, la vista)</p>
        </div>
        <div className="grammar-inner-section">
          <p className="grammar__text">URČUJEME:</p>
          <p className="grammar__text">A) ROD</p>
          <p className="grammar__text">
            Na rozdiel od slovenčiny sú v španielčine dva rody: mužský
            (masculino) a ženský (femenino): el telegrama, el café, la silla, la
            muchacha. Stredný rod španielčina nemá.
          </p>
          <p className="grammar__text">
            - podstatné mená mužského rodu, ktoré sa končia na – o , majú v
            ženskom rode príponu – a (hermano- hermana, chico- chica)
          </p>
          <p className="grammar__text">
            - podstatné mená, ktoré sa končia na – e (el estudiante- la
            estudiante), - a (el guía – la guía) a na spoluhlásku (el joven- la
            joven) nemenia svoj tvar. Ich rod určujeme podľa člena.
          </p>

          <p className="grammar__text">
            - podstatné mená mužského rodu, ktoré sa končia na spoluhlásku, majú
            v ženskom rode príponu – a (león- leona).
          </p>
          <p className="grammar__text">
            Niektoré podstatné mená menia svoj význam podľa rodu (el cura-
            farár, la cura- liečba).
          </p>
        </div>
      </div>
    </div>
  );
}

function AdjectivesTopic() {
  return (
    <div>
      <h2 className="grammar__topic-name">Prídavné mená</h2>

      <div className="grammar__content-wrapper">
        <p className="grammar__text">
          Prídavné mená sa zhodujú s podstatnými menami v rode a čísle.
          Najčastejšie stoja za podstatným menom. Prídavné mená končiace na
          spoluhlásku, na ‘–ista’ alebo na ‘e’, nemjú rozdielne tvary v mužskom
          a ženskom rode. Mení sa len ich číslo.
        </p>
        <div className="grammar-inner-section">
          <p className="grammar__text">Mužský rod, jednotné číslo:</p>
          <p className="grammar__text">un coche bonito</p>
          <p className="grammar__text">un niño simpático</p>
          <p className="grammar__text">el hombre rico</p>
          <p className="grammar__text">el ministro nuevo</p>
          <p className="grammar__text">un libro interesante</p>
          <p className="grammar__text">el autobús azul</p>
          <p className="grammar__text">un político nacionalista</p>
        </div>
        <div className="grammar-inner-section">
          <p className="grammar__text">Ženský rod, jednotné číslo:</p>
          <p className="grammar__text">una casa bonita</p>
          <p className="grammar__text">una niña simpática</p>
          <p className="grammar__text">la mujer rica</p>
          <p className="grammar__text">la ministra nueva</p>
          <p className="grammar__text">una clase interesante</p>
          <p className="grammar__text">la casa azul</p>
          <p className="grammar__text">una ministra pacifista</p>
        </div>
        <div className="grammar-inner-section">
          <p className="grammar__text">
            Prídavné mená ‘bueno’, ‘malo’, ‘grande’ môžu stáť pred podstatným
            menom. V tom prípade sa v jednotnom čísle, v mužskom rode, mení
            ‘bueno’ a ‘malo’ na ‘buen’ a ‘mal’. ‘Grande’ sa mení na ‘gran’ v
            mužskom aj ženskom rode.
          </p>
        </div>
        <div className="grammar-inner-section">
          <p className="grammar__text">Príklady:</p>
        </div>
        <div className="grammar-inner-section">
          <p className="grammar__text">un día bueno – un buen día</p>
          <p className="grammar__text">tiempo malo – mal tiempo</p>
          <p className="grammar__text">un problema grande – un gran problema</p>
          <p className="grammar__text">una parte grande – una gran parte</p>
          <p className="grammar__text">
            Tvorenie množného čísla sa riadi rovnakými pravidlami ako u
            podstatných mien:
          </p>
          <p className="grammar__text">
            pridávame ‘s’,ak prídavné meno končí na samohlásku ‘o’, ‘a’, ‘e’
          </p>
          <p className="grammar__text">
            pridávame ‘es’, ak prídavné meno končí na spoluhlásku.
          </p>
        </div>
      </div>
    </div>
  );
}

function TensesCreationTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Tvorenie časov</h2>

      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {tensesCreationSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={currentSubtopic === tensesCreationSubtopicArray.length - 1}
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function SpanishGrammarSK(props) {
  switch (props.choosenTopic) {
    case "gendersAndArticlesTopic":
      return <GendersAndArticlesTopic />;
    case "timesTopic":
      return <TimesTopic />;
    case "diminutiveWordsTopic":
      return <DiminutiveWordsTopic />;
    case "pronunciationTopic":
      return <PronunciationTopic />;
    case "nounsTopic":
      return <NounsTopic />;
    case "adjectivesTopic":
      return <AdjectivesTopic />;
    case "tensesCreationTopic":
      return <TensesCreationTopic />;
    // case "verbCanNopic":
    //   return <VerbCanTopic />;
    default:
      return (
        <div className="grammar">
          <h1 className="grammar__title">Gramatika</h1>
          <div className="grammar__topics-wrapper">
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("gendersAndArticlesTopic")}
            >
              Rody a členy v španielčine
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("timesTopic")}
            >
              Časovanie
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("diminutiveWordsTopic")}
            >
              Slová zdrobnené a zveličené
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("pronunciationTopic")}
            >
              Výslovnosť
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("nounsTopic")}
            >
              Podstatné mená
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("adjectivesTopic")}
            >
              Prídavné mená
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("tensesCreationTopic")}
            >
              Tvorenie časov
            </button>
          </div>
        </div>
      );
  }
}

export default SpanishGrammarSK;
