import { useEffect, useState } from 'react';
import api from '../../api/api';
import './Sidebar.css';
import './AnswerPopup.css';
import Store from '../../../Store';

function AnswerPopup({ question, onClose }) {
    return (
        <div className="answer-popup-wrapper">
            <div className="answer-popup__surface" onClick={onClose}></div>
            <div className="answer-popup">
                <h2 className="answer-popup__header">
                    {Store.getText('askExpertInboxHeader')} {question.lesson.number}.
                    {question.lesson.subNumber} | {Store.getText('level')} {question.lesson.level}
                </h2>
                <p className="answer-popup__text">{question.question}</p>
                <img
                    src={question.screen.base64}
                    className="answer-popup__image"
                />
                <h2 className="answer-popup__header">{Store.getText('askExpertAnswer')}</h2>
                <p className="answer-popup__text">
                    {question.translatedAnswer}
                </p>
            </div>
        </div>
    );
}

export default () => {
    const [question, setQuestion] = useState();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
        api.get('/question/last').then(({ data }) => setQuestion(data));
    }, []);

    const handleSeeAnswer = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        api.post(`/question/${question.id}/seen`);
        setQuestion(prev => ({...prev, seenAt: new Date()}))
    };

    return (
        <>
            {isPopupOpen && question && (
                <AnswerPopup question={question} onClose={handleClosePopup} />
            )}

            <li
                className="sidebar__menu-list-element"
                onClick={handleSeeAnswer}
            >
                <span
                    className={`sidebar__menu-list-inbox ${
                        question && !question.seenAt ? 'sidebar__menu-list-inbox--dot' : ''
                    }`}
                >
                    {Store.getText('askExpertInbox')}
                </span>
            </li>
        </>
    );
};
