import "./ColouredSpeechBubble.css";

function ColouredSpeechBubble(props) {
  return (
    <div {...props} className={"coloured-speech-bubble " + props.className}>
      {props.children}
    </div>
  );
}

export default ColouredSpeechBubble;
