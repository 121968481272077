import { useEffect, useState } from 'react';
import api from '../../../platform/api/api';
import './PollStats.css';

const average = (arr) =>
    arr.reduce((p, c) => parseInt(p) + parseInt(c), 0) / arr.length;

function PollStats(props) {
    const [languagePairs, setLanguagePairs] = useState([]);
    const [languagePairId, setLanguagePairId] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        api.get(`/language-pair?version=2`).then((res) => {
            api.get(`/language-pair?version=1`).then((res2) => {
                const pairs = [...res.data.sort((a, b) => a.id - b.id), ...res2.data.sort((a, b) => a.id - b.id)];
                setLanguagePairs(pairs);
                setLanguagePairId(pairs[0].id);
            })

        });
    }, []);

    const find = () => {
        if (languagePairId) {
            api.get(`/poll/${languagePairId}`).then((res) => {
                computeData(res.data);
            });
        }
    };

    const computeData = (polls) => {
        if (!polls.length) return setData();
        polls.sort((a, b) =>
            b.timestamps.createdAt.localeCompare(a.timestamps.createdAt)
        );

        const computed = polls.reduce((accum, poll) => {
            Object.entries(poll.pollAnswers).forEach(([key, value]) => {
                if (!accum[key]) accum[key] = [];
                if (value) accum[key].push(value);
            });
            return accum;
        }, {});
        setData(computed);
    };

    return (
        <div className="poll-page">
            <div
                style={{
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '100%',
                    gridGap: '10px',
                    marginBottom: '20px',
                }}
            >
                <select
                    value={languagePairId}
                    onChange={(e) => setLanguagePairId(e.target.value)}
                >
                    {languagePairs.map((pair) => (
                        <option value={pair.id}>
                            {pair.languageFrom.language}-
                            {pair.languageTo.language}
                        </option>
                    ))}
                </select>

                <button onClick={find}>Szukaj</button>
            </div>

            {data && (
                <>
                    <p style={{ marginBottom: '10px', fontWeight: '700' }}>
                        Średnia z {data['1'].length} ankiet
                    </p>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        1. Jakości treści edukacyjnych:{' '}
                        <span
                            style={{
                                fontWeight: '700',
                                fontSize: '18px',
                                display: 'inline-block',
                                marginLeft: '5px',
                            }}
                        >
                            {average(data['1']).toFixed(2)}
                        </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        2. Jakości audio i zdjęć:{' '}
                        <span
                            style={{
                                fontWeight: '700',
                                fontSize: '18px',
                                display: 'inline-block',
                                marginLeft: '5px',
                            }}
                        >
                            {average(data['2']).toFixed(2)}
                        </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        3. Łatwości korzystania z aplikacji:{' '}
                        <span
                            style={{
                                fontWeight: '700',
                                fontSize: '18px',
                                display: 'inline-block',
                                marginLeft: '5px',
                            }}
                        >
                            {average(data['3']).toFixed(2)}
                        </span>
                    </div>

                    <p style={{ margin: '10px' }}>
                        Odpowiedzi na pytanie:{' '}
                        <b>
                            Jakie zmiany w naszej platformie chcesz zobaczyć w
                            przyszłości?
                        </b>
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            gap: '30px',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {data['4'].map((answer) => (
                            <div key={answer} style={{ width: '200px', textAlign: 'center', background: "#ddd", padding: "10px", borderRadius: "5px" }}>
                                {answer}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default PollStats;
