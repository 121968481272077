import axios from "axios";
import Store from "../../Store";

const LOADING_IGNORE_URLS = ['/stats'] 
// require("dotenv").config();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});



instance.interceptors.response.use(
  (response) => {
    if(!LOADING_IGNORE_URLS.includes(response.config.url))
      document.body.classList.remove('loading-indicator');
    return response
  },
  (error) => {
    document.body.classList.remove('loading-indicator');
    if (error.response.status === 401) {
      Store.removeToken();
      window.location = "/";
    }
    else if (error.response.status === 400) {
      throw new Error(error.response.data.message);
    }
    else if (error.response.status === 403) {
      Store.removeToken();
      window.location = "/";
    }
  }
);

instance.interceptors.request.use(function (config) {
  if(!LOADING_IGNORE_URLS.includes(config.url))
    document.body.classList.add('loading-indicator');
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default instance;
