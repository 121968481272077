import { useState } from 'react';
import './WordPairEditModal.css';
import api from '../../../platform/api/api';

const categories = [
    'Greetings and farewells',
    'Colors',
    'Food',
    'Directions and locations',
    'Numbers',
    'Body parts',
    'Clothing',
    'Family members',
    'Household accessories',
    'Furniture',
    'Household chores',
    'Work and occupations',
    'Vegetables and fruit',
    'Emotions and feelings',
    'Animals',
    'Shapes and figures',
    'Time',
    'Days of the week',
    'Transport',
    'Body parts',
    'In a restaurant',
    'School',
    'Slang',
    'E-mail',
    'Tools and machines',
    'Daily life',
    'Sports and recreation',
    'City',
    'Skills',
    'Education',
    'Religion and beliefs',
    'Business',
    'Vacation',
    'Events',
    'Culture and art',
    'Professions',
    'Phone conversation',
    'Politics and economy',
    'Technology and science',
    'Bureaucracy',
    'Nationalities',
    'The world of television',
    'Health and medicine',
    'The world of the Internet',
    'Plants',
    'Weather and astronomy',
    'Law and lawlessness',
    'Geography',
    'Formal and polite expressions',
    'Texting language',
    'Social media language',
    'Slang',
    'Idioms and sayings',
    'Adjectives',
    'Verbs',
    'Nouns',
    'Positive qualities',
    'Negative qualities',
    'Customer conversation',
    'Parents and child',
    'Sports',
    'Politics',
    'Science',
];

function WordPairEditModal({ wordPair, afterUpdate, closeModal, setError }) {
    const [data, setData] = useState({
        expressionTo: wordPair.wordTo.expression.text,
        expressionFrom: wordPair.wordFrom.expression.text,
        sentenceTo: wordPair.sentenceTo,
        sentenceFrom: wordPair.sentenceFrom,

        imageBase64: wordPair?.wordLink?.image?.base64,
        category: wordPair.addionalData?.category,
        forms: wordPair.forms || {},
        blocked: !!wordPair.blocked,
    });

    const onInputChange = (value, key) => {
        setData({
            ...data,
            [key]: value,
        });
    };

    const onFormChange = (value, key) => {
        const { forms } = data;
        forms[key] = value;
        setData({
            ...data,
            forms,
        });
    };

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const onFileChange = async (event) => {
        const file = event.target.files[0];
        const supportedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        if (supportedTypes.indexOf(file.type) === -1) {
            alert('Bad image type! Try with: jpg, jpeg or png');
        }

        const base64 = await toBase64(file);

        setData({
            ...data,
            imageBase64: base64,
        });
    };

    const onSave = async () => {
        const toSend = { ...data };
        const { imageBase64 } = toSend;
        if (imageBase64 === wordPair?.wordLink?.image?.base64) {
            delete toSend.imageBase64;
        }
        try {
            await api.post(`/word-pair/${wordPair.id}`, toSend);
        } catch(e) {
            setError(null, 'Something went wrong')
        }
        await afterUpdate();
    };

    const onRateReset = async () => {
        try {
            await api.delete(`/rate/pair/${wordPair.id}`);
        } catch(e) {
            setError(null, 'Something went wrong')
        }
        await afterUpdate();
    };

    return (
        <>
            <div
                className="word-pair-edit-modal-hero"
                onClick={closeModal}
            ></div>

            <div className="word-pair-edit-modal">
                <img className="word-pair-edit-img" src={data.imageBase64} />

                <input type="file" onChange={onFileChange} />
                <label className="word-pair-edit-label">
                    Expression native
                </label>
                <input
                    className="word-pair-edit-input"
                    value={data.expressionFrom}
                    onChange={(ev) =>
                        onInputChange(ev.target.value, 'expressionFrom')
                    }
                />
                <label className="word-pair-edit-label">Sentence native</label>

                <textarea
                    className="word-pair-edit-input"
                    value={data.sentenceFrom}
                    onChange={(ev) =>
                        onInputChange(ev.target.value, 'sentenceFrom')
                    }
                />
                <label className="word-pair-edit-label">
                    Expression foreign
                </label>

                <input
                    className="word-pair-edit-input"
                    value={data.expressionTo}
                    onChange={(ev) =>
                        onInputChange(ev.target.value, 'expressionTo')
                    }
                />
                <label className="word-pair-edit-label">Sentence foreign</label>

                <textarea
                    className="word-pair-edit-input"
                    value={data.sentenceTo}
                    onChange={(ev) =>
                        onInputChange(ev.target.value, 'sentenceTo')
                    }
                />
                <label className="word-pair-edit-label">
                    blocked
                </label>

                <input
                    className="word-pair-edit-input"
                    type="checkbox"
                    checked={data.blocked}
                    onChange={(ev) =>
                        onInputChange(ev.target.checked, 'blocked')
                    }
                />

                <label className="word-pair-edit-label">Category</label>

                <select
                    className="word-pair-edit-input"
                    value={data.category}
                    onChange={(ev) =>
                        onInputChange(ev.target.value, 'category')
                    }
                >
                    <option value={undefined}></option>

                    {categories.map((category) => (
                        <option value={category}>{category}</option>
                    ))}
                </select>

                {Object.entries(data.forms).map(([key, value]) => (
                    <div key={key}>
                        <label className="word-pair-edit-label">{key}</label>
                        <input
                            className="word-pair-edit-input"
                            value={value}
                            onChange={(ev) =>
                                onFormChange(ev.target.value, key)
                            }
                        />
                    </div>
                ))}
                <div> 
                <button onClick={onRateReset} className="word-pair-rate-button">
                    Resetuj polubienia
                </button>
                    
                </div>

                <button onClick={onSave} className="word-pair-edit-button">
                    Zapisz
                </button>
            </div>
        </>
    );
}

export default WordPairEditModal;
