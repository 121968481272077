import React from 'react';
const UserProfile = () => {
    return (
        <div className="mailbox-user-profile" style={{ minWidth: '30px' }} >
            <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{transform: 'scale(0.8)'}}
            >
                <rect
                    width="38"
                    height="38"
                    rx="19"
                    fill="var(--light-main-color)"
                />
                <path
                    d="M19.0138 17.5279C20.0796 17.5279 21.1215 17.2119 22.0077 16.6197C22.8939 16.0276 23.5846 15.186 23.9925 14.2013C24.4003 13.2166 24.5071 12.1331 24.2991 11.0878C24.0912 10.0424 23.578 9.08224 22.8243 8.3286C22.0707 7.57496 21.1105 7.06172 20.0651 6.85379C19.0198 6.64586 17.9363 6.75258 16.9516 7.16045C15.9669 7.56832 15.1253 8.25902 14.5332 9.1452C13.941 10.0314 13.625 11.0733 13.625 12.1391C13.625 13.5683 14.1928 14.939 15.2034 15.9496C16.214 16.9602 17.5846 17.5279 19.0138 17.5279Z"
                    fill="#F1EBF4"
                />
                <path
                    d="M27.0967 31C27.454 31 27.7967 30.858 28.0493 30.6054C28.302 30.3527 28.4439 30.01 28.4439 29.6527C28.4439 27.1516 27.4504 24.753 25.6818 22.9844C23.9133 21.2159 21.5146 20.2223 19.0135 20.2223C16.5124 20.2223 14.1137 21.2159 12.3451 22.9844C10.5766 24.753 9.58301 27.1516 9.58301 29.6527C9.58301 30.01 9.72495 30.3527 9.9776 30.6054C10.2302 30.858 10.5729 31 10.9302 31H27.0967Z"
                    fill="#F1EBF4"
                />
            </svg>
        </div>
    );
};

export default UserProfile;
