import { Link } from 'react-router-dom';
import {
    Sidebar,
    Menu,
    MenuItem,
    sidebarClasses,
    SubMenu,
    menuClasses,
} from 'react-pro-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChartArea,
    faEnvelope,
    faFont,
    faImage,
    faUser,
    faFileText
} from '@fortawesome/free-solid-svg-icons';

function AdminSidebar() {
    return (
        <Sidebar
            rootStyles={{
                [`.${sidebarClasses.container}`]: {
                    backgroundColor: '#4a4e4d',
                },
            }}
        >
            <Menu
                menuItemStyles={{
                    button: {
                        [`&`]: {
                            color: '#fff',
                            backgroundColor: '#4a4e4d',
                        },
                        '&:hover': {
                            backgroundColor: '#393939',
                        },
                    },
                    [`&:hover`]: {
                        backgroundColor: '#ff0000',
                    },
                    [`&.${menuClasses.active}`]: {
                        backgroundColor: '#ff0000',
                    },
                }}
            >
                <MenuItem
                    icon={<FontAwesomeIcon icon={faUser} />}
                    component={<Link to="/platform/admin" />}
                >
                    Użytkownicy
                </MenuItem>

                <MenuItem
                    icon={<FontAwesomeIcon icon={faImage} />}
                    component={<Link to="/platform/admin/lesson-images" />}
                >
                    Zdjęcia lekcji
                </MenuItem>
                <SubMenu
                    label="Wyrazy"
                    icon={<FontAwesomeIcon icon={faFont} />}
                >
                    <MenuItem
                        component={<Link to="/platform/admin/word-pair" />}
                    >
                        Pary wyrazów
                    </MenuItem>
                    <MenuItem
                        component={<Link to="/platform/admin/word-pair-v2" />}
                    >
                        Pary wyrazów(nowe)
                    </MenuItem>
                </SubMenu>
                <SubMenu
                    label="Statystyki"
                    icon={<FontAwesomeIcon icon={faChartArea} />}
                >
                    <MenuItem
                        component={<Link to="/platform/admin/activity-stats" />}
                    >
                        Aktywność
                    </MenuItem>
                    <MenuItem
                        component={<Link to="/platform/admin/tutorial-stats" />}
                    >
                        Samouczek
                    </MenuItem>
                    <MenuItem component={<Link to="/platform/admin/polls" />}>
                        Ankiety
                    </MenuItem>
                </SubMenu>
                <SubMenu
                    label="Maile"
                    icon={<FontAwesomeIcon icon={faEnvelope} />}
                >
                    <MenuItem
                        component={<Link to="/platform/admin/mail-settings" />}
                    >
                        Ustawienia maili
                    </MenuItem>
                  
                </SubMenu>
                <SubMenu
                    label="Lekcje"
                    icon={<FontAwesomeIcon icon={faFileText} />}
                >
                    <MenuItem
                        component={<Link to="/platform/admin/task-lessons" />}
                    >
                        Lekcje zadań
                    </MenuItem>

                </SubMenu>
            </Menu>
        </Sidebar>
    );
}

export default AdminSidebar;
