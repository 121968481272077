import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import blueCloud from "../../../images/boxes/dymek_zielen.png";
import { Cloud } from "../hero/Hero";
import LearningElement from "./LearningElement";

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-evenly;
`;

const CloudBox = styled.div`
  width: 220px;
  overflow: hidden;
  height: 100px;
  margin: 30px;
`;

const Tittle = styled.h1`
  font-weight: lighter;
  font-size: 30px;
  max-width: 200px;
  margin: 0px;
  padding: 10px 0 10px 30px;
  text-align: center;
  color: white;
`;

const Learning = ({ data }) => {
  return (
    <div
      style={{ marginBottom: "100px", scrollMarginTop: "60px" }}
      id="learning"
      name="learning"
    >
      <CloudBox>
        <div style={{ width: "200px" }}>
          <Cloud img={blueCloud}>
            <Tittle>{data.title}</Tittle>
          </Cloud>
        </div>
      </CloudBox>
      <Box>
        {data.cards.map(({ icon, text, headline, text2, newOne }) => {
          return (
            <LearningElement
              id={text + "" + icon + "" + headline}
              image={icon}
              text={text}
              headline={headline}
              text2={text2}
              newOne={newOne}
            />
          );
        })}
      </Box>
    </div>
  );
};

export default Learning;
