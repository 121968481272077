import styled from "styled-components";

export const ProductDiv = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Roboto", sans-serif;
  }

  h1,
  h2 {
    font-family: "Teko", sans-serif;
  }

  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
  }

  h3 {
    font-size: 1.17em;
  }

  h2 {
    font-size: 1.5em;
  }
  .btn {
    box-shadow: none !important;
  }
  .btn:focus {
    outline: none;
    box-shadow: none;
  }

  .dropdown-toggle::after {
    display: none !important;
  }
  .navAnimation:hover {
    color: #61b22f;
    transform: scale(1.1);
  }

  h1 span span {
    position: relative;
    opacity: 0;
    animation: move-text 0.75s forwards;
  }

  @keyframes move-text {
    0% {
      bottom: -0.2em;
      opacity: 1;
    }
    50% {
      bottom: 0.2em;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }

  h2 span span {
    position: relative;
    opacity: 0;
    animation: move-text 0.75s forwards;
    animation-delay: 10s;
  }
`;
