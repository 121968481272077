import CheckedIcon from '../../CheckedIcon';
import MainModal from '../../../../Modals/MainModal/MainModal';
import './SendModal.css';
import Store from '../../../../../../Store';

function SendModal({ isModalOpen, onModalClose }) {
    return (
        <MainModal isModalOpen={isModalOpen} setIsModalOpen={onModalClose}>
            <div className='send-task-modal__wrapper'>
                <h2>{Store.getText('taskActivityMessageSent')}</h2>
                <CheckedIcon />
                <p>{Store.getText('taskActivityMessageSentDescription')}</p>
            </div>
        </MainModal>
    );
}

export default SendModal;
