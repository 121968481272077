import { useEffect, useRef, useState } from "react";
import Store from "../../../../Store";
import getLevelColor from "../../../common/functions/getLevelColor";
import ActivityFeedback from "../../ActivityFeedback/ActivityFeedback";
import ActivityButton from "../../Buttons/ActivityButton/ActivityButton";
import "./UnscrambleExpressionLettersActivity.css";

function UnscrambleExpressionLettersActivity({ activityData, makeAnswer, setIsValidAnswer }) {
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [allLettes, setAllLettes] = useState([]);
  const lettersRef = useRef();
  const selectedLettersRef = useRef();

  const [correctAnswer, setCorrectAnswer] = useState("");

  const [answerResult, setAnswerResult] = useState();

  const isLetter = (c) => c.toLowerCase() != c.toUpperCase();

  const computeInitLetters = (letters) => {
    setSelectedLetters(letters.map((letter) => {
      if (!isLetter(letter.letter) || (letters.length >= 10 && Math.random() < 0.15)) return letter;
      return { isBlank: true }
    }))
  }

  useEffect(() => {
    const letterArr = activityData.words.word.wordTo.expression.text
      .toUpperCase()
      .split("")
      .map((letter, i) => {
        return {
          letter: letter,
          id: i + 1,
        };
      });
    setCorrectAnswer(activityData.words.word.wordTo.expression.text);
    if (activityData.words.word.wordTo.expression.text.length > 25) makeAnswer(true)
    computeInitLetters(letterArr);
    setAllLettes(letterArr.sort((a, b) => 0.5 - Math.random()));
  }, []);

  const selectLetter = (letter) => {
    setSelectedLetters((prevState) => {
      const index = prevState.findIndex(letter => letter.isBlank === true || !letter.letter)
      const newArr = [...prevState]
      newArr[index] = letter
      return newArr;
    });
  };

  useEffect(() => {
    if (lettersRef.current && selectedLettersRef.current) {
      selectedLettersRef.current.style.minHeight = `${lettersRef.current.clientHeight}px`;
    }
  }, [lettersRef.current, selectedLettersRef.current]);

  const unSelectLetter = (letter) => {
    setSelectedLetters((prevState) => {
      const copySelected = [...prevState];
      const index = copySelected.findIndex(
        (sellLetter) => sellLetter.id === letter.id
      );
      copySelected[index] = { isBlank: true };
      return copySelected;
    });
  };

  const checkAnswer = () => {
    if (answerResult) return;

    const expression = selectedLetters.map((letter) => letter.letter).join("");

    let isCorrect = expression?.toUpperCase() === correctAnswer?.toUpperCase();
    setIsValidAnswer(isCorrect)
    setAnswerResult({ isCorrect });

  };
  return (
    <div className="unscramble-expression-letters-activity">
      <p className="unscramble-expression-letters-activity__expression">
        {activityData.words.word.wordFrom.expression.text}
      </p>
      <div className="unscramble-expression-letters-activity__activity-content">
        <div className="unscramble-expression-letters-activity__answer-wrapper">
          <div
            className="unscramble-expression-letters-activity__answer-letters-wrapper"
            ref={selectedLettersRef}
          >
            {selectedLetters.map((letter) => (
              <p
                className="unscramble-expression-letters-activity__answer-letter"
                key={letter.id}
                style={{
                  backgroundColor: letter.isBlank ? 'unset' : getLevelColor(activityData.level),
                  borderColor: getLevelColor(activityData.level),
                }}
                onClick={() => unSelectLetter(letter)}
              >
                {letter.letter}
              </p>
            ))}
          </div>
          <div className="unscramble-expression-letters-activity__answer-underline"></div>
        </div>
        <div
          className="unscramble-expression-letters-activity__letters-wrapper"
          ref={lettersRef}
        >
          {allLettes.map((letter) => (
            <p
              className={`unscramble-expression-letters-activity__letter 
            ${selectedLetters.find(
                (selectedLetter) => selectedLetter.id === letter.id
              )
                  ? "unscramble-expression-letters-activity__letter--selected"
                  : ""
                }
            `}
              style={{ borderColor: getLevelColor(activityData.level) }}
              key={letter.id}
              onClick={() => selectLetter(letter)}
            >
              {letter.letter}
            </p>
          ))}
        </div>
      </div>
      {!selectedLetters.filter(({ isBlank }) => isBlank).length && (
        <ActivityButton onClick={checkAnswer}>
          {Store.getText("continueTasks")}
        </ActivityButton>
      )}
      {answerResult && (
        <ActivityFeedback isCorrect={answerResult.isCorrect} makeAnswer={makeAnswer} properAnswer={correctAnswer}></ActivityFeedback>
      )}
    </div>
  );
}

export default UnscrambleExpressionLettersActivity;
