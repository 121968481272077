import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";

import transparentCloud from "../../../images/boxes/dymek_obrys_prawy_gorny.png";

import cloudRightDown from "../../../images/boxes/dymek_bialy_prawo_dol.png";
import cloudRightUp from "../../../images/boxes/dymek_bialy_prawo_gora.png";
import cloudLeftUp from "../../../images/boxes/dymek_bialy_lewo_gora.png";
import cloudLeftDown from "../../../images/boxes/dymek_bialy_lewo_dol.png";

import photo from "../../../images/zdj_2.jpg";
import logo from "../../../images/logo_smartado_wh.png";
import {
  BackgroundProductPage,
  Cloud,
  ColorChangerProductPage,
} from "../../reusable/exportedStyles/exportedStyles";

const Logo = styled.div`
  width: 500px;
  height: 150px;
  overflow: hidden;
  padding: 20px;
  background-color: ${Colors.darkBlue};

  @media (max-width: 600px) {
    display: none;
  }
`;

const Decription = styled.div`
  background-color: white;
  padding: 15px;
  margin-top: 10px;
  border-radius: 10px;
  position: absolute;
  max-width: 350px;
  margin-left: -60px;
  display: none;
`;

const CloudTitleWrapper = styled.div`
  max-width: 220px;
  float: right;
  margin: 30px;
`;

const H1 = styled.h1`
  font-weight: lighter;
  font-size: 30px;
  max-width: 140px;
  margin: 0px;
  padding: 10px 0 10px 10px;
  text-align: center;
  color: ${Colors.yellow};
`;

const H2Descirption = styled.h1`
  font-weight: lighter;
  font-size: 38px;
  max-width: 250px;
  margin: 0px;
  padding: 10px 30px 10px 30px;
  text-align: center;
  color: ${Colors.darkDarkBlue};
`;

const H3Descirption = styled.h1`
  font-weight: lighter;
  font-size: 38px;
  max-width: 250px;
  margin: 0px;
  padding: 0px 10px 0px 10px;
  text-align: center;
  color: ${Colors.darkDarkBlue};
`;

const H4Descirption = styled.h1`
  font-weight: lighter;
  font-size: 38px;
  max-width: 350px;
  margin: 0px;
  padding: 10px 10px 10px 10px;
  text-align: center;
  color: ${Colors.darkDarkBlue};
`;

const H5Descirption = styled.h1`
  font-weight: lighter;
  font-size: 38px;
  max-width: 300px;
  margin: 0px;
  padding: 0px 0px 0px 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  color: ${Colors.darkDarkBlue};
`;

const Flex = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  gap: 700px;

  @media (max-width: 600px) {
    max-width: 100vw;
    gap: 10px;
    flex-wrap: wrap;
  }
`;

const ThirdCloudWrapper = styled.div`
  max-width: 220px;
  margin-left: 15vw;
  margin-top: -3vh;

  @media (max-width: 600px) {
    margin: 0;
  }
`;

const FifthCloudWrapper = styled.div`
  max-width: 240px;
  margin-top: -40px;
  margin-left: 20vw;
  @media (max-width: 600px) {
    margin: 0;
  }
`;

const ThirdCloudBox = styled.div`
  display: flex;
  margin-top: 20px;

  @media (max-width: 600px) {
    justify-content: center;
    align-items: center;
  }
`;

const Cloud1Box = styled.div`
  max-width: 250px;
  @media (max-width: 600px) {
    margin-left: 80px;
  }
`;

const Cloud2Box = styled.div`
  max-width: 220px;
  @media (max-width: 600px) {
    margin-left: -80px;
  }
`;

const Cloud3Box = styled.div`
  @media (max-width: 600px) {
    margin-left: 80px;
  }
`;

const Cloud4Box = styled.div`
  @media (max-width: 600px) {
    margin-left: 80px;
  }
`;

const Cloud5Box = styled.div`
  max-width: 220px;
`;

const Flex3 = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 100px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    justify-content: center;
    display: grid;
  }
`;

const About = ({ data }) => {
  return (
    <>
      <BackgroundProductPage
        img={photo}
        style={{ scrollMarginTop: "28px" }}
        id="what"
        name="what"
      >
        <ColorChangerProductPage>
          {/* Tittleeeee */}
          <div style={{ maxWidth: "100vw" }}>
            <CloudTitleWrapper>
              <Cloud img={transparentCloud}>
                <H1>{data.title}</H1>
              </Cloud>
            </CloudTitleWrapper>
          </div>

          {/* dwa pierwsze dymki */}
          <Flex>
            <Cloud1Box>
              <Cloud img={cloudRightDown} className="showDescriptionFirst">
                <H4Descirption>{data.box1}</H4Descirption>
                <Decription className="descriptionToshowFirst">
                  <p>{data.boxDescription1}</p>
                </Decription>
              </Cloud>
            </Cloud1Box>
            <Cloud2Box>
              <Cloud
                img={cloudLeftDown}
                style={{ marginTop: "20px", paddingRight: "0px" }}
                className="showDescriptionSecond"
              >
                <H2Descirption>{data.box2}</H2Descirption>
                <Decription className="descriptionToshowSecond">
                  <p>{data.boxDescription2}</p>
                </Decription>
              </Cloud>
            </Cloud2Box>
          </Flex>

          {/* jeden dymek i logo */}
          <div style={{ maxWidth: "100vw" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Logo>
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "100%", height: "auto" }}
                ></img>
              </Logo>
            </div>
            <Cloud3Box>
              <ThirdCloudBox>
                <ThirdCloudWrapper>
                  <Cloud img={cloudRightDown} className="showDescriptionThird">
                    <H3Descirption>{data.box3}</H3Descirption>
                    <Decription className="descriptionToshowThird">
                      <p>{data.boxDescription3}</p>
                    </Decription>
                  </Cloud>
                </ThirdCloudWrapper>
              </ThirdCloudBox>
            </Cloud3Box>
          </div>

          {/* dwa ostatnie dymki  */}
          <Flex3>
            <Cloud4Box>
              <FifthCloudWrapper>
                <Cloud
                  img={cloudRightUp}
                  // style={{ marginTop: "70px" }}
                  className="showDescriptionFourth"
                >
                  <H4Descirption>{data.box4}</H4Descirption>
                  <Decription className="descriptionToshowFourth">
                    <p>{data.boxDescription4}</p>
                  </Decription>
                </Cloud>
              </FifthCloudWrapper>
            </Cloud4Box>
            <Cloud5Box>
              <Cloud
                className="showDescriptionFifth"
                img={cloudLeftUp}
                style={{ marginTop: "20px" }}
              >
                <H5Descirption>{data.box5}</H5Descirption>
                <Decription className="descriptionToshowFifth">
                  <p>{data.boxDescription5}</p>
                </Decription>
              </Cloud>
            </Cloud5Box>
          </Flex3>
        </ColorChangerProductPage>
      </BackgroundProductPage>
    </>
  );
};

export default About;
