import "./MainButton.css";

function MainButton(props) {
  return (
    <button type="button" {...props} className={"main-button " + props.className}>
      {props.children}
    </button>
  );
}

export default MainButton;
