import { useEffect, useState } from "react";
import ActivityFeedback from "../../ActivityFeedback/ActivityFeedback";
import "./ExpressionTranslationActivity.css";

function ExpressionTranslationActivity({activityData, makeAnswer, setIsValidAnswer}) {
  const [properAnswer, setProperAnswer] = useState({})
  const [possibleAnswers, setPossibleAnswers] = useState([])

  const [answerResult, setAnswerResult] = useState()

  useEffect(() => {
    const correct = {
      id: activityData.words.word.id,
      word: activityData.words.word.wordFrom.expression.text
    }
    setProperAnswer(correct)

    const answers = [correct, ...activityData.words.words.map(word => {
      return {
        id: word.id,
        word: word.wordFrom.expression.text
      }
    })]
    setPossibleAnswers(answers.sort((a, b) => 0.5 - Math.random()))

  }, [])

  const checkAnswer = (answer) => {
    if(answerResult) return;

    const isCorrect = answer.id === properAnswer.id
    setIsValidAnswer(isCorrect)
    setAnswerResult({isCorrect})
  }
  return (
    <div className="expression-translation-activity">
      <div className="expression-translation-activity__expression-wrapper">
        <div className="expression-translation-activity__image-wrapper" style={{
          backgroundImage: `url(${activityData?.words?.word?.wordLink?.image?.base64})`
        }}></div>
        <p className="expression-translation-activity__expression">{activityData.words.word.wordTo.expression.text}</p>
      </div>
      <div className="expression-translation-activity__translations-wrapper">
        {possibleAnswers.map(answer =>  <div className="expression-translation-activity__translation" onClick={() => checkAnswer(answer)} key={answer.id}>
          <p className="expression-translation-activity__translation-text" >
            {answer.word}
          </p>
        </div>)}
       
      
      </div>
      {answerResult && <ActivityFeedback isCorrect={answerResult.isCorrect} makeAnswer={makeAnswer} properAnswer={properAnswer.word}></ActivityFeedback>}

    </div>
  );
}

export default ExpressionTranslationActivity;
