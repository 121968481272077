import { useNavigate } from 'react-router-dom';
import Store from '../../../Store';
import api from '../../api/api';
import './ChangePasswordPage.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faXmark } from '@fortawesome/free-solid-svg-icons';
import MainButton from '../../components/Buttons/MainButton/MainButton';

function ChangePasswordPage(props) {
  const navigate = useNavigate();
  const [inputPassword, setInputPassword] = useState('');
  const [inputPasswordShow, setInputPasswordShow] = useState(false);
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState('');
  const [inputPasswordRepeatShow, setInputPasswordRepeatShow] =
    useState(false);

  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const email = params.get('email');

  const login = async () => {
    const res = await api.post("/auth/login", {
      email,
      password: inputPassword,
    });

    const token = res.data.token;
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    Store.setToken(token);

    navigate("/platform");
  };

  const handleChangePassword = async (ev) => {
    ev.preventDefault();
    try {
        if (isPasswordMatch) {
            await api.post('/auth/change-password', {
                password: inputPassword,
                token,
            }).then(login);
        }
    } catch (err) {
        props.setError(err.message);
    }
  };

  const handlePasswordBlur = () => {
    setIsPasswordMatch(
      inputPasswordRepeat !== '' && inputPassword !== ''
        ? inputPassword === inputPasswordRepeat
        : true
    );
  };

  return (
    <div className="change-password-page">
      <form
        className="change-password-page__form"
        onSubmit={handleChangePassword}
      >
        <div className="change-password-page__buttons-wrapper">
          <Link to="/">
            <button
              type="button"
              className="change-password-page__closing-button"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </Link>
          <button
            type="button"
            className="change-password-page__info-button"
          >
            ?
            <div className="change-password-page__info-text-wrapper">
              <p className="change-password-page__info-text">
                {Store.getText('contactText')}
                <span className="change-password-page__span-block">
                  {Store.getText('contactEmail')}
                </span>
              </p>
            </div>
          </button>
        </div>
        <h2 className="change-password-page__form-title">
          {Store.getText('changePasswordHeader')}
        </h2>
        <p className="change-password-page__form-slogan change-password-page__form-slogan--register">
          {Store.getText('changePasswordDescription')}
        </p>
        <div className="change-password-page__main-form-content">
          <div className="change-password-page__input-wrapper">
            <label className="change-password-page__input-label">
              {Store.getText('changePasswordPasswordLabel')}
            </label>
            <div className="change-password-page__password-wrapper">
              <input
                className="change-password-page__input"
                type={inputPasswordShow ? 'text' : 'password'}
                onChange={(ev) =>
                  setInputPassword(ev.target.value)
                }
                value={inputPassword}
                onBlur={handlePasswordBlur}
              ></input>
              <button
                className="change-password-page__password-icon-button"
                type="button"
                title={
                  inputPasswordShow
                    ? Store.getText('hidePassword')
                    : Store.getText('showPassword')
                }
                onClick={() =>
                  setInputPasswordShow(!inputPasswordShow)
                }
              >
                {inputPasswordShow ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </button>
            </div>
          </div>
          <div></div>
          <div className="change-password-page__input-wrapper">
            <label className="change-password-page__input-label">
              {Store.getText('changePasswordRepeatPasswordLabel')}
            </label>
            <div className="change-password-page__password-wrapper">
              <input
                className="change-password-page__input"
                type={
                  inputPasswordRepeatShow
                    ? 'text'
                    : 'password'
                }
                onChange={(ev) =>
                  setInputPasswordRepeat(ev.target.value)
                }
                onBlur={handlePasswordBlur}
                value={inputPasswordRepeat}
              ></input>
              <button
                className="change-password-page__password-icon-button"
                type="button"
                title={
                  inputPasswordRepeatShow
                    ? Store.getText('hidePassword')
                    : Store.getText('showPassword')
                }
                onClick={() =>
                  setInputPasswordRepeatShow(
                    !inputPasswordRepeatShow
                  )
                }
              >
                {inputPasswordRepeatShow ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </button>
            </div>
            {isPasswordMatch ? (
              ''
            ) : (
              <p className="change-password-page__alert">
                {Store.getText('passwordsNotMatchAlert')}
              </p>
            )}
          </div>
        </div>
        <MainButton className="orange-button up" type="submit">
          {Store.getText('changePasswordButton')}
        </MainButton>
      </form>
    </div>
  );
}

export default ChangePasswordPage;
