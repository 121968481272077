import Store from "../../../Store";

function getActivityName(type, gameType) {
  const GAME_ACTIVITIES_NAMES = {
    ExpressionListeningGame: Store.getText("activityExpressionListening"),
    ExpressionTranslationGame: Store.getText("activityExpressionTranslation"),
    LinkExpressionsGame: Store.getText("activityLinkExpressions"),
    UnscrambleExperssionLettersGame: Store.getText(
      "activityUnscrambleExpressionLetters"
    ),
    UnscrambleSentenceWordsGame: Store.getText(
      "activityUnscrambleExpressionLetters"
    ),
  };

  const WORD_ACTIVITY_NAME = Store.getText("activityExpressionLearning");
  if (type === "word") return WORD_ACTIVITY_NAME;
  return GAME_ACTIVITIES_NAMES[gameType];
}

export default getActivityName;
