import { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import StatsContext from '../context/statsContext';

const withParamColector = (Component) => (props) => {
    const { setLanguagePairId, setLessonId, setLevel } = useContext(StatsContext);
    const { pairId, lessonId, level } = useParams();

    useEffect(() => {
        if(pairId) setLanguagePairId(pairId); 
    }, [pairId]);

    useEffect(() => {
        if(lessonId) setLessonId(lessonId); 
    }, [lessonId]);

    useEffect(() => {
        if(level) setLevel(level); 
    }, [level]);

    return <Component {...props} />;
};

export default withParamColector;
