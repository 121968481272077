import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import Store from "../../../../Store";
import getLanguageFlag from "../../../common/functions/getLanguageFlag";
import SecondaryButton from "../../Buttons/SecondaryButton/SecondaryButton";
import MainModal from "../MainModal/MainModal";
import api from "../../../api/api";
import "./CoursesModal.css";
import StatsContext from "../../../stats/context/statsContext";

function CoursesModal(props) {
  const [accessKey, setAccessKey] = useState("");

  const { setLanguagePairId } = useContext(StatsContext);

  const navigate = useNavigate();

  async function addKey(ev) {
    try {
      ev.preventDefault();
      const res = await api.post(`/user/current/add-key`, { accessKey });
      props.setIsModalOpen(false);
      navigate("/platform");
    } catch (err) {
      props.setError(err.message);
    }
  }

  function selectLanguage(id, level) {
    const language = props.languagesAvailable.find((lang) => lang.id === id);
    props.setCurrentLanguageShort(language.languageTo.languageShort);
    props.setCurrentLanguagePair(language);
    setLanguagePairId(language.id);
    props.setCurrentLevel(level);
    props.setIsModalOpen(false);
  }

  return (
    <MainModal
      isModalOpen={props.isModalOpen}
      setIsModalOpen={props.setIsModalOpen}
      className="main-page__courses-modal"
    >
      <div className="main-page__courses-modal-content">
        <h2 className="main-page__courses-modal-header">
          {Store.getText("languageChangeYourCourses")}
        </h2>
        <ul className="main-page__courses-modal-your-courses-list">
          {props.languagesAvailable.map((language) => (
            <li
              key={language.id}
              className="main-page__courses-modal-your-courses-list-element"
              onClick={() => selectLanguage(language.id, 1)}
            >
              <img
                src={getLanguageFlag(language.languageTo.languageShort)}
                className="main-page__courses-modal-your-course-flag"
              />
              {Store.getLanguageNameByShort(language.languageTo.languageShort)}
            </li>
          ))}
        </ul>
        <h2 className="main-page__courses-modal-header">
          {Store.getText("languageChangeAvailable")}
        </h2>
        <ul className="main-page__courses-modal-other-courses-list">
          {props.notAvailableLanguages.map((language) => (
            <li
              key={language.id}
              className="main-page__courses-modal-other-courses-list-element"
            >
              <img
                src={getLanguageFlag(language.languageTo.languageShort)}
                className="main-page__courses-modal-other-course-flag"
              />
              {Store.getLanguageNameByShort(language.languageTo.languageShort)}
            </li>
          ))}
        </ul>
        <form className="main-page__courses-modal-form" onSubmit={addKey}>
          <label className="main-page__courses-modal-form-input-label">
            {Store.getText("languageActivationInputLabel")}
          </label>
          <input
            type="text"
            value={accessKey}
            onChange={(e) => setAccessKey(e.target.value)}
            className="main-page__courses-modal-form-input"
          ></input>
          <SecondaryButton
            type="submit"
            className="main-page__courses-modal-form-button"
          >
            {Store.getText("languageActivationButtonText")}
          </SecondaryButton>
        </form>
        <p className="main-page__courses-modal-info">
          <b>{Store.getText("languageActivationInfoFirst")}</b>{" "}
          {Store.getText("languageActivationInfoSecond")}
        </p>
      </div>
    </MainModal>
  );
}

export default CoursesModal;
