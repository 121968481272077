import Store from '../../../Store';
import './LoginPage.css';
import api from '../../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import SecondaryButton from '../../components/Buttons/SecondaryButton/SecondaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faXmark } from '@fortawesome/free-solid-svg-icons';

function LoginPage(props) {
    const navigate = useNavigate();
    const [inputEmail, setInputEmail] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [inputPasswordShow, setInputPasswordShow] = useState(false);
    const { lang } = useParams();

    const login = async (email, password) => {
        const res = await api.post('/auth/login', {
            email: email,
            password: password,
        });

        const token = res.data.token;
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        Store.setToken(token);

        navigate('/platform');
    };

    const handleLoginFormSubmit = async (ev) => {
        ev.preventDefault();
        try {
            await login(inputEmail, inputPassword);
        } catch (err) {
            props.setError(err.message);
        }
    };
    return (
        <div className="login-page">
            <form
                className="login-page__form"
                onSubmit={handleLoginFormSubmit}
                autoComplete="on"
            >
                <div className="login-page__buttons-wrapper">
                    <Link to="/">
                        <button
                            type="button"
                            className="login-page__closing-button"
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </Link>
                    <button type="button" className="login-page__info-button">
                        ?
                        <div className="login-page__info-text-wrapper">
                            <p className="login-page__info-text">
                                {Store.getText('contactText')}
                                <span className="login-page__span-block">
                                    {Store.getText('contactEmail')}
                                </span>
                            </p>
                        </div>
                    </button>
                </div>
                <h2 className="login-page__form-title">
                    {Store.getText('loginButtonText')}
                </h2>
                <p className="login-page__form-slogan login-page__form-slogan--login">
                    {Store.getText('loginSlogan')}
                </p>
                <div className="login-page__input-wrapper">
                    <label className="login-page__input-label">
                        {Store.getText('emailLabel')}
                    </label>
                    <input
                        className="login-page__input"
                        type="email"
                        onChange={(ev) => setInputEmail(ev.target.value)}
                        value={inputEmail}
                    ></input>
                </div>
                <div className="login-page__input-wrapper">
                    <label className="login-page__input-label">
                        {Store.getText('passwordLabel')}
                    </label>
                    <div className="login-page__password-wrapper">
                        <input
                            className="login-page__input"
                            type={inputPasswordShow ? 'text' : 'password'}
                            onChange={(ev) => setInputPassword(ev.target.value)}
                            value={inputPassword}
                        ></input>
                        <button
                            className="login-page__password-icon-button"
                            type="button"
                            title={
                                inputPasswordShow
                                    ? Store.getText('hidePassword')
                                    : Store.getText('showPassword')
                            }
                            onClick={() =>
                                setInputPasswordShow(!inputPasswordShow)
                            }
                        >
                            {inputPasswordShow ? (
                                <FontAwesomeIcon icon={faEyeSlash} />
                            ) : (
                                <FontAwesomeIcon icon={faEye} />
                            )}
                        </button>
                    </div>
                    <div className="login-page__recover-password-text">
                        {Store.getText('passwordRecoveryLoginText')}{' '}
                        <Link
                            className="login-page__recover-password-text--link"
                            to={`/${lang}/password-recovery`}
                        >
                            {Store.getText('passwordRecoveryLoginLink')}
                        </Link>
                        .
                    </div>
                </div>
                <SecondaryButton type="submit">
                    {Store.getText('loginButtonText')}
                </SecondaryButton>
            </form>
        </div>
    );
}

export default LoginPage;
