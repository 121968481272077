import { useCallback, useEffect, useState } from 'react';
import './TestActivityPage.css';
import { useNavigate, useParams } from 'react-router';
import ExpressionTranslationActivity from '../../../components/activities/ExpressionTranslationActivity/ExpressionTranslationActivity';
import ExpressionListeningActivity from '../../../components/activities/ExpressionListeningActivity/ExpressionListeningActivity';
import UnscrambleExpressionLettersActivity from '../../../components/activities/UnscrambleExpressionLettersActivity/UnscrambleExpressionLettersActivity';
import UnscrambleSentenceWordsActivity from '../../../components/activities/UnscrambleSentenceWordsActivity/UnscrambleSentenceWordsActivity';
import LinkExpressionsActivity from '../../../components/activities/LinkExpressionsActivity/LinkExpressionsActivity';
import getLevelColor from '../../../common/functions/getLevelColor';
import Store from '../../../../Store';
import getActivityName from '../../../common/functions/getActivityName';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const queryString = require('query-string');

const GAME_ACTIVITIES = {
    ExpressionListeningGame: ExpressionListeningActivity,
    ExpressionTranslationGame: ExpressionTranslationActivity,
    LinkExpressionsGame: LinkExpressionsActivity,
    UnscrambleExperssionLettersGame: UnscrambleExpressionLettersActivity,
    UnscrambleSentenceWordsGame: UnscrambleSentenceWordsActivity,
};

function TestActivityPage({ sequence, onTestEnd }) {
    const [activityData, setActivityData] = useState({});

    const [progressComponents, setProgressComponents] = useState([]);
    const [activityComponent, setActivityComponent] = useState();

    const navigate = useNavigate();

    const params = useParams();

    const loadCurrentActiivityData = async (sequenceNumber, progress) => {
        const dataItem = sequence[sequenceNumber];

        setActivityData(dataItem);
        loadPorgressComponents(dataItem, sequenceNumber, progress);
        loadActivityComponent(dataItem, sequenceNumber, progress);
    };

    const loadPorgressComponents = (lessonData, sequenceNumber, progress) => {
        const components = [];
        for (let i = 0; i <= lessonData.allNumber; i++) {
            let colorClassName = '';
            if (typeof progress[i] === 'boolean') {
                colorClassName = progress[i]
                    ? 'activity-page__square--completed'
                    : 'activity-page__square--failed';
            } else {
                colorClassName =
                    sequenceNumber === i
                        ? 'activity-page__square--in-progress'
                        : '';
            }

            components.push(
                <div
                    key={i}
                    className={`activity-page__square 
          ${
              sequenceNumber.currentNumber === i
                  ? 'activity-page__square--active'
                  : ''
          }
          ${colorClassName}        
        `}
                ></div>
            );
        }
        setProgressComponents(components);
    };

    const loadActivityComponent = (lessonData, sequenceNumber, progress) => {
        const GameComponent = GAME_ACTIVITIES[lessonData.gameName].bind({});
        setActivityComponent(
            <GameComponent
                activityData={lessonData}
                makeAnswer={createMakeAnswer(
                    lessonData,
                    sequenceNumber,
                    progress
                )}
                setIsValidAnswer={() => {}}
            />
        );
    };

    const createMakeAnswer =
        (lessonData, sequenceNumber, progress) => async (answer) => {
            progress[sequenceNumber] = answer;

            const isLessonEnded = lessonData.allNumber === sequenceNumber;
            if (isLessonEnded) return onTestEnd(progress);

            loadCurrentActiivityData(sequenceNumber + 1, progress);
        };

    useEffect(() => {
        loadCurrentActiivityData(0, []);
    }, []);

    return (
        <div className="activity-page">
            <button
                className="activity-page__close-button"
                onClick={() => navigate(-1)}
            >
                <FontAwesomeIcon icon={faXmark} />
            </button>

            <div className="activity-page__content-wrapper">
                {Object.keys(activityData).length > 0 && <div className="activity-page__activity-title-wrapper">
                    <Link
                        to={`/platform/lessons/${params.pairId}/${params.level}`}
                    >
                        <button className="activity-page__close-button activity-page__close-button--mobile">
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </Link>
                    <div
                        className="activity-page__square-div"
                        style={{
                            background: getLevelColor(activityData.level),
                        }}
                    ></div>
                    <div className="activity-page__activity-title-div">
                        <p className="activity-page__activity-title">
                            {getActivityName(
                                activityData.type,
                                activityData.gameName
                            )}
                        </p>
                    </div>
                </div>}

                {activityComponent}

                <div className="activity-page__squares-wrapper">
                    {progressComponents}
                </div>
            </div>
        </div>
    );
}

export default TestActivityPage;
