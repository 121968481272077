import { Link, useParams } from "react-router-dom";
import MainButton from "../../components/Buttons/MainButton/MainButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton/SecondaryButton";
import Navbar from "../../components/Navbar/Navbar";
import ColouredSpeechBubble from "../../components/SpeechBubbles/ColouredSpeechBubble/ColouredSpeechBubble";
import Store from "../../../Store";
import "./HomePage.css";

function HomePage() {
  const {lang} = useParams();
  return (
    <div className="home-page">
      <div className="home-page__content-wrapper">
        <div className="home-page__main-content">
          <div className="home-page__slogan-wrapper">
            <h1 className="home-page__slogan">
              {Store.getText("homePageSloganFirstLine", true)}
              <span className="home-page__slogan-span" style={{display: 'block'}}>
                {Store.getText("homePageSloganSecondLine", true)}
              </span>
            </h1>
          </div>
          <div className="home-page__login-register-wrapper">
            <ColouredSpeechBubble className="coloured-speech-bubble--right-top home-page__login-div">
              <h2 className="home-page__login-div-header">
                {Store.getText("login", true)}
              </h2>
              <p className="home-page__login-div-slogan">
                {Store.getText("loginBoxDescription", true)}
              </p>
              <Link className="home-page__login-link" to={`/${lang}/login`}>
                <SecondaryButton>
                  {Store.getText("loginButtonText", true)}
                </SecondaryButton>
              </Link>
            </ColouredSpeechBubble>
            <ColouredSpeechBubble className="coloured-speech-bubble--right-top home-page__register-div">
              <h2 className="home-page__register-div-header">
                {Store.getText("register", true)}
              </h2>
              <p className="home-page__register-div-slogan">
                {Store.getText("registerBoxDescription", true)}
              </p>
              <Link className="home-page__register-link" to={`/${lang}/register`}>
                <MainButton>{Store.getText("registerButtonText", true)}</MainButton>
              </Link>
            </ColouredSpeechBubble>
          </div>
        </div>
        <ColouredSpeechBubble className="coloured-speech-bubble--left-top home-page__more-info-div">
          <h3 className="home-page__more-info-header">
            {Store.getText("learnMoreHeader", true)}
          </h3>
          <p className="home-page__more-info-text">
            {Store.getText("learnMoreDescription", true)}
          </p>
          <Link to ={`/${lang}/product`}>
          <button className="home-page__more-info-button">{`${Store.getText(
            "learnMoreButton", true
          )} >>`}</button>
          </Link>
        </ColouredSpeechBubble>
      </div>
    </div>
  );
}

export default HomePage;
