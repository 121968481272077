import de1 from "./lesson-bgs/de1.webp";
import de2 from "./lesson-bgs/de2.webp";
import de3 from "./lesson-bgs/de3.webp";

import nl1 from "./lesson-bgs/nl1.webp";
import nl2 from "./lesson-bgs/nl2.webp";
import nl3 from "./lesson-bgs/nl3.webp";

import pl1 from "./lesson-bgs/pl1.webp";
import pl2 from "./lesson-bgs/pl2.webp";
import pl3 from "./lesson-bgs/pl3.webp";

import ru1 from "./lesson-bgs/ru1.webp";
import ru2 from "./lesson-bgs/ru2.webp";
import ru3 from "./lesson-bgs/ru3.webp";

import fr1 from "./lesson-bgs/fr1.webp";
import fr2 from "./lesson-bgs/fr2.webp";
import fr3 from "./lesson-bgs/fr3.webp";

import it1 from "./lesson-bgs/it1.webp";
import it2 from "./lesson-bgs/it2.webp";
import it3 from "./lesson-bgs/it3.webp";

import es1 from "./lesson-bgs/es1.webp";
import es2 from "./lesson-bgs/es2.webp";
import es3 from "./lesson-bgs/es3.webp";

import en1 from "./lesson-bgs/en1.webp";
import en2 from "./lesson-bgs/en2.webp";
import en3 from "./lesson-bgs/en3.webp";

import pt1 from "./lesson-bgs/pt1.webp";
import pt2 from "./lesson-bgs/pt2.webp";
import pt3 from "./lesson-bgs/pt3.webp";

const lessongBgs = {
  de1,
  de2,
  de3,
  ru1,
  ru2,
  ru3,
  es1,
  es2,
  es3,
  fr1,
  fr2,
  fr3,
  it1,
  it2,
  it3,
  nl1,
  nl2,
  nl3,
  pl1,
  pl2,
  pl3,
  en1,
  en2,
  en3,
  pt1,
  pt2,
  pt3,
};

const getLessonBg = (short, level) => {
  return lessongBgs[short+level] ? lessongBgs[short+level] : lessongBgs.en1;
};

export default getLessonBg;
