import React, { useEffect, useRef, useState } from 'react';
import UserProfile from '../../../../platform/pages/MailBoxPage/components/UserProfile';
import api from '../../../../platform/api/api';
import moment from 'moment';
import { CONVERSATION_STATUS } from './MailBoxTable';
import { ArrowIcon } from '../icons/Arrow';

const BoxElement = ({ setConversation, conversation }) => {
    const [isCompleted, setIsCompleted] = useState(
        conversation.consultantStatus === CONVERSATION_STATUS.COMPLETED
    );
    const textRef = useRef();

    const [messages, setMessages] = useState([]);

    const fetchMessages = async () => {
        api.get(`/messages/conversation/${conversation.id}/consultant`).then(
            (response) => {
                console.log(response.data);
                setMessages(response.data);
            }
        );
    };

    const onCompletedToggle = (value) => {
        const status = Boolean(value)
            ? CONVERSATION_STATUS.COMPLETED
            : CONVERSATION_STATUS.NEW_IN_CONVERSATION;
        setIsCompleted(value);
        api.post(`/conversations/${conversation.id}/status`, { status });
    };

    const handleSendMessage = (e) => {
        e && e.preventDefault();

        const message = textRef.current.value;
        api.post(`/messages/conversation/${conversation.id}/consultant`, {
            content: message,
        }).then(() => {
            textRef.current.value = '';
            fetchMessages();
        });
    };

    useEffect(() => {
        fetchMessages();
    }, [conversation]);

    return (
        <div className="mailbox-wrapper__box__element">
            <div className="mailbox-wrapper__box__element__style">
                <div className="mailbox-wrapper__top_box">
                    <div
                        className="mailbox-button__back"
                        onClick={() => setConversation(null)}
                    >
                        <ArrowIcon scale={0.7} />
                    </div>
                    <div className="mailbox-mail__topic">
                        {conversation.topic}
                    </div>
                    <div className="mailbox-mail__end">
                        <label for="myCheckbox" className="mailbox-mail__text">
                            Zakończ wątek
                        </label>
                        <input
                            type="checkbox"
                            id="myCheckbox"
                            checked={isCompleted}
                            onChange={(e) =>
                                onCompletedToggle(e.target.checked)
                            }
                        />
                    </div>
                </div>
                <div className="mailbox-wrapper__elements">
                    {messages.map((message, index) => (
                        <div className="mailbox-element" key={index}>
                            <UserProfile />
                            <div className="mailbox-sender">
                                {message.user.username}
                            </div>
                            <div className="mailbox-message__element">
                                {message.image && (
                                    <div className="mailbox-message__image-wrapper">
                                        <img
                                            src={message.image.base64}
                                            className="mailbox-message__image"
                                        />
                                    </div>
                                )}
                                {conversation.wordPair && (
                                    <div className='mailbox-message__word'>
                                        <p>
                                            <b>Expression native:</b>{' '}
                                            {
                                                conversation.wordPair.wordFrom
                                                    .expression.text
                                            }
                                        </p>
                                        <p>
                                            <b>Sentence native:</b>{' '}
                                            {conversation.wordPair.sentenceFrom}
                                        </p>
                                        <p>
                                            <b>Expression translation:</b>{' '}
                                            {
                                                conversation.wordPair.wordTo
                                                    .expression.text
                                            }
                                        </p>
                                        <p>
                                            <b>Sentence translation:</b>{' '}
                                            {conversation.wordPair.sentenceTo}
                                        </p>
                                    </div>
                                )}
                                {message.content
                                    .split('\n')
                                    .map(function (item, idx) {
                                        return (
                                            <span key={idx}>
                                                {item}
                                                <br />
                                            </span>
                                        );
                                    })}
                            </div>
                            <div className="mailbox-date">
                                {moment(message.timestamps.createdAt).format(
                                    'DD-MM-YYYY'
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <form
                    className="mailbox-wrapper__typing"
                    onSubmit={handleSendMessage}
                >
                    <textarea
                        className="mailbox-texarea__typing"
                        rows={2}
                        ref={textRef}
                        required
                    ></textarea>
                    <button className="mailbox-button__typing" type="submit">
                        Wyślij
                    </button>
                </form>
            </div>
        </div>
    );
};

export default BoxElement;
