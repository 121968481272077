import "./SecondaryButton.css";

function SecondaryButton(props) {
  return (
    <button
      type="button"
      {...props}
      className={"secondary-button " + props.className}
    >
      {props.children}
    </button>
  );
}

export default SecondaryButton;
