import React from "react";
import styled from "styled-components";

const PhotoWraper = styled.div`
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const Title = styled.h3`
  font-weight: bold;
  text-align: center;
  color: black;
  margin-bottom: 5px;
`;

const Text = styled.p`
  text-align: center;
  color: black;
`;

const Wrapper = styled.div`
  max-width: 300px;

  @media (max-width: 600px) {
    max-width: 100%;
    margin: 20px;
  }
`;

const NewFeature = styled.div`
  position: absolute;
  color: red;
  margin-top: -210px;
  margin-left: 210px;
  transform: rotate(0deg);
  font-weight: bold;
  font-size: 24px;
`;

const LearningElement = ({ image, text, headline, text2, newOne = false }) => {
  return (
    <Wrapper>
      <PhotoWraper>
        <img src={image} alt="logo" style={{ width: "auto", height: "100%" }} />
      </PhotoWraper>
      <Title>{headline}</Title>
      <Text>{text}</Text>
      <Text>{text2}</Text>
    </Wrapper>
  );
};

export default LearningElement;
