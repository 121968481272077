import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import {
  BackgroundProductPage,
  Cloud,
  ColorChangerProductPage,
} from "../../reusable/exportedStyles/exportedStyles";
import hero from "../../../images/zdj_1.jpg";
import styled from "styled-components";
import photo from "../../../images/boxes/dymek_bialy_prawo_gora.png";
import { Colors } from "../../../colors/Colors";

const Wrapperr = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: auto;
`;

const ColorChanger = styled.div`
  background: rgba(36, 71, 35, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
`;
const H1 = styled.h1`
  font-weight: lighter;
  font-size: 30px;
  max-width: 200px;
  margin: 0px;
  text-align: center;
  color: ${Colors.darkBlue};
`;

const CloudWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: right;
  width: 100%;
  padding: 30px;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  background: white;
  border-radius: 10px;
  max-width: 600px;
`;

const FormSender = ({ data }) => {
  const [text, setText] = useState("...");
  const [showMore, setShowMore] = useState(false);
  const [showButtonThx, setShowButtonThx] = useState(true);

  const inputOne = useRef();
  const inputTwo = useRef();
  const inputThree = useRef();

  const changeText = (e) => {
    setShowMore(() => !showMore);
    e.preventDefault();
    if (text === "...") {
      setText(data.textTwo);
    } else {
      setText("...");
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();

    window.gtag("event", "form_submit");

    const dataOne = inputOne.current.value;
    const dataTwo = inputTwo.current.value;
    const dataThree = inputThree.current.value;

    inputOne.current.value = "";
    inputTwo.current.value = "";
    inputThree.current.value = "";
    setShowButtonThx(false);

    setTimeout(() => {
      setShowButtonThx(true);
    }, 6000);

    await axios
      .post(data.pageLink, {
        productVariant: data.slovakLead ? data.slovakLead : "cz",
        phone: dataTwo,
        name: dataOne,
        email: dataThree,
        surname: "brak",
        utmSource: "Platform",
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {}, [
    text,
    setText,
    showMore,
    setShowMore,
    showButtonThx,
    setShowButtonThx,
  ]);

  return (
    <BackgroundProductPage img={hero}>
      <ColorChanger>
        <CloudWrapper>
          <Cloud img={photo}>
            <div style={{ padding: "0 50px" }}>
              <H1>{data.titleUp} </H1>
              <H1>{data.titleDown} </H1>
            </div>
          </Cloud>
        </CloudWrapper>
        <Wrapperr>
          <Card className="form_wrapper">
            <form
              id="form"
              style={{
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "1600px",
              }}
              onSubmit={(e) => submitForm(e)}
            >
              <h3
                id="formHeading"
                style={{
                  textAlign: "center",
                  color: Colors.darkDarkBlue,
                  fontWeight: "lighter",
                  fontSize: "30px",
                }}
              >
                {data.title}
              </h3>
              <h3
                id="formHeading"
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  color: Colors.darkDarkBlue,
                  fontWeight: "lighter",
                  fontSize: "30px",
                }}
              >
                {data.title1}
              </h3>
              <h3
                id="formHeading"
                style={{
                  marginBottom: "40px",
                  color: Colors.darkDarkBlue,
                  fontWeight: "lighter",
                  fontSize: "25px",
                }}
              >
                {data.subtitle} <strong>{data.subtitle2}</strong>
              </h3>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  flexDirection: "column",
                }}
              >
                <div
                  className="input"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <input
                    ref={inputOne}
                    name="name"
                    placeholder={data.button1}
                    type="text"
                    required
                    style={{
                      border: `2px solid ${Colors.darkDarkBlue}`,
                      borderRadius: "10px",
                      padding: "10px",
                      fontSize: "18px",
                    }}
                  />
                </div>
                <div
                  className="input"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <input
                    ref={inputTwo}
                    name="phone"
                    placeholder={data.button2}
                    type="text"
                    required
                    style={{
                      border: `2px solid ${Colors.darkDarkBlue}`,
                      borderRadius: "10px",
                      padding: "10px",
                      fontSize: "18px",
                    }}
                  />
                </div>
                <div
                  className="input"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <input
                    ref={inputThree}
                    name="email"
                    placeholder={data.button3}
                    type="email"
                    style={{
                      border: `2px solid ${Colors.darkDarkBlue}`,
                      borderRadius: "10px",
                      padding: "10px",
                      fontSize: "18px",
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {showButtonThx ? (
                  <input
                    style={{
                      border: "1px solid white",
                      justifyContent: "center",
                      minWidth: "200px",
                      background: Colors.darkDarkBlue,
                      color: "white",
                      margin: "20px",
                      padding: "10px",
                      borderRadius: "20px",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                    value={data.buttonSub}
                    type="submit"
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "20px",
                      color: Colors.darkDarkBlue,
                    }}
                  >
                    <button
                      style={{
                        border: "1px solid white",
                        justifyContent: "center",
                        width: "200px",
                        background: "darkGreen",
                        color: "white",
                        margin: "20px",
                        padding: "10px",
                        borderRadius: "20px",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {data.buttonTXT}
                    </button>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h3 style={{ textAlign: "center" }}>{data.buttonText}</h3>
                      <h3 style={{ textAlign: "center" }}>
                        {data.buttonText2}
                      </h3>
                    </div>
                  </div>
                )}
              </div>
              <div style={{ display: "grid" }}>
                <label>
                  <input type="checkbox" defaultChecked="true" required />
                  <span style={{ paddingLeft: "10px" }}>
                    {data.checkOne}
                    <span> </span>
                    <a href={data.rulesLink} target="_blank">
                      {data.subOne}
                    </a>
                    <span> </span>
                    <a href={data.privacyLink} target="_blank">
                      {data.subTwo}
                    </a>
                  </span>
                </label>
                <label>
                  <input type="checkbox" defaultChecked="true" required />
                  <span style={{ paddingLeft: "10px" }}>{data.checkTwo}</span>
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  color: "grey",
                  alignItems: "center",
                  fontSize: "10px",
                }}
              >
                <p
                  style={{
                    padding: "10px",
                    paddingBottom: "0px",
                    fontSize: "10px",
                  }}
                >
                  {data.textOne}: {text}
                </p>{" "}
                <button
                  style={{
                    border: "none",
                    color: "grey",
                    background: "none",
                    marginTop: "-2px",
                    fontSize: "10px",
                  }}
                  onClick={(e) => changeText(e)}
                >
                  <div style={{ marginTop: "11px" }}>
                    {showMore ? data.buttonLess : data.buttonMore}
                  </div>
                </button>
              </div>
            </form>
          </Card>
        </Wrapperr>
      </ColorChanger>
    </BackgroundProductPage>
  );
};

export default FormSender;
