import SecondaryButton from "../../Buttons/SecondaryButton/SecondaryButton";
import MainModal from "../MainModal/MainModal";
import "./ConfirmationModal.css";

function ConfirmationModal(props) {
  return (
    <MainModal
      isModalOpen={props.isModalOpen}
      setIsModalOpen={props.setIsModalOpen}
    >
      <p className="confirmation-modal__text">{props.content}</p>
      <SecondaryButton onClick={props.onConfirm}>
        {props.buttonText}
      </SecondaryButton>
    </MainModal>
  );
}

export default ConfirmationModal;
