import england from "../images/countries/mapa_anglia.png";
import poland from "../images/countries/mapa_polska.png";
import italy from "../images/countries/mapa_wlochy.png";
import germany from "../images/countries/mapa_niemcy.png";
import niderland from "../images/countries/mapa_holandia.png";
import portugal from "../images/countries/mapa_portugalia.png";
import russia from "../images/countries/mapa_rosja.png";
import spanish from "../images/countries/mapa_hiszpania.png";
import french from "../images/countries/mapa_francja.png";

import testimonial1 from "../images/testamonial/user.png";
import testimonial2 from "../images/testamonial/user.png";
import testimonial3 from "../images/testamonial/user.png";
import testimonial4 from "../images/testamonial/user.png";
import testimonial5 from "../images/testamonial/user.png";

import brandValuesPhoto1 from "../images/brandValues/wartosci_ambicja.webp";
import brandValuesPhoto2 from "../images/brandValues/wartosci_radosc.webp";
import brandValuesPhoto3 from "../images/brandValues/wartosci_ciekawosc.webp";
import brandValuesPhoto4 from "../images/brandValues/wartosci_efektywnosc.webp";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import mockup from "../images/mockup_ro.png";

export const english = {
  navbar: {
    data: [
      { title: "Order a course", path: "/course" },
      { title: " Log in	", path: "" },
      { title: "About Smartado", path: "/product" },
      { title: "About us", path: "/about" },
    ],
  },
  mainPage: {
    hero: {
      title: "Where will your new language take you?",
      right1: "New friendships?",
      right2: "A vacation in the tropics?",
      right3: "A family abroad?",
      middle1: "A higher position?",
      middle2: "Better grades?",
      left1: "A job in a new place?",
    },
    poliglotWithNumbers: {
      title: "Smartado in numbers",
      count: [
        {
          number: 8,
          description: "languages",
        },
        {
          number: 24000,
          description: "users",
        },
        {
          number: 238,
          description: "lessons",
        },
      ],
    },
    poliglotMap: {
      title: "What is Smartado?",
      box1: "Words, phrases and expressions",
      boxDescription1:
        "Each lesson is accompanied by carefully selected pictures that make the learning process intuitive and contextual. The most frequently used topics cover a wide range of vocabulary from everyday life and travel, making it possible to communicate in these areas without the so-called language block.      ",
      box2: "A multitude of lessons",
      boxDescription2:
        "As you gain experience, the number of lessons you will have access to will increase. If you decide to learn from scratch, start with lesson types that cover topics from simple to complex. Each lesson is not just the learning of individual vocabulary words, but also contains varied tasks.",
      box3: "30 minutes are enough",
      boxDescription3:
        "No pressure! You can start and finish your lessons at any time. Nothing will happen if your daily routine takes up all your time. This is what sets our course apart - time flexibility. However, we suggest that you spend 30 minutes a day studying - at least from time to time. This will make the memorization process even smoother and you will start speaking the foreign language even faster, just like a native.",
      box4: "Certificate",
      boxDescription4:
        "A course completion certificate is a great honour. After all, everyone would like to have confirmation that they have acquired new skills. After completing all three levels of the course and passing the final test - you will receive a free certificate. You can print it out and be proud of yourself as a participant. Congratulations - you did it. Show it to your family and friends, brag to your boss at work and speak a foreign language fluently!",
      box5: "3 learning levels",
      boxDescription5:
        "Whether you don't know a foreign language at all, can introduce yourself, order a coffee or talk to your son-in-law from abroad - Smartado is for everyone. That's why the course is divided into basic, intermediate and advanced levels. At each level, learning algorithms constantly select the right vocabulary so that learning doesn't surprise anyone with too difficult a level. After completing the basic level, you can move on to the intermediate or advanced level. That's 3 courses for the price of one!",
    },
    avaiableLanguages: {
      title: "Available languages",
      text: "Have you always wanted to learn a foreign language? Choosing the right language is an important decision that can change your life for the better. ",
      text1:
        "Speaking a new language can open doors to new opportunities as you grow.",
      text2:
        "Becoming more proficient in any language takes time, dedication, enthusiasm, motivation and perseverance. Smartado's language platform is designed to help you learn any language.",
      languages: [
        { image: spanish, lang: "Spanish" },
        { image: french, lang: "French" },
        { image: niderland, lang: "Dutch" },
        { image: germany, lang: "German" },
        { image: italy, lang: "Italian" },
        { image: russia, lang: "Russian" },
      ],
      polish: { image: poland, lang: "Polish" },
    },
    testimonial: {
      title: "Users write",
      elements: [
        {
          id: 1,
          photo: testimonial1,
          date: "24 January 2022",
          stars: 5,
          title: "The whole family learns!",
          text: "In my opinion the Smartado application is very well prepared. Thanks to it, knowledge is easy to assimilate, it is interesting which makes learning a pleasure and I like to use it every day. I recommend 🙂.",
          bottomInfo: "Jeffrey Zane",
        },
        {
          id: 2,
          photo: testimonial2,
          date: "24 January 2022",
          stars: 5,
          title: "Time goes faster with Smartado!",
          text: " Overall I'm quite happy with this app and I'm learning my 3rd language with it.",
          bottomInfo: "Rhonda Greer",
        },
        {
          id: 3,
          photo: testimonial3,
          date: "24 January 2022",
          stars: 4,
          title: "Learning for busy people",
          text: "Super platform, the best language learning on the market. Learning is very substantive and practical. Emphasizes both vocabulary and grammar, which I have always lacked in other applications of this type, in addition to a wide range of languages.          ",
          bottomInfo: "Dwight Stutz",
        },
        {
          id: 4,
          photo: testimonial4,
          date: "24 January 2022",
          stars: 4.5,
          title: "Now I live abroad!",
          text: "Smartado supports me in learning foreign languages. I have been actively using it for over a year now and I am very satisfied. I'm glad that it is constantly updated and upgraded. I highly recommend it to everyone who starts learning foreign languages - there is no better application!          ",
          bottomInfo: "Margaret Parham",
        },
        {
          id: 5,
          photo: testimonial5,
          date: "24 January 2022",
          stars: 5,
          title: "It's never too late",
          text: "I've browsed a few other top language learning apps and this one seems to have the best balance between fun and enjoyment and effective learning. I really recommend it 👌          ",
          bottomInfo: "Jodi Weaver          ",
        },
        {
          id: 6,
          photo: testimonial5,
          date: "24 January 2022",
          stars: 5,
          title: "It's never too late",
          text: "I think the app is great for people who want to increase their skills in communicating in a foreign language. There is something for everyone. The best thing is that people of any age who have never had anything to do with learning a foreign language can start learning:) I recommend 😊",
          bottomInfo: "John McNeil",
        },
      ],
    },
    course: {
      title: "What does the course look like?",
      mockupPolyglot: mockup,
      right:
        "On the Smartado platform you will not find boring, dry grammar rules that you have to memorize in one day. You will learn sentences and expressions as they are used in everyday life and then consolidate the memory through simple exercises and repetitions. The course is designed to make learning fun. ",
      right2Title: "Real-life lessons",
      right2Bottom:
        "Each level of learning consists of dozens of lessons. If you don't know what level you are - start with the basics. Learn greetings, days of the week, feelings. Learn how to order a meal in a restaurant, and how to order a taxi. Each lesson includes examples from everyday life. Each lesson is slightly different, consists of different tasks and exercises, but all of them form a whole - so that you can easily master the language. You can start a lesson at any time.",
      flexDown: [
        {
          title: "While learning, you will encounter elements such as:",
          text: `Recordings teaching correct pronunciation,`,
          text1: "Phrases and sentences from real life,",
          text2:
            "Images to help you remember words and phrases through visual associations",
          text4: "...and many, many more.",
        },
        {
          title: "Exercises",
          text: `Whether you enjoy solving crosswords on a daily basis or, on the contrary, prefer to avoid such activities, there's no denying that these mental puzzles are excellent for training your memory and allowing you to repeat the material learnt.`,
        },
        {
          title: "Repetitions",
          text: `More advanced learners can repeat vocabulary and test their knowledge in all subject areas through the continuous learning mode. It contains all available vocabulary, from all categories. A learning algorithm constantly checks the level of knowledge and adjusts the next lessons.`,
        },
      ],
    },
    learningWithPolyglot: {
      title: "Learning with Smartado",
      cards: [
        {
          icon: learning1,
          headline: `Short but systematic lessons`,
          text: ` The most important thing when learning a language is regularity. At the same time it is difficult for all of us to find the time we need. With this in mind, Smartado is designed to be taught in short, but systematic lessons.
          `,
        },
        {
          icon: learning2,
          headline: `Lessons without leaving home`,
          text: `You don't need anything other than your phone, computer or tablet to use the Smartado learning app. With Smartado you can learn anywhere: in line, on the bus and, above all, you can learn at home without having to go anywhere. Do you learn in the bathtub or while walking the dog? With Smartado it's possible!
          `,
          text2:
            "Learning with Smartado will save you a lot of commuting time and you can easily integrate learning into your schedule!            ",
        },
        {
          icon: learning3,
          headline: `Top quality`,
          text: `During the development of Smartado a team of experts focused on one of the most important goals: to enable our method to make learning a foreign language as fast as possible (regardless of the student's age!).
          `,
        },
      ],
    },
    whyPolyglot: {
      title: "Why learn with Smartado?",
      textUp:
        "It is a platform with a multifaceted approach to learning that will help you master a new language in the same way as you would your mother tongue – naturally.        ",
      textBottom:
        "Learning a foreign language can change your life. Not only does it open a window to a world full of professional and personal opportunities, it also develops your mind and boosts your confidence. As technologies such as the internet reduce distance and we become members of a global community, the ability to learn a language becomes an essential part of our interaction with the world and success.",
      cards: [
        {
          headline: `Learn phrases and expressions from everyday life using our advanced learning technology. `,
          text: `Lessons are constantly adapted to your level, so you'll never run out of boredom or motivation.
          `,
        },
        {
          headline: `Immerse yourself in a foreign language. Try to fully understand a new language based on your contextual knowledge.`,
          text: `With thousands of graphics and audio clips, you'll see and hear like a native speaker.
          `,
        },
        {
          headline: `Gain confidence in speaking. The real purpose of learning languages is also to be understood. `,
          text: `With Smartado's help, all you have to do is repeat the vocabulary you've heard - don't be afraid to talk! Our system will fix you up.
          `,
        },
        {
          headline: `Don't let words escape your memory. Learn through a variety of exercises.`,
          text: `We've carefully designed the elements of the app so that your mind never gets bored. We're constantly preparing and adding new challenges to Smartado to make learning fun.
          `,
        },
      ],
    },
  },
  aboutUs: {
    mission: {
      title: "Mission",
      text1:
        "Our main goal is to make the same quality learning as private tutoring available to everyone using technology. ",

      title2: "Universal access",
      textTitle2:
        "More than 1.2 billion people are learning foreign languages and most of them hope to gain new opportunities by doing so. Unfortunately, learning a language is expensive and for many people access is closed.",

      title3: "Cultural Bridge",
      textTitle3:
        "We believe that language is a kind of bridge between different cultures. It encourages the search for understanding and conversation, encourages the pursuit of goals and the making of discoveries. For some, it is the key to success; for others, it is the ticket to a new world.",

      textBottom:
        "That's why the mission of the Smartado Learning Platform is to make language learning more effective and comfortable. By harnessing the power of technology, we can unite the world through language. ",
    },
    approach: {
      title: "Approach",
      text1:
        "From our conversations with the community, it seems that many users from around the world like the Smartado Language Platform",
      text2:
        "Our mission is to break down communication barriers and bring people together through language learning.",
      text3:
        "Understanding what we stand for and how we communicate with our community is essential to adapting to the needs of our diverse community that we have built.",
      paragraphData: [
        {
          id: 1,
          title: "Personalization",
          text: "Machine learning algorithms work all the time to deliver learning materials at the right level for each person.",
        },
        {
          id: 2,
          title: "A balanced approach",
          text: `Smartado Online enables language learners to explore a variety of patterns on their own, without the need to focus on language rules - just as we learned our first language as children. This approach, called "latent learning," is ideal for developing a solid understanding of a language and its rules at a basic level.`,
        },
        {
          id: 3,
          title: "Community",
          text: "Languages make people socialize. Exploring languages encourages people to find understanding. The community of the Smartado language platform unites people from all over the world.",
        },
        {
          id: 4,
          title: "Continuous improvement",
          text: "We're constantly creating new and better courses, expanding our collection of language learning tools, and updating our materials as the language evolves to make sure we're constantly offering access to the best language education in the world. Like language, we never stop evolving.          ",
        },
      ],
    },
    brandValues: {
      title: "Brand Values",
      part2: {
        textInfo:
          "Our brand values define how we communicate, how we work on our product, and how we will develop the Smartado language platform in the coming years.",
        brandValuesData: [
          {
            photo: brandValuesPhoto1,
            title: "Ambition",
            text: "We want our users to succeed, regardless of the goals they set for themselves. We are ambitious and constantly pushing the boundaries. ",
          },
          {
            photo: brandValuesPhoto2,
            title: "Joy",
            text: "We believe that learning languages should be fun. We help people gain fascinating benefits from being able to speak another language. ",
          },
          {
            photo: brandValuesPhoto3,
            title: "Curiosity",
            text: "We have a constant thirst for knowledge and growth. We are passionate about learning about other cultures. We help people discover the world through languages. ",
          },
          {
            photo: brandValuesPhoto4,
            title: "Effectiveness",
            text: "We want people to learn languages wisely. We combine education with technology. ",
          },
        ],
      },
    },
  },
  formSender: {
    formControll: {
      titleUp: "Order",
      titleDown: "a course",
      pageLink: "https://viitor-si-competenta.eu/typ",
      title: "Interested in a course?",
      title1: "Would you like more information?",
      subtitle: "Leave us your number – ",
      subtitle2: "we will call you back",
      button1: "Name and surname",
      button2: "Phone number",
      button3: "E-mail",
      buttonSub: "Request a call >",
      checkOne:
        "By submitting this form I agree to the terms and conditions and declare that I have read",
      checkOneTwo: "a",
      checkTwo: "I agree to be contacted for marketing purposes",
      textOne: "We inform you that",
      textTwo:
        "The controller of your personal data is the company Lingua Institute Spółka z ograniczoną odpowiedzialnością, with its registered office in Warsaw, Nowogrodzka 50 / 515, 00-695, registered in the Register of Entrepreneurs kept by the District Court in Warsaw under KRS 0001003739, REGON: 523740380, NIP 7011116589. Your personal data will be processed in connection with the processing of your enquiry in the contact form or the performance of the concluded contract. By contacting us, you voluntarily consent to the processing of your personal data for the above purposes. In connection with our processing of your data, you have the right of access, rectification, erasure or restriction of processing, the right to object to processing and the right to data portability. You also have the right to withdraw your consent at any time, but withdrawal of consent does not affect the lawfulness of processing carried out on the basis of consent prior to withdrawal. You also have the right to lodge a complaint with a supervisory authority. The provision of the data is of course voluntary, but may be necessary to deal with an enquiry or in connection with the performance of a contract. The personal data will be kept for as long as necessary for the above purposes, but not for the period of limitation of any claims under general law. Please read the privacy policy carefully.",
      buttonMore: "Read more",
      buttonLess: "Read less",
      subOne: "conditions",
      subTwo: "and data protection rules.",
      buttonTXT: "Thank you",
      buttonText:
        "One of our consultants will get back to you within the next few business days.",
      buttonText2: "We' ll be in touch!",
      privacyLink: "/Privacy_EN.pdf",
      rulesLink: "/Regulations_EN.pdf",
    },
  },
  footer: {
    privacy: "Privacy",
    privacyLink: "/Privacy_EN.pdf",
    rules: "Regulations",
    rulesLink: "/Regulations_EN.pdf",
  },
};
