import { useState } from "react";
import Store from "../../../Store";
import api from '../../api/api';
import './RateWordPair.css'


const RateWordPair = ({ existingRate, wordPairId }) => {
    const [rate, setRate] = useState(existingRate ? existingRate.rate : 0);

    const makeRate = async (rate) => {
        await api.post(`/rate`, {
            wordPairId,
            rate,
        });
        setRate(rate);
    };

    return (
        <div className="word-pair-rate__wrapper">
            <p className="word-pair-rate__text">
                {Store.getText('makeRateText')}
            </p>
            <svg
                className={`word-pair-rate__rate ${
                    rate === 1 ? 'word-pair-rate__rate--up-active' : ''
                }`}
                version="1.1"
                id="UP"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 39.8 36.3"
                onClick={() => makeRate(1)}
            >
                <path
                    class="st0"
                    d="M14.5,36.3h16.3c1.4,0,2.8-1,3.4-2.2l5.4-12.8c0.1-0.4,0.2-0.8,0.2-1.3v-3.6c0-2-1.6-3.6-3.6-3.6H24.7l1.7-8.3
V3.9c0-0.7-0.4-1.4-0.8-1.9L23.6,0L11.7,11.9c-0.6,0.6-1.1,1.6-1.1,2.5v18.1C10.9,34.7,12.4,36.3,14.5,36.3z M14.5,14.6l7.8-7.8
l-2.4,9.6h16.3V20l-5.4,12.7H14.5V14.6z M7.2,36.3H0V14.6h7.2V36.3z"
                />
            </svg>
            <svg
                className={`word-pair-rate__rate ${
                    rate === -1 ? 'word-pair-rate__rate--down-active' : ''
                }`}
                version="1.1"
                id="DOWN"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 39.8 36.3"
                onClick={() => makeRate(-1)}
            >
                <path
                    class="st0"
                    d="M25.3,0H9C7.6,0,6.3,1,5.7,2.2L0.2,14.9C0.1,15.3,0,15.8,0,16.3v3.6c0,2,1.6,3.6,3.6,3.6h11.5l-1.7,8.3v0.6
c0,0.7,0.4,1.4,0.8,1.9l1.9,1.9L28,24.3c0.6-0.6,1.1-1.6,1.1-2.5V3.7C28.9,1.6,27.4,0,25.3,0z M25.3,21.7l-7.8,7.8l2.4-9.6H3.6v-3.6
L9,3.6h16.3V21.7z M32.6,0h7.2v21.7h-7.2V0z"
                />
            </svg>
        </div>
    );
};

export default RateWordPair;
