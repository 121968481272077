import { useState } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../GrammarModal.css";

const verbTenseSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Prítomný čas slabých slovies</h3>
    <p className="grammar__text">
      Väčšina nemeckých slovies sa časuje pravidelne. V prvej osobe priberajú
      osobnú koncovku –e, v druhej osobe koncovku –st, v tretej osobe koncovku
      –t, v množnom čísle v prvej osobe koncovku –en, v druhej osobe koncovku
      –t, a v tretej osobe a pri vykaní koncovku –en.
    </p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Osoba</th>
          <th className="grammar__table-header">Jednotné číslo</th>
          <th className="grammar__table-header">Množné číslo</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. osoba</td>
          <td className="grammar__table-data">ich mache</td>
          <td className="grammar__table-data">wir machen</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. osoba</td>
          <td className="grammar__table-data">du machst</td>
          <td className="grammar__table-data">ihr macht</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. osoba</td>
          <td className="grammar__table-data">er,sie, es macht</td>
          <td className="grammar__table-data">sie, Sie machen</td>
        </tr>
      </tbody>
    </table>
    <p className="grammar__text">
      Slovesá končiace sa v kmeni na –t , st, alebo –d, sa časujú ako sloveso
      arbeiten. Medzi kmeň a osobnú koncovku sa vkladá –e.
    </p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Osoba</th>
          <th className="grammar__table-header">Jednotné číslo</th>
          <th className="grammar__table-header">Množné číslo</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. osoba</td>
          <td className="grammar__table-data">ich arbeite</td>
          <td className="grammar__table-data">wir arbeiten</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. osoba</td>
          <td className="grammar__table-data">du arbeitest</td>
          <td className="grammar__table-data">ihr arbeitet</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. osoba</td>
          <td className="grammar__table-data">dr, sie, es arbeitet</td>
          <td className="grammar__table-data">sie ,Sie arbeiten</td>
        </tr>
      </tbody>
    </table>

    <p className="grammar__text">
      Slovesá, ktoré sa končia na –s, -ß, -x, tz, alebo na –z, sa časujú ako
      sloveso heißen. V druhej osobe jednotného čísla odpadá –s. Tvar druhej
      osoby jednotného čísla sa zhoduje s tvarom tretej osoby.
    </p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Osoba</th>
          <th className="grammar__table-header">Jednotné číslo</th>
          <th className="grammar__table-header">Množné číslo</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. osoba</td>
          <td className="grammar__table-data">ich heiße</td>
          <td className="grammar__table-data">wir heißen</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. osoba</td>
          <td className="grammar__table-data">du heißt</td>
          <td className="grammar__table-data">ihr heißt</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. osoba</td>
          <td className="grammar__table-data">er, sie, es heißt</td>
          <td className="grammar__table-data">sie, Sie heißen</td>
        </tr>
      </tbody>
    </table>

    <p className="grammar__text">
      Slovesá s kmeňom na –el priberajú v infinitíve iba koncovku –n. Pri týchto
      slovesách v 1. osobe jednotného čísla –e pred –l odpadá.
    </p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Osoba</th>
          <th className="grammar__table-header">Jednotné číslo</th>
          <th className="grammar__table-header">Množné číslo</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. osoba</td>
          <td className="grammar__table-data">ich sammle</td>
          <td className="grammar__table-data">wir sammeln</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. osoba</td>
          <td className="grammar__table-data">du sammelst</td>
          <td className="grammar__table-data">Ihr sammelt</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. osoba</td>
          <td className="grammar__table-data">er, sie, es sammelt</td>
          <td className="grammar__table-data">sie, Sie sammeln</td>
        </tr>
      </tbody>
    </table>

    <p className="grammar__text">
      Pri slovesách, ktorých kmeň sa končí na –n alebo –m a predchádza im ďalšia
      spoluhláska sa vkladá tiež –e.
    </p>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Osoba</th>
          <th className="grammar__table-header">Jednotné číslo</th>
          <th className="grammar__table-header">Množné číslo</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">1. osoba</td>
          <td className="grammar__table-data">ich rechne</td>
          <td className="grammar__table-data">wir rechnen</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">2. osoba</td>
          <td className="grammar__table-data">du rechnest</td>
          <td className="grammar__table-data">ihr rechnet</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">3. osoba</td>
          <td className="grammar__table-data">er, sie, es rechnet</td>
          <td className="grammar__table-data">sie, Sie rechnen</td>
        </tr>
      </tbody>
    </table>
  </div>,
];

function WordsOrderTopic() {
  return (
    <div>
      <h2 className="grammar__topic-name">Slovosled v německé větě</h2>
      <div className="grammar__content-wrapper">
        <p className="grammar__text">
          Dnešní článek se vám pokusí stručně vysvětlit, v jakém pořadí
          poskládat slova v jednoduché větě (tedy nikoli v souvětí). Tento
          článek je určen pro začátečníky, proto se zatím omezíme na přítomný
          čas základních sloves.
        </p>
        <div className="grammar__inner-section">
          <p className="grammar__text">
            Oznamovací věta a otázka otevřená (doplňovací)
          </p>
          <p className="grammar__text">
            Nejdříve si rozebereme věty oznamovací a otevřené otázky (co je
            otázka otevřená a uzavřená naleznete v článku Otázky – základní
            slova).
          </p>
          <p className="grammar__text">
            V těchto větách je sloveso ve větě vždy na druhém místě.
          </p>
        </div>
        <p className="grammar__text">Podívejte se na příklady:</p>
        <div className="grammar__inner-section">
          <p className="grammar__text">
            Er <b>kommt</b> aus Bern.
          </p>
          <p className="grammar__text">
            Maria <b>wohnt</b> in Hamburg.
          </p>
        </div>
        <div className="grammar__inner-section">
          <p className="grammar__text">
            Was <b>ist</b> das? TTT
          </p>
          <p className="grammar__text">
            Wo <b>wohnst</b> du? TTT
          </p>
        </div>
        <p className="grammar__text">
          V následujících větách si povšimněte, že podmět může být složen i z
          více slov (Frau Wollner či Der Zug aus Köln), ale bereme jej jako
          jeden větný člen, takže sloveso je opravdu stále na druhém místě, jak
          velí zmíněné pravidlo.
        </p>
        <div className="grammar__inner-section">
          <p className="grammar__text">
            Frau Wollner <b>ist</b> zu Hause. TTT
          </p>
          <p className="grammar__text">
            Der Zug aus Köln <b>kommt</b> um sieben Uhr. TTT
          </p>
        </div>
        <p className="grammar__text">Podívejme se na tabulku:</p>
        <table className="grammar__table">
          <thead className="grammar__table-head">
            <tr className="grammar__table-row">
              <th className="grammar__table-header">první místo ve větě</th>
              <th className="grammar__table-header">sloveso</th>
              <th className="grammar__table-header">další části věty</th>
            </tr>
          </thead>
          <tbody className="grammar__table-body">
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Ich</td>
              <td className="grammar__table-data">arbeite</td>
              <td className="grammar__table-data">jeden Tag.</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Wohin</td>
              <td className="grammar__table-data">gehst</td>
              <td className="grammar__table-data">du?</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Jetzt</td>
              <td className="grammar__table-data">fahren</td>
              <td className="grammar__table-data">wir nach Wien.</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Woher</td>
              <td className="grammar__table-data">kommen</td>
              <td className="grammar__table-data">Sie, Herr Schwarz?</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Sie</td>
              <td className="grammar__table-data">sucht</td>
              <td className="grammar__table-data">ihren Mann.</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Die Frau</td>
              <td className="grammar__table-data">sehe</td>
              <td className="grammar__table-data">ich auch.</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Im Sommer</td>
              <td className="grammar__table-data">spielen</td>
              <td className="grammar__table-data">wir Fußball.</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">
                Johanna, Laura und Angelina
              </td>
              <td className="grammar__table-data">gehen</td>
              <td className="grammar__table-data">in die Schule.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function VerbToBeTopic() {
  return (
    <div>
      <h2 className="grammar__topic-name">Sloveso werden</h2>
      <div className="grammar__content-wrapper">
        <p className="grammar__text">
          Sloveso <b>werden</b> dokáže řádně potrápit studenty od začátečníků po
          pokročilé. Podobně jako třeba <b>sein</b> či <b>haben</b> patří totiž
          mezi slovesa, která mají jednak významovou a jednak pomocnou funkci.
          Ukážeme si to na několika příkladech.
        </p>
        <div className="grammar__inner-section">
          <p className="grammar__text">
            Ich <b>habe</b> ein Auto.
          </p>
          <p className="grammar__text">
            - sloveso <b>haben</b> je významové, znamená “mít, vlastnit”
          </p>
          <p className="grammar__text">
            Ich <b>habe</b> ein Auto gekauft.
          </p>
          <p className="grammar__text">
            - sloveso <b>haben</b> je pomocné, vytváří perfektum
          </p>
        </div>
        <div className="grammar__inner-section">
          <p className="grammar__text">
            Er <b>ist</b> zu Hause.
          </p>
          <p className="grammar__text">
            - sloveso <b>sein</b> je významové, znamená “být”
          </p>
          <p className="grammar__text">
            Er <b>ist</b> nach Hause gekommen.
          </p>
          <p className="grammar__text">
            - sloveso <b>sein</b> je pomocné, vytváří perfektum
          </p>
        </div>
        <p className="grammar__text">
          Sloveso <b>werden</b> má významů dokonce ještě víc. Pojďme se na ně
          podívat.
        </p>
        <div className="grammar__inner-section">
          <p className="grammar__text">Trpný rod</p>
          <p className="grammar__text">
            V kombinaci s <b>příčestím minulým</b> vytváří <b>werden</b> trpný
            rod.
          </p>
        </div>
        <div className="grammar__inner-section">
          <p className="grammar__text">
            Alles <b>wurde</b> verkauft.
          </p>
          <p className="grammar__text">
            Was versprochen ist, muss gehalten <b>werden</b>.
          </p>
          <p className="grammar__text">
            Wann <b>wird</b> es bekannt gemacht?
          </p>
        </div>
        <p className="grammar__text">
          Více se dočtete v našem článku <b>Trpný rod (Das Passiv).</b>
        </p>
        <div className="grammar__inner-section">
          <p className="grammar__text">Budoucí čas</p>
          <p className="grammar__text">
            V kombinaci s <b>infinitivem</b> tvoří <b>werden</b> obyčejný
            budoucí čas.
          </p>
        </div>
        <div className="grammar__inner-section">
          <p className="grammar__text">
            Ich <b>werde</b> dir helfen.
          </p>
          <p className="grammar__text">
            Es <b>wird</b> regnen.
          </p>
          <p className="grammar__text">
            Sie <b>wird</b> nächste Woche auf Urlaub sein.
          </p>
        </div>
      </div>
    </div>
  );
}

function StrongVerbsTopic() {
  return (
    <div>
      <h2 className="grammar__topic-name">Slovosled v německé větě</h2>
      <div className="grammar__content-wrapper">
        <table className="grammar__table">
          <thead className="grammar__table-head">
            <tr className="grammar__table-row">
              <th className="grammar__table-header">Infinitiv </th>
              <th className="grammar__table-header">Preklad</th>
              <th className="grammar__table-header">Préteritum</th>
              <th className="grammar__table-header">Perfektum</th>
            </tr>
          </thead>
          <tbody className="grammar__table-body">
            <tr className="grammar__table-row">
              <td className="grammar__table-data">backen</td>
              <td className="grammar__table-data">piecť</td>
              <td className="grammar__table-data">ich backte/buk </td>
              <td className="grammar__table-data">ich h gebacken</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">beginnen</td>
              <td className="grammar__table-data">začať</td>
              <td className="grammar__table-data">ich begann</td>
              <td className="grammar__table-data">ich h begonnen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">bitten</td>
              <td className="grammar__table-data">prosiť</td>
              <td className="grammar__table-data">ich bat</td>
              <td className="grammar__table-data">ich h gebeten</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">braten</td>
              <td className="grammar__table-data">piecť, smažiť</td>
              <td className="grammar__table-data">ich briet</td>
              <td className="grammar__table-data">ich h gebraten</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">dringen</td>
              <td className="grammar__table-data">vniknúť, naliehať</td>
              <td className="grammar__table-data">ich drang</td>
              <td className="grammar__table-data">ich b/h gedrungen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">empfehlen</td>
              <td className="grammar__table-data">odporučiť</td>
              <td className="grammar__table-data">ich empfahl</td>
              <td className="grammar__table-data">ich h empfohlen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">essen</td>
              <td className="grammar__table-data">jesť</td>
              <td className="grammar__table-data">ich aß</td>
              <td className="grammar__table-data">ich h gegessen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">finden</td>
              <td className="grammar__table-data">nájsť</td>
              <td className="grammar__table-data">ich fand</td>
              <td className="grammar__table-data">ich h gefunden</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">fliegen</td>
              <td className="grammar__table-data">letieť</td>
              <td className="grammar__table-data">ich flog</td>
              <td className="grammar__table-data">ich b/h geflogen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">geben</td>
              <td className="grammar__table-data">dať</td>
              <td className="grammar__table-data">ich gab</td>
              <td className="grammar__table-data">ich h gegeben</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">gehen</td>
              <td className="grammar__table-data">ísť</td>
              <td className="grammar__table-data">ich ging</td>
              <td className="grammar__table-data">ich b gegangen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">genießen</td>
              <td className="grammar__table-data">požívať</td>
              <td className="grammar__table-data">ich genoss</td>
              <td className="grammar__table-data">ich h genossen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">halten</td>
              <td className="grammar__table-data">držať</td>
              <td className="grammar__table-data">ich hielt</td>
              <td className="grammar__table-data">ich h gehalten</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">heißen</td>
              <td className="grammar__table-data">volať sa</td>
              <td className="grammar__table-data">ich hieß</td>
              <td className="grammar__table-data">ich h geheißen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">kommen</td>
              <td className="grammar__table-data">prísť</td>
              <td className="grammar__table-data">ich kam</td>
              <td className="grammar__table-data">ich b gekommen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">lesen</td>
              <td className="grammar__table-data">čítať</td>
              <td className="grammar__table-data">ich las</td>
              <td className="grammar__table-data">ich h gelesen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">nehmen</td>
              <td className="grammar__table-data">vziať</td>
              <td className="grammar__table-data">ich nahm</td>
              <td className="grammar__table-data">ich h genommen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">ringen</td>
              <td className="grammar__table-data">zápasiť</td>
              <td className="grammar__table-data">ich rang</td>
              <td className="grammar__table-data">ich h gerungen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">schlafen</td>
              <td className="grammar__table-data">spať</td>
              <td className="grammar__table-data">ich schlief</td>
              <td className="grammar__table-data">ich h geschlafen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">schneiden</td>
              <td className="grammar__table-data">rezať</td>
              <td className="grammar__table-data">ich schnitt</td>
              <td className="grammar__table-data">ich h geschnitten</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">sehen</td>
              <td className="grammar__table-data">vidieť</td>
              <td className="grammar__table-data">ich sah</td>
              <td className="grammar__table-data">ich h gesehen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">stehen</td>
              <td className="grammar__table-data">stáť</td>
              <td className="grammar__table-data">ich stand</td>
              <td className="grammar__table-data">ich h gestanden</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">trinken</td>
              <td className="grammar__table-data">piť</td>
              <td className="grammar__table-data">ich trank</td>
              <td className="grammar__table-data">ich h getrunken</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">waschen</td>
              <td className="grammar__table-data">myť</td>
              <td className="grammar__table-data">ich wusch</td>
              <td className="grammar__table-data">ich h gewaschen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">werfen</td>
              <td className="grammar__table-data">hodiť</td>
              <td className="grammar__table-data">ich warf</td>
              <td className="grammar__table-data">ich h geworfen</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">ziehen</td>
              <td className="grammar__table-data">ťahať</td>
              <td className="grammar__table-data">ich zog</td>
              <td className="grammar__table-data">ich h/b gezogen</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function PronounsDeclensionTopic() {
  return (
    <div>
      <h2 className="grammar__topic-name">Skloňovanie zámen v nemčine</h2>
      <div className="grammar__content-wrapper">
        <p className="grammar__text">
          Mysleli ste si, že sedem slovenských pádov je nezmysel, ktorý sme si
          vymysleli pod Tatrami? Omyl! Nemčina rozlišuje „iba” štyri pády.
          Pozrite, ako sa v Nominativ, Genitiv, Dativ a Akkusativ skloňujú
          privlastňovacie zámená a aké koncovky sa pridávajú na konci každého
          slova.
        </p>
        <table className="grammar__table">
          <thead className="grammar__table-head">
            <tr className="grammar__table-row">
              <th className="grammar__table-header">Pád</th>
              <th className="grammar__table-header">Maskulin (mužský rod)</th>
              <th className="grammar__table-header">Feminina (ženský rod)</th>
              <th className="grammar__table-header">Neutrum (stredný rod)</th>
              <th className="grammar__table-header">Plural (množné číslo)</th>
            </tr>
          </thead>
          <tbody className="grammar__table-body">
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Nominativ</td>
              <td className="grammar__table-data">-</td>
              <td className="grammar__table-data">-e</td>
              <td className="grammar__table-data">-</td>
              <td className="grammar__table-data">-e</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Genitiv</td>
              <td className="grammar__table-data">-es</td>
              <td className="grammar__table-data">-er</td>
              <td className="grammar__table-data">-es</td>
              <td className="grammar__table-data">-er</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Dativ</td>
              <td className="grammar__table-data">-em</td>
              <td className="grammar__table-data">-er</td>
              <td className="grammar__table-data">-em</td>
              <td className="grammar__table-data">-en</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Akkusativ</td>
              <td className="grammar__table-data">-en</td>
              <td className="grammar__table-data">-e</td>
              <td className="grammar__table-data">-</td>
              <td className="grammar__table-data">-e</td>
            </tr>
          </tbody>
        </table>
        <p className="grammar__text">
          Na príklade si poďme si vyskloňovať slovíčka: stôl, lampa, posteľ (v
          nemčine má stredný rod), stoličky.
        </p>
        <table className="grammar__table">
          <thead className="grammar__table-head">
            <tr className="grammar__table-row">
              <th className="grammar__table-header">Pád</th>
              <th className="grammar__table-header">Maskulin (mužský rod)</th>
              <th className="grammar__table-header">Feminina (ženský rod)</th>
              <th className="grammar__table-header">Neutrum (stredný rod)</th>
              <th className="grammar__table-header">Plural (množné číslo)</th>
            </tr>
          </thead>
          <tbody className="grammar__table-body">
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Nominativ</td>
              <td className="grammar__table-data">mein Tisch</td>
              <td className="grammar__table-data">meine Lampe</td>
              <td className="grammar__table-data">mein Bett</td>
              <td className="grammar__table-data">meine Stühle</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Genitiv</td>
              <td className="grammar__table-data">meines Tisch</td>
              <td className="grammar__table-data">meiner Lampe</td>
              <td className="grammar__table-data">meines Bett</td>
              <td className="grammar__table-data">meiner Stühle</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Dativ</td>
              <td className="grammar__table-data">meinem Tisch</td>
              <td className="grammar__table-data">meiner Lampe</td>
              <td className="grammar__table-data">meinem Bett</td>
              <td className="grammar__table-data">meinen Stühle</td>
            </tr>
            <tr className="grammar__table-row">
              <td className="grammar__table-data">Akkusativ</td>
              <td className="grammar__table-data">meinen Tisch</td>
              <td className="grammar__table-data">meine Lampe</td>
              <td className="grammar__table-data">mein Bett</td>
              <td className="grammar__table-data">meine Stühle</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function VerbTenseTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">
        Časovanie slabých a silných slovies v prítomnom čase.
      </h2>
      <p className="grammar__text">
        V nemčine používame prítomný čas na vyjadrenie dejov, ktoré práve
        prebiehajú, dejov, ktoré sa začali v minulosti a ešte stále prebiehajú,
        budúceho času, ak ide o blízku budúcnosť (spravidla s použitím časového
        údaju), opakujúcich sa dejov a dejov všeobecného významu.
      </p>
      <p className="grammar__text">
        Pri časovaní slovies v nemčine, podobne ako v slovenčine pridávame ku
        kmeňu slova osobné koncovky. Prvá osoba je osoba hovoriaca, druhá osoba
        je oslovená a tretia je osoba alebo vec, o ktorej hovoríme. Pre
        zdvorilostné oslovenie používa nemčina osobné zámeno 3. osoby množného
        čísla.
      </p>

      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {verbTenseSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={currentSubtopic === verbTenseSubtopicArray.length - 1}
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function PersonalPronounsDeclensionTopic() {
  return (
    <div>
      <h2 className="grammar__topic-name">Skloňovanie osobných zámen</h2>
      <div className="grammar__content-wrapper">
        <p className="grammar__text">
          Zámená sú pomerne početnou a obsahovo bohatou časťou slovnej zásoby.
          Zámená môžu zastupovať alebo sprevádzať podstatné a prídavné mená,
          príslovky alebo celé obsahové celky.
        </p>
        <p className="grammar__text">Rozlišujeme niekoľko skupín zámen:</p>
        <p className="grammar__text">
          Osobné zámená : ich, du, er, sie, es, wir, ihr, sie/ Sie
        </p>
        <p className="grammar__text">
          Privlastňovacie zámená: mein, dein, sein, ihr, sein, unser, euer, ihr
          / Ihr
        </p>
        <p className="grammar__text">
          Opytovacie zámená: wer, was, wie, wo, wohin, wessen, welcher, was fűr
          ein,
        </p>
        <p className="grammar__text">
          Ukazovacie zmámená: der, dieser, jener, derjenige, derselbe, der
          gleiche, solcher, solch ein, ...
        </p>
        <p className="grammar__text">
          Vzťažné zámená: der, dessen, dem, den, die, deren, das, denen, wer,
          welcher,
        </p>
        <p className="grammar__text">
          Neurčité zámená: jemand, niemand, etwas, nichts, jeder, mancher,
          einer, keiner, man, es, sämtlich, einige, wenig, viel, alles, mehr,
        </p>
        <p className="grammar__text">Zvratné zámeno: sich</p>
        <p className="grammar__text">
          Recipročné zámeno: einander (spája sa s predložkami – voneinander,
          miteinander, ...)
        </p>
        <p className="grammar__text">
          Zámenné príslovky: wo/ da + predložky (používajú sa pri predložkových
          väzbách slovies, prídavných a podstatných mien, napr. Angst vor etwas
          haben: Wovor hast du Angst? – čoho sa bojíš?; Ich habe Angst davor. –
          Mám z toho strach.)
        </p>
        <p className="grammar__text">
          Tvary osobných zámen v genitíve sa používajú ako privlastňovacie
          zámená, preto ich v tabuľke nebudem uvádzať. Privlastňovacie zámená sú
          uvedené v úvodnom rozdelení a majú tvary pre všetky tri rody (mein,
          meine, mein, ...).
        </p>
        <div className="grammar__inner-section">
          <p className="grammar__text">
            <b>Jednotné číslo</b>
          </p>
          <table className="grammar__table">
            <thead className="grammar__table-head">
              <tr className="grammar__table-row">
                <th className="grammar__table-header">1. pád</th>
                <th className="grammar__table-header">3. pád</th>
                <th className="grammar__table-header">4. pád</th>
              </tr>
            </thead>
            <tbody className="grammar__table-body">
              <tr className="grammar__table-row">
                <td className="grammar__table-data">ich (ja)</td>
                <td className="grammar__table-data">mir (mi/ mne)</td>
                <td className="grammar__table-data">mich (ma/ mňa)</td>
              </tr>
              <tr className="grammar__table-row">
                <td className="grammar__table-data">du (ty)</td>
                <td className="grammar__table-data">dir ( ti/tebe)</td>
                <td className="grammar__table-data">dich (ťa/ teba)</td>
              </tr>
              <tr className="grammar__table-row">
                <td className="grammar__table-data">er (on)</td>
                <td className="grammar__table-data">ihm ( mu/ jemu)</td>
                <td className="grammar__table-data">ihn (ho/ jeho)</td>
              </tr>
              <tr className="grammar__table-row">
                <td className="grammar__table-data">sie (ona)</td>
                <td className="grammar__table-data">ihr ( jej)</td>
                <td className="grammar__table-data">sie (ju)</td>
              </tr>
              <tr className="grammar__table-row">
                <td className="grammar__table-data">es (ono)</td>
                <td className="grammar__table-data">ihm ( mu/ jemu)</td>
                <td className="grammar__table-data">es ( ho/ jeho)</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="grammar__inner-section">
          <p className="grammar__text">
            <b>Množné číslo</b>
          </p>
          <table className="grammar__table">
            <thead className="grammar__table-head">
              <tr className="grammar__table-row">
                <th className="grammar__table-header">1. pád</th>
                <th className="grammar__table-header">3. pád</th>
                <th className="grammar__table-header">4. pád</th>
              </tr>
            </thead>
            <tbody className="grammar__table-body">
              <tr className="grammar__table-row">
                <td className="grammar__table-data">wir ( my)</td>
                <td className="grammar__table-data">uns (nám)</td>
                <td className="grammar__table-data">uns (nás)</td>
              </tr>
              <tr className="grammar__table-row">
                <td className="grammar__table-data">ihr (vy)</td>
                <td className="grammar__table-data">euch (vám)</td>
                <td className="grammar__table-data">euch (vás)</td>
              </tr>
              <tr className="grammar__table-row">
                <td className="grammar__table-data">sie (oni, ony)</td>
                <td className="grammar__table-data">ihnen (im)</td>
                <td className="grammar__table-data">sie (ich)</td>
              </tr>
              <tr className="grammar__table-row">
                <td className="grammar__table-data">Sie (Vy)</td>
                <td className="grammar__table-data">Ihnen (Vám)</td>
                <td className="grammar__table-data">Sie (Vás)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function GermanGrammarSK(props) {
  switch (props.choosenTopic) {
    case "wordsOrderTopic":
      return <WordsOrderTopic />;
    case "verbToBeTopic":
      return <VerbToBeTopic />;
    case "strongVerbsTopic":
      return <StrongVerbsTopic />;
    case "pronounsDeclensionTopic":
      return <PronounsDeclensionTopic />;
    case "verbTenseTopic":
      return <VerbTenseTopic />;
    case "personalPronounsDeclensionTopic":
      return <PersonalPronounsDeclensionTopic />;
    // case "adjectivesTopic":
    //   return <AdjectivesTopic />;

    default:
      return (
        <div className="grammar">
          <h1 className="grammar__title">Gramatika</h1>
          <div className="grammar__topics-wrapper">
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("wordsOrderTopic")}
            >
              Slovosled v německé větě
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("verbToBeTopic")}
            >
              Sloveso werden
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("strongVerbsTopic")}
            >
              Prehľad silných slovies a ich tvary
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("pronounsDeclensionTopic")}
            >
              Skloňovanie zámen v nemčine
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("verbTenseTopic")}
            >
              Časovanie slabých a silných slovies v prítomnom čase.
            </button>
            <button
              className="grammar__topic-button"
              onClick={() =>
                props.setChoosenTopic("personalPronounsDeclensionTopic")
              }
            >
              Skloňovanie osobných zámen
            </button>
          </div>
        </div>
      );
  }
}

export default GermanGrammarSK;
