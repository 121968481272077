import { useState } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../GrammarModal.css";

const timesSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">
      Present Simple (Přítomný čas prostý)
    </h3>
    <p className="grammar__text">Jak vytvořit čas:</p>
    <p className="grammar__text">
      Přítomný čas prostý pracuje s pomocným slovesem do a základním tvarem
      významového slovesa.
    </p>

    <ul className="grammar__list">
      <li className="grammar__list-element">kladná věta</li>
    </ul>
    <p className="grammar__text">podmět + významové sloveso + zbytek věty</p>
    <ul className="grammar__list">
      <li className="grammar__list-element">záporná věta</li>
    </ul>
    <p className="grammar__text">
      podmět + pomocné sloveso do + zápor not + významové sloveso + zbytek věty
    </p>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">I</p>
            <p className="grammar__table-text-center">We</p>
            <p className="grammar__table-text-center">You</p>
            <p className="grammar__table-text-center">They</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">live</p>
            <p className="grammar__table-text-center">don't live</p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            near here.
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            Já (ty, my atd.) bydlím / nebydlím blízko.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">He</p>
            <p className="grammar__table-text-center">She</p>
            <p className="grammar__table-text-center">It</p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">lives</p>
            <p className="grammar__table-text-center">doesn't live</p>
          </td>
        </tr>
      </tbody>
    </table>

    <ul className="grammar__list">
      <li className="grammar__list-element">otázka</li>
    </ul>
    <p className="grammar__text">
      otázka se v přítomném čase tvoří přehozením podmětu a přísudku
    </p>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data" rowSpan={2}>
            Where
          </td>
          <td className="grammar__table-data">do</td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">I</p>
            <p className="grammar__table-text-center">we</p>
            <p className="grammar__table-text-center">you</p>
            <p className="grammar__table-text-center">they</p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            live?
          </td>
          <td className="grammar__table-data">
            Kde (já, ty, on, oni atd.) bydlíš?
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">does</td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">he</p>
            <p className="grammar__table-text-center">she</p>
            <p className="grammar__table-text-center">it</p>
          </td>
        </tr>
      </tbody>
    </table>

    <ul className="grammar__list">
      <li className="grammar__list-element">krátká odpověď</li>
    </ul>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Do you like Peter? Yes, I do.</td>
          <td className="grammar__table-data">Máš ráda Petra? Ano, mám.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            Does she speak French? No, she doesn't.
          </td>
          <td className="grammar__table-data">
            Mluví francouzsky? Ne, nemluví.
          </td>
        </tr>
      </tbody>
    </table>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">
      Present continuous (Přítomný čas průběhový)
    </h3>
    <p className="grammar__text">Jak vytvořit čas</p>

    <ul className="grammar__list">
      <li className="grammar__list-element">kladná věta</li>
    </ul>
    <p className="grammar__text">
      podmět + am / is / are (stažené tvary) + sloveso s koncovkou -ing (present
      participle)
    </p>
    <ul className="grammar__list">
      <li className="grammar__list-element">záporná věta</li>
    </ul>
    <p className="grammar__text">
      podmět + am / is / are (stažené tvary) + NOT + sloveso s koncovkou -ing
      (present participle)
    </p>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">I</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">'m (am)</p>
            <p className="grammar__table-text-center">'m not</p>
          </td>
          <td className="grammar__table-data" rowSpan={3}>
            working.
          </td>
          <td className="grammar__table-data" rowSpan={3}>
            Já (ty, on, oni atd.) pracuji / nepracuji.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">He</p>
            <p className="grammar__table-text-center">She</p>
            <p className="grammar__table-text-center">It</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">'s (is)</p>
            <p className="grammar__table-text-center">isn't</p>
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">We</p>
            <p className="grammar__table-text-center">You</p>
            <p className="grammar__table-text-center">They</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">'re (are)</p>
            <p className="grammar__table-text-center">aren't</p>
          </td>
        </tr>
      </tbody>
    </table>

    <ul className="grammar__list">
      <li className="grammar__list-element">otázka</li>
    </ul>
    <p className="grammar__text">
      otázka se v přítomném čase tvoří přehozením podmětu a přísudku
    </p>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data" rowSpan={3}>
            <p className="grammar__table-text-center">What</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">am</p>
          </td>
          <td className="grammar__table-data" rowSpan={3}>
            <p className="grammar__table-text-center">I</p>
            <p className="grammar__table-text-center">he</p>
            <p className="grammar__table-text-center">she</p>
            <p className="grammar__table-text-center">it</p>
            <p className="grammar__table-text-center">we</p>
            <p className="grammar__table-text-center">you</p>
            <p className="grammar__table-text-center">they</p>
          </td>
          <td className="grammar__table-data" rowSpan={3}>
            <p className="grammar__table-text-center">wearing?</p>
          </td>
          <td className="grammar__table-data" rowSpan={3}>
            <p className="grammar__table-text-center">
              Co mám (já, ty, on, ona, oni atd.) na sobě?
            </p>
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">is</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">are</td>
        </tr>
      </tbody>
    </table>

    <ul className="grammar__list">
      <li className="grammar__list-element">krátká odpověď</li>
    </ul>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Are you going?</b>
            </p>
            <p className="grammar__table-text">Jdeš?</p>
          </td>

          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Yes, I am. / No, I'm not.</b>
            </p>
            <p className="grammar__table-text">Ano, jdu. / Ne, nejdu.</p>
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Is Anna working?</b>
            </p>
            <p className="grammar__table-text">Pracuje Anna?</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Yes, she is. / No, she isn't.</b>
            </p>
            <p className="grammar__table-text">
              Ano, pracuje. / Ne, nepracuje.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">
      Present perfect (Předpřítomný čas prostý)
    </h3>
    <p className="grammar__text">Jak vytvořit čas:</p>

    <ul className="grammar__list">
      <li className="grammar__list-element">kladná věta</li>
    </ul>
    <p className="grammar__text">
      podmět + have / has + sloveso s koncovkou -ed (past participle)
    </p>
    <ul className="grammar__list">
      <li className="grammar__list-element">záporná věta</li>
    </ul>
    <p className="grammar__text">
      podmět + have / has + zápor not + sloveso s koncovkou -ed (past
      participle)
    </p>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">I</p>
            <p className="grammar__table-text-center">We/You/They</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">'ve (have)</p>
            <p className="grammar__table-text-center">haven't</p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">worked in factory.</p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">
              Pracoval / nepracoval jsem (já, my, vy, on, ona atd.) v továrně.
              (a dosud pracuji / nepracuji)
            </p>
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">He</p>
            <p className="grammar__table-text-center">She</p>
            <p className="grammar__table-text-center">It</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">'s (has)</p>
            <p className="grammar__table-text-center">hasn't</p>
          </td>
        </tr>
      </tbody>
    </table>

    <ul className="grammar__list">
      <li className="grammar__list-element">otázka</li>
    </ul>
    <p className="grammar__text">
      otázka se v přítomném čase tvoří přehozením podmětu a přísudku
    </p>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">Have</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">I</p>
            <p className="grammar__table-text-center">we/you/they</p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">
              been to the United States?
            </p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">
              Byl jsem (já, my, vy, on, ona, oni atd.) v USA?
            </p>
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">Has</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">he/she/it</p>
          </td>
        </tr>
      </tbody>
    </table>

    <ul className="grammar__list">
      <li className="grammar__list-element">krátká odpověď</li>
    </ul>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Have you ever been to Egypt?</b>
            </p>
            <p className="grammar__table-text">Byl jsi někdy v Egyptě?</p>
          </td>

          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Yes, I have. / No, I haven't.</b>
            </p>
            <p className="grammar__table-text">Ano, byl. / Ne, nebyl.</p>
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Has she ever written poetry?</b>
            </p>
            <p className="grammar__table-text">Psala někdy básně?</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Yes, she has. / No, she hasn't.</b>
            </p>
            <p className="grammar__table-text">Ano, psala. / Ne, nepsala.</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">
      Present perfect continuous (Předpřítomný čas průběhový)
    </h3>
    <p className="grammar__text">Jak vytvořit čas:</p>

    <ul className="grammar__list">
      <li className="grammar__list-element">kladná věta</li>
    </ul>
    <p className="grammar__text">
      podmět + have / has + been + sloveso s koncovkou -ing (present participle)
    </p>
    <ul className="grammar__list">
      <li className="grammar__list-element">záporná věta</li>
    </ul>
    <p className="grammar__text">
      podmět + have / has + zápor not + been + sloveso s koncovkou -ing (present
      participle)
    </p>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">I</p>
            <p className="grammar__table-text-center">We</p>
            <p className="grammar__table-text-center">You</p>
            <p className="grammar__table-text-center">They</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">'ve (have)</p>
            <p className="grammar__table-text-center">haven't</p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">been working</p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">
              Já (ty, on, oni atd.) jsem pracoval / nepracoval.
            </p>
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">He</p>
            <p className="grammar__table-text-center">She</p>
            <p className="grammar__table-text-center">It</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">'s (has)</p>
            <p className="grammar__table-text-center">hasn't</p>
          </td>
        </tr>
      </tbody>
    </table>

    <ul className="grammar__list">
      <li className="grammar__list-element">otázka</li>
    </ul>
    <p className="grammar__text">
      otázka se v přítomném čase tvoří přehozením podmětu a přísudku
    </p>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">How long</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">have</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">I</p>
            <p className="grammar__table-text-center">we</p>
            <p className="grammar__table-text-center">you</p>
            <p className="grammar__table-text-center">they</p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">been working?</p>
          </td>
          <td className="grammar__table-data" rowSpan={2}>
            <p className="grammar__table-text-center">
              Jak dlouho (já, ty, on, oni atd.) pracuji?
            </p>
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">has</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text-center">he</p>
            <p className="grammar__table-text-center">she</p>
            <p className="grammar__table-text-center">it</p>
          </td>
        </tr>
      </tbody>
    </table>

    <ul className="grammar__list">
      <li className="grammar__list-element">krátká odpověď</li>
    </ul>
    <table className="grammar__table">
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Have you been running?</b>
            </p>
            <p className="grammar__table-text">Běháš?</p>
          </td>

          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Yes, I have. / No, I haven't.</b>
            </p>
            <p className="grammar__table-text">Ano, běhám. / Ne, neběhám.</p>
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Has she been shopping?</b>
            </p>
            <p className="grammar__table-text">Nakupuje?</p>
          </td>
          <td className="grammar__table-data">
            <p className="grammar__table-text">
              <b>Yes, she has. / No, she hasn't</b>
            </p>
            <p className="grammar__table-text">
              Ano, nakupuje. / Ne, nenakupuje.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>,
];

const personalPronounsAndVerbToBeSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Věty oznamovací</h3>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Anglický text</th>
          <th className="grammar__table-header">Český překlad</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">I am...</td>
          <td className="grammar__table-data">Já jsem...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">I am a white cat.</td>
          <td className="grammar__table-data">Já jsem bílá kočka.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">You are...</td>
          <td className="grammar__table-data">Ty jsi...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">You are a small black spider.</td>
          <td className="grammar__table-data">Ty jsi malý černý pavouk.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">He is...</td>
          <td className="grammar__table-data">On je...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">He is eight years old.</td>
          <td className="grammar__table-data">Je mu osm let.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">She is...</td>
          <td className="grammar__table-data">Ona je...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">She is my sister.</td>
          <td className="grammar__table-data">Ona je moje sestra.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">It is...</td>
          <td className="grammar__table-data">Ono je...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">It is a PlayStation.</td>
          <td className="grammar__table-data">To je PlayStation.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">We are...</td>
          <td className="grammar__table-data">My jsme...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">We are children at school.</td>
          <td className="grammar__table-data">My jsme děti ve škole.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">You are...</td>
          <td className="grammar__table-data">Vy jste...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">You are classmates.</td>
          <td className="grammar__table-data">Vy jste spolužáci.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">They are...</td>
          <td className="grammar__table-data">Oni jsou...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">They are a large family.</td>
          <td className="grammar__table-data">Oni jsou velká rodina.</td>
        </tr>
      </tbody>
    </table>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Věty tázací</h3>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Anglický text</th>
          <th className="grammar__table-header">Český překlad</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Am I ...</td>
          <td className="grammar__table-data">Jsem já...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Am I a white cat?</td>
          <td className="grammar__table-data">Jsem bílá kočka?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Are you...</td>
          <td className="grammar__table-data">Jsi ty...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Are you a small black spider?</td>
          <td className="grammar__table-data">Jsi malý černý pavouk?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Is he...</td>
          <td className="grammar__table-data">Je on...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Is he eight years old?</td>
          <td className="grammar__table-data">Je mu osm let?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Is she...</td>
          <td className="grammar__table-data">Je ona...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Is she my sister?</td>
          <td className="grammar__table-data">Je moje sestra?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Is it...</td>
          <td className="grammar__table-data">Je ono/ to...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Is it a PlayStation?</td>
          <td className="grammar__table-data">Je to PlayStation?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Are we...</td>
          <td className="grammar__table-data">Jsme my...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Are we children at school?</td>
          <td className="grammar__table-data">Jsme děti ve škole?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Are you...</td>
          <td className="grammar__table-data">Jste vy...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Are you classmates?</td>
          <td className="grammar__table-data">Jste spolužáci?</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Are they...</td>
          <td className="grammar__table-data">Jsou oni...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">Are they a large family?</td>
          <td className="grammar__table-data">Jsou velká rodina?</td>
        </tr>
      </tbody>
    </table>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Věty tázací</h3>
    <table className="grammar__table">
      <thead className="grammar__table-head">
        <tr className="grammar__table-row">
          <th className="grammar__table-header">Anglický text</th>
          <th className="grammar__table-header">Český překlad</th>
        </tr>
      </thead>
      <tbody className="grammar__table-body">
        <tr className="grammar__table-row">
          <td className="grammar__table-data">I am not...</td>
          <td className="grammar__table-data">Já nejsem...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">I am not a white cat.</td>
          <td className="grammar__table-data">Já nejsem bílá kočka.</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">You are not...</td>
          <td className="grammar__table-data">Ty nejsi...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            You are not a black spider. You are a brown spider.
          </td>
          <td className="grammar__table-data">
            Ty nejsi černý pavouk. Ty jsi hnědý pavouk.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">He is not...</td>
          <td className="grammar__table-data">On není...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            He is not eight years old. He is older.
          </td>
          <td className="grammar__table-data">
            Není mu osm let. On je starší.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">She is not...</td>
          <td className="grammar__table-data">Ona není...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            She is not my sister. She is my cousin.
          </td>
          <td className="grammar__table-data">
            Ona není moje sestra. Ona je moje sestřenice.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">It is not...</td>
          <td className="grammar__table-data">Ono není...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            It is not a PlayStation. It is a notebook.
          </td>
          <td className="grammar__table-data">
            To není PlayStation. To je notebook.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">We are not...</td>
          <td className="grammar__table-data">My nejsme...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            We are not children at school. We are children in kindergarten.
          </td>
          <td className="grammar__table-data">
            My nejsme děti ve škole. My jsme děti ve školce.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">You are not...</td>
          <td className="grammar__table-data">Vy nejste...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            You are not classmates. You are neighbours.
          </td>
          <td className="grammar__table-data">
            Vy nejste spolužáci. Vy jste sousedé.
          </td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">They are not...</td>
          <td className="grammar__table-data">Oni nejsou...</td>
        </tr>
        <tr className="grammar__table-row">
          <td className="grammar__table-data">
            They are not a large family – only mum, dad and one baby boy.
          </td>
          <td className="grammar__table-data">
            Oni nejsou velká rodina - pouze mamka, taťka a jedno miminko
            (chlapeček).
          </td>
        </tr>
      </tbody>
    </table>
  </div>,
];

const articlesSubtopicArray = [
  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Neurčitý člen</h3>
    <p className="grammar__text">
      Nejprve se zaměříme na neurčité členy. Mají dva tvary, a to:
    </p>
    <p className="grammar__text">a/'ə/</p>
    <p className="grammar__text">an/'ən/</p>
    <ul className="grammar__list">
      <li className="grammar__list-element">
        Neurčitý člen a používáme před vyslovovanou souhláskou:
        <p className="grammar__text">
          a dog, a house, a union (zde se sice píše samohláska, ale čte se
          souhláska /j/)
        </p>
      </li>
      <li className="grammar__list-element">
        Neurčitý člen an se používá před vyslovovanou samohláskou:
        <p className="grammar__text">
          an engineer, an actor, an hour (zde se sice píše souhláska, ale
          vyslovuje se samohláska /a/)
        </p>
      </li>
    </ul>
    <p className="grammar__text">
      Neurčitý člen používáme především v těchto třech případech:
    </p>
    <ol className="grammar__ordered-list">
      <li className="grammar__ordered-list-element">
        <b>Nová informace</b>
        <p className="grammar__text">
          I bought a new car. – Koupil jsem si nové auto – je to pro něho nová
          informace, ještě neví jaké jsem si koupil.
        </p>
      </li>
      <li className="grammar__ordered-list-element">
        <b>Co je kdo zač</b>
        <p className="grammar__text">
          He's a teacher. – Je učitel – říkáme, co je zač, jaké má zaměstnání.
        </p>
      </li>
      <li className="grammar__ordered-list-element">
        <b>Množství, frekvence, apod.</b>
        <p className="grammar__text">
          He bought an apple and two bananas. – jedno jablko a dva banány
        </p>
      </li>
    </ol>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Určitý člen</h3>
    <p className="grammar__text">
      Určitý člen má také dvě varianty, ty se ale odlišují pouze ve výslovnosti:
    </p>
    <p className="grammar__text">the/'ðə/ (čteme krátce s /ə/)</p>

    <p className="grammar__text">the /ði:/ (čteme dlouze s /i:/)</p>
    <p className="grammar__text">
      První varianta, podobně jako tomu bylo u neurčitého členu, se používá před
      vyslovovanou souhláskou (the dog, the house, the union) a druhá před
      vyslovovanou samohláskou (the engineer, the actor, the hour).
    </p>
    <p className="grammar__text">
      Tento člen se používá v následujících případech:
    </p>

    <ol className="grammar__ordered-list">
      <li className="grammar__ordered-list-element">
        <b>Známá věc</b>
        <p className="grammar__text">
          He lives in a house in the city centre and the house is very old. –
          ten dům, který byl zmíněn v první části věty
        </p>
      </li>
      <li className="grammar__ordered-list-element">
        <b>Jediný exemplář</b>
        <p className="grammar__text">
          The sun is shining. – Slunce je jenom jedno.
        </p>
      </li>
      <li className="grammar__ordered-list-element">
        <b>Státy, pohoří, řeky</b>
        <p className="grammar__text">
          the Czech Republic, the Soviet Union, the United States of America
          atd.
        </p>
      </li>
    </ol>
  </div>,

  <div className="grammar__subtopic">
    <h3 className="grammar__subtopic-name">Nulový člen</h3>
    <p className="grammar__text">
      Nulový člen se nepíše, nemá žádný tvar. Přesto je důležité vědět, kdy ho
      je nutné použít. Pojem ‘vynechat člen’ není přesný. Členy se vynechávají
      např. v nadpisech, v novinových titulcích apod. Nulový člen je však něco
      jiného. Používá se následovně:
    </p>

    <ol className="grammar__ordered-list">
      <li className="grammar__ordered-list-element">
        <b>Mluvíme pouze obecně</b>
        <p className="grammar__text">
          Water is wet. – Voda je mokrá, mluvím o vodě obecně. (ale: THE water
          is cold today – dnes je voda studená – myslím tím asi vodu v jezeře či
          v bazénu.)
        </p>
      </li>
      <li className="grammar__ordered-list-element">
        <b>Ustálené předložkové vazby</b>
        <p className="grammar__text">
          in bed, in hospital, in prison, in church – zde spíše říkáme, co tam
          člověk dělá, nejde jen o místo, kde je – spí, je v nemocnici
          (zraněný), je v base (jako vězeň), je v kostele a modlí se
        </p>
      </li>
      <li className="grammar__ordered-list-element">
        <b>Státy, města, ulice</b>
        <p className="grammar__text">Germany, Italy, France, Spain atd.</p>
      </li>
    </ol>
  </div>,
];

function TimesTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Najpoužívanejšie časy</h2>
      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {timesSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={currentSubtopic === timesSubtopicArray.length - 1}
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function PersonalPronounsAndVerbToBeTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Osobní zájmena a sloveso být</h2>
      <div className="grammar__content-wrapper">
        <p className="grammar__text">
          Přestože se tento portál zabývá výukou angličtiny bez biflování, jsou
          věci, které se prostě musíme naučit nazpaměť. Mezi ně patří i anglická
          osobní zájmena ve spojení se slovesem být v přítomném čase. Bez této
          znalosti nesložíte ani základní anglickou větu.
        </p>
        <p className="grammar__text">
          Níže naleznete tabulky, které obsahují použití osobních zájmen ve
          větách oznamovacích, tázacích i záporných. Vše v přítomném čase
          prostém. U každé věty je český překlad i výslovnost namluvená mladou
          rodilou mluvčí z USA.
        </p>
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {personalPronounsAndVerbToBeSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={
            currentSubtopic ===
            personalPronounsAndVerbToBeSubtopicArray.length - 1
          }
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function ArticlesTopic() {
  const [currentSubtopic, setCurrentSubtopic] = useState(0);

  return (
    <div>
      <h2 className="grammar__topic-name">Členy v angličtině</h2>
      <p className="grammar__text">
        V angličtině existuje několik neoblíbených gramatických jevů, kterých se
        většina studentů a žáků bojí. Jedním z těchto jevů jsou nepochybně
        členy. Zatímco jiným nepříjemným ‘gramatikám’ se lze šikovně vyhýbat a
        často se objevují až v pozdějším stádiu pokročilosti (např. předpřítomý
        čas), členy nás provázejí již od úplného začátku. Nebudeme se jim tedy
        vyhýbat ani u nás.
      </p>
      <div className="grammar__content-wrapper">
        <button
          className="grammar__prev-topic-btn"
          disabled={currentSubtopic === 0}
          onClick={() => setCurrentSubtopic(currentSubtopic - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {articlesSubtopicArray[currentSubtopic]}
        <button
          className="grammar__next-topic-btn"
          disabled={currentSubtopic === articlesSubtopicArray.length - 1}
          onClick={() => setCurrentSubtopic(currentSubtopic + 1)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
}

function PrepositionsTopic() {
  return (
    <div>
      <h2 className="grammar__topic-name">Místní předložky IN, ON, AT</h2>
      <p className="grammar__text">
        Dnes se podíváme na používání základních místních předložek, in/'ɪn/,
        on/'ɒn/ a at/'æt/. Naučit se je správně používat je důležité a mnohdy i
        obtížné, protože se v některých případech odlišují od češtiny. Vesměs se
        však dají logicky odvodit.
      </p>
      <p className="grammar__text">
        Podíváme se na každou z těchto předložek blíže a uvedeme si množství
        názorných příkladů.
      </p>
      <div className="grammar__content-wrapper">
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">IN</h3>
          <p className="grammar__text">
            Předložku in (v) používáme především tehdy, mluvíme-li o prostoru.
            Něco se tedy nalézá uvnitř něčeho, v nějakém prostoru.
          </p>
          <p className="grammar__text">in the box (v krabici)</p>
          <p className="grammar__text">in the house (v domě)</p>
          <p className="grammar__text">in the room (v místnosti)</p>
          <p className="grammar__text">in the table (ve stole)</p>
          <p className="grammar__text">in my mouth (v ústech)</p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">ON</h3>
          <p className="grammar__text">
            Předložku on (na) používáme, pokud se něco nalézá na povrchu něčeho,
            na ploše. Tento povrch může být vodorovný, svislý, nezáleží na tom.
          </p>
          <p className="grammar__text">on the table (na stole)</p>
          <p className="grammar__text">on the roof (na střeše)</p>
          <p className="grammar__text">on the floor (na podlaze)</p>
          <p className="grammar__text">on the wall (na zdi)</p>
          <p className="grammar__text">on the hill (na kopci)</p>
        </div>
        <div className="grammar__subtopic">
          <h3 className="grammar__subtopic-name">AT</h3>
          <p className="grammar__text">
            Předložka at je daleko složitější než předchozí dvě. Prostor u
            předložky in i plochu u předložky on si představíme snadno. S
            předložkou at je to problematičtější.
          </p>
          <ol className="grammar__ordered-list">
            <li className="grammar__ordered-list-element">
              Zastávky, stanice, letiště
              <p className="grammar__text">
                V těchto případech máme na mysli bod, ve kterém nasedáme či
                vysedáme z dopravního prostředku.
              </p>
              <p className="grammar__text">at the bus stop (na zastávce)</p>
              <p className="grammar__text">
                at the railway station (na železniční stanici)
              </p>
              <p className="grammar__text">at the airport (na letišti)</p>
              <p className="grammar__text">
                at the second stop (na druhé zastávce)
              </p>
              <p className="grammar__text">at the terminal (na konečné)</p>
            </li>
            <li className="grammar__ordered-list-element">
              Místo na mapě, na plánku
              <p className="grammar__text">
                Podobně jako u předcházejícího příkladu, at používáme i ve
                spojeních, kterými říkáme, ve kterém místě na mapě apod. něco
                je.
              </p>
              <p className="grammar__text">
                at the traffic lights (na semaforu)
              </p>
              <p className="grammar__text">
                Turn right at the bank. (Odbočte vpravo u banky.)
              </p>
            </li>
            <li className="grammar__ordered-list-element">
              Místo + účel
              <p className="grammar__text">
                Dále at používáme v některých spojeních, kdy nemáme na mysli
                pouze místo samotné, ale i účel, ke kterému daná věc slouží:
              </p>
              <p className="grammar__text">at home (doma)</p>
              <p className="grammar__text">
                No, he isn't at home. (Ne, není doma. – nejde o budovu)
              </p>
              <p className="grammar__text">at work (v práci)</p>
              <p className="grammar__text">
                He's still at work. (Ještě je v práci – pracuje)
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

function EnglishGrammarSK(props) {
  switch (props.choosenTopic) {
    case "timesTopic":
      return <TimesTopic />;
    case "personalPronounsAndVerbToBeTopic":
      return <PersonalPronounsAndVerbToBeTopic />;
    case "articlesTopic":
      return <ArticlesTopic />;
    case "prepositionsTopic":
      return <PrepositionsTopic />;
    default:
      return (
        <div className="grammar">
          <h1 className="grammar__title">Grammar</h1>
          <div className="grammar__topics-wrapper">
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("timesTopic")}
            >
              Přítomné časy
            </button>
            <button
              className="grammar__topic-button"
              onClick={() =>
                props.setChoosenTopic("personalPronounsAndVerbToBeTopic")
              }
            >
              Osobní zájmena a sloveso být
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("articlesTopic")}
            >
              Členy v angličtině
            </button>
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic("prepositionsTopic")}
            >
              Místní předložky IN, ON, AT
            </button>
          </div>
        </div>
      );
  }
}

export default EnglishGrammarSK;
