import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ReturnButton.css";

function ReturnButton(props) {
  return (
    <button
      type="button"
      {...props}
      className={"return-button " + props.className}
    >
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
  );
}

export default ReturnButton;
