export const MailBoxConsultantData = [
  {
    username: "John Doe",
    topic: "Question about programming",
    messages: [
      {
        sender: "Expert A",
        content:
          "Hello, how can I help you?,Hello, how can I help you?,Hello, how can I help you?,Hello, how can I help you?,Hello, how can I help you?,Hello, how can I help you?,Hello, how can I help you?,Hello, how can I help you?,v,v",
      },
      { sender: "User", content: "Hi, I need some help with coding." },
      { sender: "Expert A", content: "Sure, what do you want to know?" },
    ],
    date: "2023-09-18",
    status: "New in conversation",
  },
  {
    username: "Jane Smith",
    topic: "Mobile app issues",
    messages: [
      { sender: "Expert C", content: "Good day, how can I assist you?" },
      {
        sender: "User",
        content: "Hello, I'm having problems with the mobile app.",
      },
      { sender: "Expert C", content: "Please describe your app issues." },
    ],
    date: "2023-09-17",
    status: "New",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
  {
    username: "Maria Johnson",
    topic: "Product inquiry",
    messages: [
      { sender: "Expert E", content: "Hello, how can I assist you?" },
      { sender: "User", content: "Hi, I'm interested in your products." },
      { sender: "Expert E", content: "Which product are you interested in?" },
    ],
    date: "2023-09-16",
    status: "Completed",
  },
];
