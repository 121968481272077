
import { useEffect, useState } from 'react';
import api from '../../../platform/api/api';
import './LessonImages.css';

import Store from '../../../Store';
const queryString = require('query-string');

const PER_PAGE = 20;

function LessonImages(props) {
    const [lessonNumbers, setLessonNumbers] = useState([]);

    const [languagePairId, setLanguagePairId] = useState();
    const [level, setLevel] = useState(1);
    const [lessonNumber, setLessonNumber] = useState();
    const [lessonImages, setLessonImages] = useState([]);

    const [currentLessonImageId, setCurrentLessonImageId] = useState()
    const [selectedImageId, setSelectedImageId] = useState()
    const [lessons, setLessons] = useState()

    useEffect(() => {
        if (level) {
            loadLessons(level);
        }
    }, [level]);

    useEffect(() => {
        if (lessonNumber && level) {
            loadLessonImages(lessonNumber, level);
            const currentLesson = lessons.find(lesson => lesson.number === lessonNumber)
            setCurrentLessonImageId(currentLesson.imageId)
            setSelectedImageId(currentLesson.imageId)
        }
    }, [lessonNumber, level]);

    const loadLessons = async (level) => {
        const res = await api.get(`/language-pair`);
        const languagePairId = res.data.sort((a, b) => a.id - b.id)[0].id;
        const qsObj = {
            languagePairId,
            level,
        };

        const resLessons = await api.get(`/lesson?${queryString.stringify(qsObj)}`);
        setLessons(resLessons.data)
        const uniqueLessonNumbers = [...new Set(resLessons.data.map(lesson => lesson.number))].sort((a,b) => a - b)

        setLessonNumbers(uniqueLessonNumbers);
        setLessonNumber(uniqueLessonNumbers[0]);

    };

    const saveNewImage = async () => {
        await api.post(`/lesson/${lessonNumber}/${level}/set-image`, {imageId: selectedImageId})
        setCurrentLessonImageId(selectedImageId)
    }

    const loadLessonImages = async (lessonNumber, level) => {
        const res = await api.get(
            `/lesson/possible-images/${lessonNumber}/${level}`
        );
        setLessonImages([...new Set(res.data.map(image => image.id))].sort((a,b) => a - b).map(id => res.data.find(img => img.id === id)));
    };

    return (
        <div
            style={{
                backgroundColor: `#fff`,
                minHeight: '100vh',
                backgroundSize: 'cover',
            }}
        >
            <div className="lesson-images-page">

                <div>
                    <select
                        value={level}
                        onChange={(e) => setLevel(e.target.value)}
                    >
                        <option value={1}>Poziom 1</option>
                        <option value={2}>Poziom 2</option>
                        <option value={3}>Poziom 3</option>
                    </select>
                    <select
                        value={lessonNumber}
                        onChange={(e) => {
                            setLessonNumber(
                                parseInt(e.target.value)
                            );
                        }}
                    >
                        {lessonNumbers.map((number) => (
                            <option
                                value={number}
                            >
                                {Store.getLessonName(
                                    level,
                                    number
                                )}
                            </option>
                        ))}
                    </select>
                    <div style={{width: "720px", margin: "20px auto", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: "10px"}}> 
                    {lessonImages.map((image, i) => 
                    <img src={image?.base64} 
                    className={`lesson-images-page__image ${image.id === selectedImageId ? "lesson-images-page__image--selected" : ""}`} 
                    onClick={() => setSelectedImageId(image.id)}
                    key={image.id}/>)}


                    </div>
                    {currentLessonImageId !== selectedImageId && <button className="lesson-images-page__button" onClick={() => saveNewImage()}>Zapisz</button>}
                </div>
            </div>
        </div>
    );
}

export default LessonImages;
