import cs from "./question-icons/cs.png";
import sk from "./question-icons/sk.png";
import ro from "./question-icons/ro.png";
import Store from "../../../Store";

const icons = {
  cs,
  sk,
  ro,
};

const getQuestionIcon = () => {
  const short = Store.getUserPlatformLangShort();
  return icons[short] ? icons[short] : icons.cs;
};

export default getQuestionIcon;
