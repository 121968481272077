import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ReturnButton from "../../Buttons/ReturnButton/ReturnButton";
import MainModal from "../MainModal/MainModal";
import EnglishGrammarSK from "./GrammarContent/EnglishGrammarSK";
import EnglishGrammarCS from "./GrammarContent/EnglishGrammarCS";
import SpanishGrammarSK from "./GrammarContent/SpanishGrammarSK";
import "./GrammarModal.css";
import GermanGrammarSK from "./GrammarContent/GermanGrammarSK";

const LANG_MAP = {
  "cs-en": EnglishGrammarCS,
  "sk-en": EnglishGrammarSK,
  "sk-es": SpanishGrammarSK,
  "sk-de": GermanGrammarSK,
};

function GrammarModal(props) {
  const [choosenTopic, setChoosenTopic] = useState("");
  const GrammarComponent = LANG_MAP[props.lang.replace(/[0-9]/g, '')];

  return (
    <MainModal
      isModalOpen={props.isModalOpen}
      setIsModalOpen={props.setIsModalOpen}
      className="grammar-modal"
    >
      {choosenTopic ? (
        <ReturnButton
          className="grammar-modal__return-button"
          onClick={() => setChoosenTopic("")}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </ReturnButton>
      ) : (
        ""
      )}
      <div className="grammar-modal__content">
        <GrammarComponent
          choosenTopic={choosenTopic}
          setChoosenTopic={setChoosenTopic}
        />
      </div>
    </MainModal>
  );
}

export default GrammarModal;
