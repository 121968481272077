export const alphabetDictionary = {
  en: {
    cs: {
      alphabetTitle: "Angličtina - abeceda",
      alphabetDescFirstLine:
        "English alphabet (anglická abeceda) se skládá z 26 písmen zapisovaných latinkou, která jsou součástí také české abecedy. V angličtině ale, na rozdíl od češtiny, neexistují písmena s diakritickými znaménky (´, ˇ, °) ani spřežka “ch”",
      alphabetDescSecondLine:
        "V tabulce níže naleznete všechna písmena anglické abecedy včetně výslovnosti a názvu jednotlivých písmen. Pro lepší zapamatování a usnadnění učení jsou uvedena i slovíčka, která na dané písmeno začínají.",
      tableHeaderLetter: "Písmeno",
      tableHeaderPronuncation: "Výslovnosť",
      tableHeaderExample: "Príklad / Porovnanie so slovenčinou",

      letter: {
        1: "A a",
        2: "B b",
        3: "C c",
        4: "D d",
        5: "E e",
        6: "F f",
        7: "G g",
        8: "H h",
        9: "I i",
        10: "J j",
        11: "K k",
        12: "L l",
        13: "M m",
        14: "N n",
        15: "O o",
        16: "P p",
        17: "Q q",
        18: "R r",
        19: "S s",
        20: "T",
        21: "U",
        22: "V",
        23: "W",
        24: "X",
        25: "Y",
        26: "Z",
      },

      pronunciation: {
        1: "ej",
        2: "bý",
        3: "sý",
        4: "dý",
        5: "ý",
        6: "ef",
        7: "džý",
        8: "ejdž",
        9: "áj",
        10: "džej",
        11: "kej",
        12: "el",
        13: "em",
        14: "en",
        15: "ou",
        16: "pí",
        17: "kjů",
        18: "ár",
        19: "es",
        20: "tý",
        21: "jů",
        22: "vý",
        23: "dabl jů",
        24: "eks",
        25: "váj",
        26: "zet",
      },

      example: {
        1: "actor, apple, airplane",
        2: "book, bone, basket",
        3: "cent, car, comb",
        4: "dolphin, dust, daffodil",
        5: "enter, envelope, entrance",
        6: "fish, flute, farm",
        7: "giraffe, gift, gloves",
        8: "hippo, herb, house",
        9: "insect, Indian, igloo",
        10: "jaguar, jumper, jam",
        11: "kangaroo, kettle, knife",
        12: "lion, life, lamp",
        13: "monkey, milk, map",
        14: "narwhal, night, nose",
        15: "owl, olive, oak",
        16: "python, park, pen",
        17: "quail, question, quiz",
        18: "rabbit, rocket, rose",
        19: "squirrel, sister, smith",
        20: "turtle, towel, tree",
        21: "unicorn, umbrella, union",
        22: "vampire, vest, van",
        23: "wolf, wood, watch",
        24: "x-ray, xylophone, Xerox",
        25: "yak, yacht, yellow",
        26: "zebra, zoo, zone",
      },
    },

    sk: {
      alphabetTitle: "Angličtina - abeceda",
      alphabetDescFirstLine:
        "Anglická abeceda (English alphabet) sa skladá z 26 písmen zapisovaných latinkou, ktoré sú súčasťou tiež slovenskej abecedy. V angličtine však na rozdiel od slovenčiny, neexistujú písmena s diakritickými znamienkami (´, ˇ) ani spojenie písmen “ch”.",
      alphabetDescSecondLine:
        "V tabuľke nižšie nájdete všetky písmená anglickej abecedy vrátane výslovnosti a názvov jednotlivých písmen. Pre lepšie zapamätanie a uľahčenie učenia sú uvedené aj slovíčka, ktoré daným písmenom začínajú.",
      tableHeaderLetter: "Písmeno",
      tableHeaderPronuncation: "Výslovnosť",
      tableHeaderExample: "Príklad / Porovnanie so slovenčinou",
      letter: {
        1: "A a",
        2: "B b",
        3: "C c",
        4: "D d",
        5: "E e",
        6: "F f",
        7: "G g",
        8: "H h",
        9: "I i",
        10: "J j",
        11: "K k",
        12: "L l",
        13: "M m",
        14: "N n",
        15: "O o",
        16: "P p",
        17: "Q q",
        18: "R r",
        19: "S s",
        20: "T",
        21: "U",
        22: "V",
        23: "W",
        24: "X",
        25: "Y",
        26: "Z",
      },

      pronunciation: {
        1: "ej",
        2: "bý",
        3: "sý",
        4: "dý",
        5: "ý",
        6: "ef",
        7: "dží",
        8: "ejdž",
        9: "áj",
        10: "džej",
        11: "kej",
        12: "el",
        13: "em",
        14: "en",
        15: "ou",
        16: "pí",
        17: "kjů",
        18: "ár",
        19: "es",
        20: "tý",
        21: "jů",
        22: "vý",
        23: "dabl jů",
        24: "eks",
        25: "váj",
        26: "zet",
      },

      example: {
        1: "actor, apple, airplane",
        2: "book, bone, basket",
        3: "cent, car, comb",
        4: "dolphin, dust, daffodil",
        5: "enter, envelope, entrance",
        6: "fish, flute, farm",
        7: "giraffe, gift, gloves",
        8: "hippo, herb, house",
        9: "insect, Indian, igloo",
        10: "jaguar, jumper, jam",
        11: "kangaroo, kettle, knife",
        12: "lion, life, lamp",
        13: "monkey, milk, map",
        14: "narwhal, night, nose",
        15: "owl, olive, oak",
        16: "python, park, pen",
        17: "quail, question, quiz",
        18: "rabbit, rocket, rose",
        19: "squirrel, sister, smith",
        20: "turtle, towel, tree",
        21: "unicorn, umbrella, union",
        22: "vampire, vest, van",
        23: "wolf, wood, watch",
        24: "x-ray, xylophone, Xerox",
        25: "yak, yacht, yellow",
        26: "zebra, zoo, zone",
      },
    },
  },

  es: {
    cs: {
      alphabetTitle: "",
      alphabetDescFirstLine: "",
      alphabetDescSecondLine: "",
      tableHeaderLetter: "",
      tableHeaderPronuncation: "",
      tableHeaderExample: "",
      letter: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
        14: "",
        15: "",
        16: "",
        17: "",
        18: "",
        19: "",
        20: "",
        21: "",
        22: "",
        23: "",
        24: "",
        25: "",
        26: "",
        27: "",
        28: "",
        29: "",
      },

      pronunciation: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
        14: "",
        15: "",
        16: "",
        17: "",
        18: "",
        19: "",
        20: "",
        21: "",
        22: "",
        23: "",
        24: "",
        25: "",
        26: "",
        27: "",
        28: "",
        29: "",
      },

      example: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
        14: "",
        15: "",
        16: "",
        17: "",
        18: "",
        19: "",
        20: "",
        21: "",
        22: "",
        23: "",
        24: "",
        25: "",
        26: "",
        27: "",
        28: "",
        29: "",
      },
    },

    sk: {
      alphabetTitle: "lŠpanielska abeceda",
      alphabetDescFirstLine:
        "Španielska abeceda, ktorá je odvodená z latinskej abecedy, prešla vo svojej najnovšej histórii niekoľkými dôležitými úpravami. Normálne používame normálnu abecedu, ktorú predstavuje nasledujúci text 27 písmen (symboly).",
      alphabetDescSecondLine: "",
      tableHeaderLetter: "Písmeno",
      tableHeaderPronuncation: "Názov v španielčina",
      tableHeaderExample: "Príklad / Porovnanie so slovenčinou",

      letter: {
        1: "A",
        2: "B",
        3: "C",
        4: "D",
        5: "E",
        6: "F",
        7: "G",
        8: "H",
        9: "I",
        10: "J",
        11: "K",
        12: "L",
        13: "LL",
        14: "M",
        15: "N",
        16: "ñ",
        17: "O",
        18: "P",
        19: "Q",
        20: "R",
        21: "RR",
        22: "S",
        23: "T",
        24: "U",
        25: "V",
        26: "W",
        27: "X",
        28: "Y",
        29: "Z",
      },

      pronunciation: {
        1: "a",
        2: "be",
        3: "ce",
        4: "de",
        5: "e",
        6: "efe",
        7: "ge",
        8: "hache",
        9: "i (latina)",
        10: "jota",
        11: "ka",
        12: "ele",
        13: "elle",
        14: "eme",
        15: "ene",
        16: "eñe",
        17: "o",
        18: "pe",
        19: "cu",
        20: "erre",
        21: "erre doble",
        22: "ese",
        23: "te",
        24: "u",
        25: "uve",
        26: "uve doble",
        27: "equis",
        28: "ye (i griega)",
        29: "zeta",
      },

      example: {
        1: "Plato",
        2: "Bueno (v šp. splýva až s našou fonémou V)",
        3: "Ciruela [siruela], Cosa [kosa]",
        4: "Dormitorio",
        5: "Rey",
        6: "Fuerte",
        7: "Girar [chirar], Guerra [gerra]",
        8: "Harina (Nikdy si nevyslovuje)",
        9: "Mirada",
        10: "Jamás [chamas]",
        11: "Kilogramo",
        12: "Lanzar",
        13: "Llamar",
        14: "Montar",
        15: "Nadar",
        16: "Pañuelo",
        17: "Odiar",
        18: "Probar",
        19: "Quitar [kitar]",
        20: "Rama",
        21: "Aburrido",
        22: "Sonar",
        23: "Tener",
        24: "Uva",
        25: "Valer",
        26: "Takmer nikde sa nenachádza",
        27: "Exacto",
        28: "Yo [jo]",
        29: "Zanahoría [sanahoría]",
      },
    },
  },

  de: {
    cs: {
      alphabetTitle: "",
      alphabetDescFirstLine: "",
      alphabetDescSecondLine: "",
      tableHeaderLetter: "",
      tableHeaderPronuncation: "",
      tableHeaderExample: "",
      letter: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
        14: "",
        15: "",
        16: "",
        17: "",
        18: "",
        19: "",
        20: "",
        21: "",
        22: "",
        23: "",
        24: "",
        25: "",
        26: "",
        27: "",
        28: "",
        29: "",
      },

      pronunciation: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
        14: "",
        15: "",
        16: "",
        17: "",
        18: "",
        19: "",
        20: "",
        21: "",
        22: "",
        23: "",
        24: "",
        25: "",
        26: "",
        27: "",
        28: "",
        29: "",
      },

      example: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
        14: "",
        15: "",
        16: "",
        17: "",
        18: "",
        19: "",
        20: "",
        21: "",
        22: "",
        23: "",
        24: "",
        25: "",
        26: "",
        27: "",
        28: "",
        29: "",
      },
    },

    sk: {
      alphabetTitle: "Nemecká abeceda",
      alphabetDescFirstLine:
        "Nemecká abeceda (resp. nemecké písmo) je abeceda (resp. písmo) používaná na zápis nemčiny. Obsahuje 26 základných písmen; vrátane ẞ je písmen 27, a vrátane ä, ö, ü je písmen 30.",
      alphabetDescSecondLine: "Abeceda vyzerá nasledovne:",
      tableHeaderLetter: "Písmeno",
      tableHeaderPronuncation: "Výslovnosť",
      tableHeaderExample: "",
      letter: {
        1: "A a",
        2: "B b",
        3: "C c",
        4: "D d",
        5: "E e",
        6: "F f",
        7: "G g",
        8: "H h",
        9: "I i",
        10: "J j",
        11: "K k",
        12: "L l",
        13: "M m",
        14: "N n",
        15: "O o",
        16: "P p",
        17: "Q q",
        18: "R r",
        19: "S s",
        20: "T t",
        21: "U u",
        22: "V v",
        23: "W w",
        24: "X x",
        25: "Y y",
        26: "Z z",
        27: "Ä ä",
        28: "Ö ö",
        29: "Ü ü",
      },

      pronunciation: {
        1: "á",
        2: "bé",
        3: "cé",
        4: "dé",
        5: "é",
        6: "ef",
        7: "gé",
        8: "há",
        9: "í",
        10: "jot (je)",
        11: "ká",
        12: "el",
        13: "em",
        14: "en",
        15: "ó",
        16: "pé",
        17: "kú (kvé)",
        18: "er",
        19: "es",
        20: "té",
        21: "ú",
        22: "fau",
        23: "vé",
        24: "iks",
        25: "üpsilon",
        26: "cet",
        27: "a-Umlaut",
        28: "o-Umlaut",
        29: "u-Umlaut",
      },

      example: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
        14: "",
        15: "",
        16: "",
        17: "",
        18: "",
        19: "",
        20: "",
        21: "",
        22: "",
        23: "",
        24: "",
        25: "",
        26: "",
        27: "",
        28: "",
        29: "",
      },
    },
  },

  ru: {
    cs: {
      alphabetTitle: "Ruská cyrilice",
      alphabetDescFirstLine: "Cyrilice byla poprvé použita k zápisu ruštiny v 10. století. Abeceda, kterou používáme dnes, se začala používat v 18. století. Předtím se pro ruštinu používala řada různých abeced, včetně hlaholice a různých verzí řecké abecedy. Za vlády Petra Velikého se začala více používat cyrilice. Ten ruskou abecedu reformoval a začlenil do ní prvky hlaholice i cyrilice ve snaze učinit ji vhodnější pro ruský jazyk. Postupem času se cyrilice stala hlavní abecedou používanou v ruštině.",
      alphabetDescSecondLine: "Ruská abeceda obsahuje 33 písmen. To zahrnuje 10 samohlásek, 21 souhlásek a 2 znaky (ь, ъ). Z toho 6 písmen (Ё, Й, Ъ, Ь, Ы, Э) je považováno za oddělená písmena od hlavní abecedy a používají se pouze v některých slovech.",
      tableHeaderLetter: "Ruština",
      tableHeaderPronuncation: "Čeština",
      tableHeaderExample: "",
      letter: {
        1: "А а",
        2: "Б б",
        3: "В в",
        4: "Г г",
        5: "Д д",
        6: "Е е",
        7: "Ё ё",
        8: "Ж ж",
        9: "З з",
        10: "И и",
        11: "Й й",
        12: "К к",
        13: "Л л",
        14: "М м",
        15: "Н н",
        16: "О о",
        17: "П п",
        18: "Р р",
        19: "С с",
        20: "Т т",
        21: "У у",
        22: "Ф ф",
        23: "Х х",
        24: "Ц ц",
        25: "Ч ч",
        26: "Ш ш",
        27: "Щ щ",
        28: "Ъ ъ",
        29: "Ы ы",
        30: "Ь ь",
        31: "Э э",
        32: "Ю ю",
        33: "Я я",
      },

      pronunciation: {
        1: "A a",
        2: "B b",
        3: "V v",
        4: "G g",
        5: "D d",
        6: "E e<div>Jë jë (na začátku slova, po samohláskách a po jerech <b>ь</b> resp. <b>ъ</b>)</div>",
        7: "Ë ë<div>Jë jë (na začátku slova, po samohláskách a po jerech <b>ь</b> resp. <b>ъ</b>)</div>",
        8: "Ž ž",
        9: "Z z",
        10: "I i<div>Ji ji (po samohláskách a po jerech <b>ь<b/> resp. <b>ъ</b>)</div>",
        11: "J j",
        12: "K k",
        13: "L l",
        14: "M m",
        15: "N n",
        16: "O o",
        17: "P p",
        18: "R r",
        19: "S s",
        20: "T t",
        21: "U u",
        22: "F f",
        23: "Ch ch",
        24: "C c",
        25: "Č č",
        26: "Š š",
        27: "Šč šč",
        28: "(vynechává se)",
        29: "Y y",
        30: "(vynechává se)",
        31: "E e (na začátku slova)<div>Ê ê (uvnitř slova, správně však e s kroužkem)</div>",
        32: "Ju ju",
        33: "Ja ja",
      },

      example: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
        14: "",
        15: "",
        16: "",
        17: "",
        18: "",
        19: "",
        20: "",
        21: "",
        22: "",
        23: "",
        24: "",
        25: "",
        26: "",
        27: "",
        28: "",
        29: "",
      },
    },
    sk: {
      alphabetTitle: "Ruská abeceda",
      alphabetDescFirstLine: "Ruská abeceda pozostáva z 33 písmen, na rozdiel od ukrajinskej a bieloruskej abecedy v nej ale nenájdeme písmeno i. V ruštine je písmeno Г vyslovované ako [g]. To ale v bieloruštine a ukrajinčine neplatí. V ukrajinčine je písmeno Г vyslovované ako [h] a hláska [g] je zapisovaná písmenom Ґ. Rovnaké písmeno sa v minulosti používalo aj v bieloruštine, kde je teraz označované za zastarané. ",
      alphabetDescSecondLine: "Ruské a bieloruské Е má rovnakú funkciu ako písmeno ě v češtine, okrem toho, že sa číta ako [e], navyše zmäkčuje predchádzajúcu spoluhlásku. Na rozdiel od českého ě sa ale môže vyskytovať aj na začiatku slova. Ak sa nachádza na začiatku slova, po tvrdom znaku alebo po inej samohláske, je čítané ako [je].",
      alphabetDescThirdLine: "Súčasná ruská abeceda zahŕňa 11 samohlások, 20 spoluhlások a dva znaky, ktoré neoznačujú zvuk, a to tvrdý znak Ъ a mäkký znak Ь. ",
      tableHeaderLetter: "Znak",
      tableHeaderPronuncation: "Meno",
      tableHeaderExample: "Približný zvuk",
      letter: {
        1: "А а",
        2: "Б б",
        3: "В в",
        4: "Г г",
        5: "Д д",
        6: "Е е",
        7: "Ё ё",
        8: "Ж ж",
        9: "З з",
        10: "И и",
        11: "Й й",
        12: "К к",
        13: "Л л",
        14: "М м",
        15: "Н н",
        16: "О о",
        17: "П п",
        18: "Р р",
        19: "С с",
        20: "Т т",
        21: "У у",
        22: "Ф ф",
        23: "Х х",
        24: "Ц ц",
        25: "Ч ч",
        26: "Ш ш",
        27: "Щ щ",
        28: "Ъ ъ",
        29: "Ы ы",
        30: "Ь ь",
        31: "Э э",
        32: "Ю ю",
        33: "Я я",
      },

      pronunciation: {
        1: "a",
        2: "b",
        3: "v",
        4: "g",
        5: "d",
        6: "je",
        7: "jo",
        8: "ž",
        9: "z",
        10: "i",
        11: "j",
        12: "k",
        13: "l",
        14: "m",
        15: "n",
        16: "o",
        17: "p",
        18: "r",
        19: "s",
        20: "t",
        21: "u",
        22: "f",
        23: "ch",
        24: "c",
        25: "č",
        26: "š",
        27: "šč",
        28: "tvrdý znak (tvrdý jer)",
        29: "y- jery",
        30: "mäkký znak (mäkký jer)",
        31: "e",
        32: "ju",
        33: "ja",
      },

      example: {
        1: "/a/",
        2: "/b/",
        3: "/v/",
        4: "/g/",
        5: "/d/",
        6: "/je/",
        7: "/jo/",
        8: "/ž/",
        9: "/z/",
        10: "/i/",
        11: "/j/",
        12: "/k/",
        13: "/l/",
        14: "/m/",
        15: "/n/",
        16: "/o/",
        17: "/p/",
        18: "/r/",
        19: "/s/",
        20: "/t/",
        21: "/u/",
        22: "/f/",
        23: "/ch/",
        24: "/c/",
        25: "/č/",
        26: "/š/",
        27: "/šč/",
        28: "/slabé j/",
        29: "/y/",
        30: "zmäkčuje spoluhlásku",
        31: "/e/",
        32: "/ju/",
        33: "/ja/",
      },
    },

  },
};
