import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import api from "../api/api";

import StatsContext from './context/statsContext';

const STATS_INTERVAL = 60 * 1000;

const StatsWrapper = ({ children }) => {
    const languagePairIdRef = useRef(null);
    const lessonIdRef = useRef(null);
    const levelRef = useRef(null);

    const setLanguagePairId = useCallback((value) => {
        languagePairIdRef.current = value;
    }, []);

    const setLessonId = useCallback((value) => {
        lessonIdRef.current = value;
    }, []);

    const setLevel = useCallback((value) => {
        levelRef.current = value;
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if(languagePairIdRef.current && window.location.pathname.includes('platform')) {
                api.post('/stats', {
                    path: window.location.pathname,
                    lessonId: parseInt(lessonIdRef.current),
                    languagePairId: parseInt(languagePairIdRef.current),
                    level: parseInt(levelRef.current)
                })
            }
        }, STATS_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    return (
        <StatsContext.Provider value={{ setLanguagePairId, setLessonId, setLevel }}>
            {children}
        </StatsContext.Provider>
    );
};

export default StatsWrapper;