import { useEffect, useState } from 'react';
import api from '../../../platform/api/api';
import './TutorialStats.css';

function TutorialStats() {
    const [tutorialStats, setTutorialStats] = useState({});

    useEffect(() => {
        api.get('/tutorial-stats').then((res) => {
            setTutorialStats(res.data);
        });
    }, []);

    console.log(tutorialStats)
    return (
        <div className="tutorial-stats-page">

            {!!Object.keys(tutorialStats).length ? (
                <table className="tutorial-stats-page__table">
                    <thead>
                        <th className="tutorial-stats-page__table-header">
                            Strona samouczka
                        </th>
                        <th className="tutorial-stats-page__table-header">
                            Liczba użytkowników
                        </th>
                    </thead>
                    {Object.keys(tutorialStats)
                        .sort((a, b) => parseInt(a) - parseInt(b))
                        .map((key) => (
                            <tr className="tutorial-stats-page__table-row">
                                <td className="tutorial-stats-page__table-cell">
                                    {key}
                                </td>
                                <td className="tutorial-stats-page__table-cell">
                                    {tutorialStats[key]}
                                </td>
                            </tr>
                        ))}
                </table>
            ) : <div>Brak danych</div>}
        </div>
    );
}

export default TutorialStats;
