import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import api from "../../../api/api";
import "./MainPageMobile.css";
import logo from "../../../../common/assets/logo-white.png";
import Store from "../../../../Store";
import flag from "../dymek_angielski.png";
import getLanguageFlag from "../../../common/functions/getLanguageFlag";
import levelOne from "../dymek-lvl1.png";
import levelTwo from "../dymek-lvl2.png";
import levelThree from "../dymek-lvl3.png";
import ColouredSpeechBubble from "../../../components/SpeechBubbles/ColouredSpeechBubble/ColouredSpeechBubble";
import YourActivityBox from "../../../components/YourActivityBox/YourActivityBox";
import MainModal from "../../../components/Modals/MainModal/MainModal";
import YourActivityModal from "../../../components/Modals/YourActivityModal/YourActivityModal";
import getLanguageCapitalWeatherCodes from "../../../common/functions/getLanguageCapitalWeatherCodes";
import getLevelColor from "../../../common/functions/getLevelColor";
import getLanguageFunFact from "../../../common/functions/getLanguageFunFact";
import Sidebar from "../../../components/Sidebar/Sidebar";
import getLanguageMap from "../../../common/functions/getLanguageMap";
import CoursesModal from "../../../components/Modals/CoursesModal/CoursesModal";

function MainPageMobile(props) {
  const [currentLanguagePair, setCurrentLanguagePair] = useState();
  const [currentLevel, setCurrentLevel] = useState();
  const [currentLessonNumber, setCurrentLessonNumber] = useState();
  const [funfact, setFunfact] = useState();

  const [currentLanguageShort, setCurrentLanguageShort] = useState();

  const [languagesAvailable, setLanguagesAvailable] = useState([]);
  const [notAvailableLanguages, setNotAvailableLanguages] = useState([]);

  const [currentProgress, setCurrentProgress] = useState(0);

  const params = useParams();

  const navigate = useNavigate();

  function handleLogOut() {
    Store.removeToken();
    navigate("/"); // whichever component you want it to route to
  }

  function appendWeatherWidget(langShort) {
    const weatherCode = getLanguageCapitalWeatherCodes(langShort).html;
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      getLanguageCapitalWeatherCodes(langShort).script
    );
    document.getElementById("weather-widget").innerHTML = weatherCode;
    document.getElementById("weather-widget").appendChild(script);
  }

  async function loadData() {
    const lastLesson = await api.get("/lesson/last-lesson");
    const langAvailableRes = await api.get(
      `/language-pair/language-from/${params.languageShort}/available`
    );
    const langAllRes = await api.get(
      `/language-pair/language-from/${params.languageShort}`
    );

    setLanguagesAvailable(langAvailableRes.data);
    const availableIds = langAvailableRes.data.map((lang) => lang.id);
    setNotAvailableLanguages(
      langAllRes.data.filter((lang) => availableIds.indexOf(lang.id) === -1)
    );

    if (lastLesson.data
      && 
      lastLesson.data.languagePair.languageFrom.languageShort.includes(params.languageShort)) {
      setCurrentLanguageShort(
        lastLesson.data.languagePair.languageTo.languageShort
      );
      setCurrentLanguagePair(lastLesson.data.languagePair);
      setCurrentLevel(lastLesson.data.level);
      setCurrentLessonNumber(lastLesson.data.number);
    } else {
      setCurrentLanguageShort(
        langAvailableRes.data[0].languageTo.languageShort
      );
      setCurrentLanguagePair(langAvailableRes.data[0]);
      setCurrentLevel(1);
      setCurrentLessonNumber(1);
    }
  }

  async function loadProgress(pairId, level) {
    const resLessons = await api.get(
      `/lesson/language-pair/${pairId}/level/${level}`
    );
    let sumProgress = 0;
    resLessons.data.forEach((lesson) => {
      sumProgress += lesson.progress;
    });
    setCurrentProgress((sumProgress * 100) / resLessons.data.length);
  }

  function getBackgroundImageClass(languageShort) {
    let short = languageShort;
    return `main-page-mobile__background--${short}`;
  }

  useEffect(() => {
    Store.setUserPlatformLanguage(params.languageShort);
    setFunfact(getLanguageFunFact());
    loadData();
  }, [params.languageShort]);


  useEffect(() => {
    if (currentLevel && currentLanguagePair) {
      loadProgress(currentLanguagePair.id, currentLevel);
    }
  }, [currentLevel, currentLanguagePair]);

  const [isYourActivityModalOpen, setIsYourActivityModalOpen] = useState(false);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  return (
    currentLanguageShort && currentLanguagePair && (
      <div className="main-page-mobile">
        <Sidebar
          isSidebarExpandable
          darkBg
          setError={props.setError}
          pairId={currentLanguagePair.id}
        ></Sidebar>
        <div
          className={`main-page-mobile__background ${getBackgroundImageClass(
            currentLanguageShort
          )}`}
        >
          <div className="main-page-mobile__nav-wrapper">
            <div className="main-page-mobile__logo-wrapper">
              <img src={logo} className="main-page-mobile__logo" />
            </div>
          </div>
          <div className="main-page-mobile__main-wrapper">
            <div className="main-page-mobile__content-wrapper">
              <ColouredSpeechBubble className="coloured-speech-bubble--left-top main-page__main-speech-bubble">
                <h1 className="main-page-mobile__speech-bubble-main-header">
                  {Store.getText("mainPageWelcomeBoxHeaderOne")}{" "}
                  {Store.getUsername()}!
                </h1>
                <h2 className="main-page-mobile__speech-bubble-header">
                  {Store.getText(
                    "mainPageWelcomeBoxHeaderTwo" +
                      ((new Date().getDate() % 3) + 1)
                  )}
                </h2>
              </ColouredSpeechBubble>
              {
                <YourActivityModal
                  isModalOpen={isYourActivityModalOpen}
                  setIsModalOpen={setIsYourActivityModalOpen}
                  level={currentLevel}
                  pairId={currentLanguagePair.id}
                ></YourActivityModal>
              }
            </div>
            <div className="main-page-mobile__content-wrapper">
              <div className="main-page-mobile__levels-wrapper">
                <div className="main-page-mobile__speech-bubbles-image"></div>
                <div className="main-page-mobile__level-buttons">
                  <div
                    className="main-page-mobile__level-button-wrapper"
                    onClick={() => setCurrentLevel(1)}
                  >
                    <img
                      src={levelOne}
                      className={`main-page-mobile__level-button-img ${
                        currentLevel === 1
                          ? "main-page-mobile__level-button-img--active"
                          : ""
                      }`}
                    />
                    <button
                      className={`main-page-mobile__level-button ${
                        currentLevel === 1
                          ? "main-page-mobile__level-button--active"
                          : ""
                      }`}
                    >
                      {Store.getText("level")} 1
                    </button>
                  </div>
                  <div
                    className="main-page-mobile__level-button-wrapper"
                    onClick={() => setCurrentLevel(2)}
                  >
                    <img
                      src={levelTwo}
                      className={`main-page-mobile__level-button-img ${
                        currentLevel === 2
                          ? "main-page-mobile__level-button-img--active"
                          : ""
                      }`}
                    />
                    <button
                      className={`main-page-mobile__level-button ${
                        currentLevel === 2
                          ? "main-page-mobile__level-button--active"
                          : ""
                      }`}
                    >
                      {Store.getText("level")} 2
                    </button>
                  </div>
                  <div
                    className="main-page-mobile__level-button-wrapper"
                    onClick={() => setCurrentLevel(3)}
                  >
                    <img
                      src={levelThree}
                      className={`main-page-mobile__level-button-img ${
                        currentLevel === 3
                          ? "main-page-mobile__level-button-img--active"
                          : ""
                      }`}
                    />
                    <button
                      className={`main-page-mobile__level-button ${
                        currentLevel === 3
                          ? "main-page-mobile__level-button--active"
                          : ""
                      }`}
                    >
                      {Store.getText("level")} 3
                    </button>
                  </div>
                </div>
                <div className="main-page-mobile__progress-bar-wrapper">
                  <div className="main-page-mobile__progress-bar">
                    <ProgressBar
                      completed={currentProgress}
                      height="30px"
                      bgColor="#95C34B"
                      customLabel=" "
                      className="progress-bar"
                    />
                  </div>
                  <p className="main-page-mobile__progress-bar-desc">
                    {Store.getText("mainPageProgressBarDescription")}{" "}
                    {currentLevel}
                  </p>
                </div>
              </div>
              <div className="main-page-mobile__lessons-wrapper">
                <Link
                  className="main-page-mobile__big-square-button"
                  style={{ backgroundColor: getLevelColor(currentLevel) }}
                  to={`/platform/activity/${currentLanguagePair.id}/${currentLevel}`}
                >
                  {Store.getText("mainPageContinueLearningButtonText")}
                </Link>
                <Link
                  className="main-page-mobile__transparent-button"
                  to={`/platform/lessons/${currentLanguagePair.id}/${currentLevel}`}
                >
                  {Store.getText("mainPageAllLessonsgButtontext")}
                </Link>
              </div>
            </div>
            <div
              onClick={() => setIsYourActivityModalOpen(true)}
              className="main-page-mobile__content-div main-page-mobile__content-div--your-activity"
            >
              <h3 className="main-page-mobile__content-title main-page-mobile__content-title--your-activity">
                {Store.getText("yourActivityBoxHeader")}
              </h3>
            </div>
            <div className="main-page-mobile__content-wrapper">
              <CoursesModal
                setCurrentLanguageShort={setCurrentLanguageShort}
                setCurrentLanguagePair={setCurrentLanguagePair}
                setCurrentLevel={setCurrentLevel}
                languagesAvailable={languagesAvailable}
                isModalOpen={isLanguageModalOpen}
                setIsModalOpen={setIsLanguageModalOpen}
                notAvailableLanguages={notAvailableLanguages}
                setError={props.setError}
              ></CoursesModal>
              <div
                onClick={() => setIsLanguageModalOpen(true)}
                className="main-page-mobile__content-div main-page-mobile__content-div--active-course"
              >
                <h3 className="main-page-mobile__content-title main-page-mobile__content-title--active-course">
                  {Store.getText("mainPageActiveCourseHeader")}
                </h3>

                <img
                  src={getLanguageFlag(currentLanguageShort)}
                  className="main-page-mobile__course-flag"
                />
              </div>

              <ColouredSpeechBubble
                className="coloured-speech-bubble--bottom-right main-page__fun-facts-widget"
                style={{
                  padding: "40px",
                  textAlign: "center",
                  fontSize: "2.6rem",
                }}
              >
                <div className="fun-facts-widget">
                  <h2 className="fun-facts-widget__title">
                    {Store.getText("mainPageFunFactsHeader")}
                  </h2>
                  {funfact && (
                    <div className="fun-facts-widget__content-wrapper">
                      <img
                        src={funfact.map}
                        className="fun-facts-widget__img"
                      />
                      <p className="fun-facts-widget__text">
                        {funfact.funfact}
                      </p>
                    </div>
                  )}
                </div>
              </ColouredSpeechBubble>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default MainPageMobile;
