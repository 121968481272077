import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "./MainModal.css";

function MainModal(props) {
  return (
    <div
      className={
        "main-modal " +
        (props.isModalOpen ? " main-modal--active " : " ") +
        props.outerClassName
      }
    >
      <div
        className="main-modal__closing-div"
        onClick={() => props.setIsModalOpen(false)}
      ></div>
      <div className={"main-modal-box " + props.className}>
        <button
          className="main-modal__closing-button"
          type="button"
          onClick={() => props.setIsModalOpen(false)}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="main-modal__closing-button-icon"
          />
        </button>
        <div>{props.children}</div>
      </div>
    </div>
  );
}

export default MainModal;
