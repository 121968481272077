import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import getLevelColor from "../../../common/functions/getLevelColor";
import ActivityFeedback from "../../ActivityFeedback/ActivityFeedback";
import "./LinkExpressionsActivity.css";

function LinkExpressionsActivity({activityData, makeAnswer}) {

  const [foreignExpressions, setForeignExpressions] = useState([])
  const [localExpressions, setLocalExpressions] = useState([])

  const [activeExpression, setActiveExpression] = useState({});

  const [colouredPairs, setColouredPairs] = useState([])
  const [bluredWords, setBluredWords] = useState([])

  const [answerResult, setAnswerResult] = useState()

  useEffect(() => {
    const forExpr = []
    const locExpr = []
    activityData.words.words.forEach(word => {
      forExpr.push({id: word.id, word: word.wordTo.expression.text})
      locExpr.push({id: word.id, word: word.wordFrom.expression.text})
    })
    setForeignExpressions(forExpr.sort((a, b) => 0.5 - Math.random()))
    setLocalExpressions(locExpr.sort((a, b) => 0.5 - Math.random()))

  }, [])


  useEffect(() => {
    if(foreignExpressions.length > 0 && bluredWords.length === foreignExpressions.length) {

      setAnswerResult({isCorrect: true})
      setTimeout(() => {
        makeAnswer(true)
      }, 1500)
    };
  }, [bluredWords])


  const clickExpression = (id, side) => {
    if(Object.keys(activeExpression).length) {
      if(activeExpression.side === side) return setActiveExpression({id, side})
      setActiveExpression({})

      const isProper = id === activeExpression.id
      setColouredPairs((prev) => {
          const newColoured = [...prev, {[side]: id, [activeExpression.side]: activeExpression.id, isProper }]
          return newColoured
      })

      setTimeout(() => {
        setColouredPairs((prev) => {
          const copyColoured = [...prev]
          const index = copyColoured.findIndex(coloured => coloured[side] === id && coloured[activeExpression.side] === activeExpression.id)
          copyColoured.splice(index, 1)
          return copyColoured
        })
        if(isProper) setBluredWords(prev => [...prev, id])
      }, 1500)
    } else {
      setActiveExpression({id, side})
    }
  }


  return (
    <div className="link-expression-activity">
      <div className="link-expression-activity__main-content">

        <div className="link-expression-activity__expressions-wrapper">
        {foreignExpressions.map(expression =>  <div className={`
          link-expression-activity__expression 
          ${activeExpression.id === expression.id && activeExpression.side === "to"? 'link-expression-activity__expression--active' : ''}
          ${bluredWords.find(blured => blured === expression.id) ? 'link-expression-activity__expression--blured' : ''}
          ${colouredPairs.find(coloured => coloured.to === expression.id) ? (colouredPairs.find(coloured => coloured.to === expression.id).isProper ? 'link-expression-activity__expression--correct' : 'link-expression-activity__expression--wrong') : ''}

        `} 
        style={{...(activeExpression.id === expression.id && activeExpression.side === "to" && {borderColor: getLevelColor(activityData.level)})}}
          key={expression.id}
          onClick={() => clickExpression(expression.id, 'to')}
        >
          <p className="link-expression-activity__expression-text" >
            {expression.word}
          </p>
          </div>)}

        </div>
        <div className="link-expression-activity__expressions-wrapper">
        {localExpressions.map(expression =>  <div className={`
          link-expression-activity__expression 
          ${activeExpression.id === expression.id  && activeExpression.side === "from"? 'link-expression-activity__expression--active' : ''}
          ${bluredWords.find(blured => blured === expression.id) ? 'link-expression-activity__expression--blured' : ''}
          ${colouredPairs.find(coloured => coloured.from === expression.id) ? (colouredPairs.find(coloured => coloured.from === expression.id).isProper ? 'link-expression-activity__expression--correct' : 'link-expression-activity__expression--wrong') : ''}
        `} 
          key={expression.id}
          onClick={() => clickExpression(expression.id, 'from')}
          style={{...(activeExpression.id === expression.id && activeExpression.side === "from" && {borderColor: getLevelColor(activityData.level)})}}
        
        >
          <p className="link-expression-activity__expression-text" >
            {expression.word}
          </p>
          </div>)}

        </div>
      </div>
      {answerResult && <ActivityFeedback isCorrect={answerResult.isCorrect}></ActivityFeedback>}
    </div>
  );
}

export default LinkExpressionsActivity;
