import React from "react";
import styled from "styled-components";

const ImgBox = styled.div`
  max-width: 1000px;
  overflow: hidden;
`;

const TextWrapper = styled.div`
  max-width: 500px;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    max-width: 100vw;
    gap: 120px;
  }

  @media (max-width: 800px) {
    margin: 0px 20px;
    display: grid;
    gap: 0px;
  }
`;

const CustomP = styled.div`
  color: white;
  margin-top: 20px;
  font-weight: bold;
`;

const MockupAndTextUp = ({ mockup, right, right2Title, right2Bottom }) => {
  return (
    <>
      <ImgBox>
        <img src={mockup} style={{ width: "100%", height: "auto" }}></img>
      </ImgBox>
      <TextWrapper>
        <p style={{ maxWidth: "400px" }}>{right}</p>
        <div>
          <CustomP>{right2Title}</CustomP>
          <p style={{ maxWidth: "400px" }}> {right2Bottom}</p>
        </div>
      </TextWrapper>
    </>
  );
};

export default MockupAndTextUp;
