import england from "../images/countries/mapa_anglia.png";
import poland from "../images/countries/mapa_polska.png";
import italy from "../images/countries/mapa_wlochy.png";
import germany from "../images/countries/mapa_niemcy.png";
import niderland from "../images/countries/mapa_holandia.png";
import portugal from "../images/countries/mapa_portugalia.png";
import russia from "../images/countries/mapa_rosja.png";
import spanish from "../images/countries/mapa_hiszpania.png";
import french from "../images/countries/mapa_francja.png";

import testimonial1 from "../images/testamonial/user.png";
import testimonial2 from "../images/testamonial/user.png";
import testimonial3 from "../images/testamonial/user.png";
import testimonial4 from "../images/testamonial/user.png";
import testimonial5 from "../images/testamonial/user.png";

import brandValuesPhoto1 from "../images/brandValues/wartosci_ambicja.webp";
import brandValuesPhoto2 from "../images/brandValues/wartosci_radosc.webp";
import brandValuesPhoto3 from "../images/brandValues/wartosci_ciekawosc.webp";
import brandValuesPhoto4 from "../images/brandValues/wartosci_efektywnosc.webp";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";
import learning4 from "../images/learning/expertro.png";

import mockup from "../images/mockup_ro.png";

export const romanian = {
  navbar: {
    data: [
      { title: "Comandă un curs", path: "/course" },
      { title: "Autentificare", path: "" },
      { title: "Despre Smartado", path: "/product" },
      { title: "Despre noi", path: "/about" },
    ],
  },
  mainPage: {
    navbar: [
      { title: "Comandă un curs", path: "/course" },
      { title: "Autentificare", path: "" },
      { title: "Despre Smartado", path: "/product" },
      { title: "Despre noi", path: "/about" },
    ],
    hero: {
      title: "Ce oportunități vă va aduce noua limbă străină?",
      right1: "Un nou loc de munca?",
      right2: "Un post superior?",
      right3: "Note mai bune?",
      middle1: "Noi prietenii?",
      middle2: "O vacanță la tropice?",
      left1: "O familie în afară?",
    },
    poliglotWithNumbers: {
      title: "Smartado în numere",
      count: [
        {
          number: 3,
          description: "niveluri de studiu",
        },
        {
          number: 24000,
          description: "de utilizatori",
        },
        {
          number: 238,
          description: "lecții",
        },
      ],
    },
    poliglotMap: {
      title: "Ce este Smartado?",
      box1: "Cuvinte, fraze și expresii",
      boxDescription1:
        "Fiecare lecție este însoțită de imagini atent selecționate care fac procesul de învățare intuitiv și contextual. Subiectele cele mai frecvent utilizate acoperă o gamă largă de vocabular din viața de zi cu zi și din călătorii, făcând posibilă comunicarea în aceste domenii fără așa-numita barieră lingvistică.",
      box2: "O multitudine de lecții",
      boxDescription2:
        "Pe măsură ce dobândiți experiență, numărul de lecții la care veți avea acces va crește. Dacă vă decideți să învățați de la zero, începeți cu tipuri de lecții care acoperă subiecte de la cele mai simple la cele mai complexe. Fiecare lecție nu reprezintă doar învățarea unor cuvinte de vocabular individuale, ci conține și sarcini variate.      ",
      box3: "30 de minute sunt suficiente",
      boxDescription3:
        "Fără suprasolicitare! Puteți începe și termina lecțiile în orice moment. Nu se va întâmpla nimic dacă rutina zilnică vă ocupă tot timpul. Acest lucru diferențiază cursul nostru de toate celelalte - flexibilitatea. Cu toate acestea, vă sugerăm să dedicați 30 de minute pe zi studiului - cel puțin din când în când. Acest lucru va face ca procesul de memorare să fie și mai ușor și veți începe să vorbiți limba străină mai cursiv, exact ca un nativ.",
      box4: "Certificat",
      boxDescription4:
        "Un certificat de absolvire a cursului este o mare onoare. La urma urmei, toată lumea ar dori să obțină o confirmare conform căreia a dobândit noi competențe. După ce ați finalizat toate cele trei niveluri ale cursului și ați trecut testul final - veți primi un certificat gratuit. Îl puteți tipări și puteți fi mândru de dumneavoastră ca participant. Felicitări - ați reușit. Arătați certificatul familiei și prietenilor, lăudați-vă în fața șefilor și vorbiți fluent o limbă străină.",
      box5: "3 niveluri de învățare",
      boxDescription5:
        "Fie că nu cunoașteți deloc o limbă străină, fie că puteți să vă prezentați, să comandați o cafea sau să vorbiți cu ginerele dumneavoastră din străinătate - Smartado este potrivit pentru oricine. De aceea, cursul este împărțit pe niveluri: de bază, intermediar și avansat. Pentru fiecare nivel în parte, algoritmii de învățare selectează în mod constant vocabularul potrivit, astfel încât învățarea să nu surprindă pe nimeni cu un nivel prea dificil. După ce ați terminat nivelul de bază, puteți trece la nivelul intermediar sau avansat. Acest lucru înseamnă 3 cursuri la prețul unuia singur!",
    },
    avaiableLanguages: {
      title: "Limbi disponibile",
      text: "V-ați dorit dintotdeauna să învățați o limbă străină? Alegerea limbii potrivite este o decizie importantă care vă poate schimba viața în bine. ",
      text1:
        "Vorbirea unei noi limbi străine poate deschide portițe către noi oportunități pe măsură ce creșteți.",
      text2:
        "Pentru a deveni fluent în orice limbă este nevoie de timp, dedicare, entuziasm, motivație și perseverență. Platforma lingvistică Smartado este concepută pentru a vă ajuta să învățați orice limbă.",
      languages: [
        { image: england, lang: "Engleză" },
        { image: french, lang: "Franceză" },
        { image: spanish, lang: "Spaniolă" },
        { image: niderland, lang: "Olandeză" },
        { image: germany, lang: "Germană" },
        { image: italy, lang: "Italiană" },
        { image: russia, lang: "Rusă" },
        { image: portugal, lang: "Portugheză" },
        { image: poland, lang: "Poloneză" },
      ],
    },
    testimonial: {
      title: "Utilizatorii noștri spun",
      elements: [
        {
          id: 1,
          photo: testimonial2,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Z Eureką czas płynie szybciej!",
          text: "Smartado mă ajută la învățarea limbilor străine. Îl folosesc în mod activ de peste un an și sunt foarte mulțumită. Mă bucur că este actualizat și îmbunătățit în mod constant. Recomand cu căldură tuturor celor care încep să învețe limbi străine - nu există o aplicație mai bună!",
          bottomInfo: "Ana",
        },
        {
          id: 2,
          photo: testimonial3,
          date: "24 stycznia 2022",
          stars: 4,
          title: "Nauka dla zapracowanych!",
          text: "Am răsfoit alte câteva aplicații de top pentru învățarea limbilor străine, dar aceasta pare să aibă cel mai bun echilibru între distracție, plăcere și învățare eficientă. Recomand cu căldură 👌 ",
          bottomInfo: "Rodica",
        },
        {
          id: 3,
          photo: testimonial4,
          date: "24 stycznia 2022",
          stars: 4.5,
          title: "Teraz mieszkam za granicą!",
          text: "Cred că aplicația este excelentă pentru persoanele care doresc să își sporească abilitățile de comunicare într-o limbă străină. Există câte ceva pentru toată lumea. Cel mai bun aspect este faptul că persoanele de orice vârstă care nu au avut niciodată de-a face cu învățarea unei limbi străine pot începe să învețe:) Recomand 😊",
          bottomInfo: "Răzvan",
        },
        {
          id: 4,
          photo: testimonial5,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Nigdy nie jest za późno",
          text: "În opinia mea, aplicația Smartado este foarte bine pusă la punct. Mulțumită acesteia, cunoștințele sunt ușor de asimilat, lecțiile sunt interesante, ceea ce face ca învățarea să fie o plăcere. O folosesc în fiecare zi. Vă recomand 🙂.",
          bottomInfo: "Cosmin",
        },
        {
          id: 5,
          photo: testimonial5,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Nigdy nie jest za późno",
          text: "În mare parte, sunt destul de mulțumită de această aplicație și învăț o treia limbă străină.",
          bottomInfo: "Luminița",
        },
        {
          id: 6,
          photo: testimonial1,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Uczymy się całą rodziną!",
          text: "O platformă formidabilă, cea mai bună platformă de învățare a limbilor străine de pe piață. Învățarea este profundă și practică. Pune accent atât pe vocabular cât și pe gramatică, ceea ce mi-a lipsit întotdeauna în alte aplicații de acest tip, pe lângă o gamă largă de limbi străine.          ",
          bottomInfo: "Dănuț",
        },
      ],
    },
    course: {
      title: "Cum este structurat cursul?",
      mockupPolyglot: mockup,
      right:
        "Pe platforma Smartado, nu veți găsi reguli de gramatică plictisitoare și seci, pe care trebuie să le învățați într-o zi. Veți învăța propoziții și expresii așa cum sunt folosite în viața de zi cu zi și apoi veți consolida memoria prin exerciții simple și repetiții. Cursul este conceput pentru a face învățarea distractivă.        ",
      right2Title: "Lecții cu utilitate în viața reală",
      right2Bottom:
        "Fiecare nivel de învățare este format din zeci de lecții. Dacă nu știți la ce nivel vă aflați - începeți cu noțiunile de bază. Învățați saluturi, zilele săptămânii, sentimente. Învățați cum să comandați la restaurant și cum să chemați un taxi. Fiecare lecție include exemple din viața de zi cu zi. Fiecare lecție este ușor diferită, constă în sarcini și exerciții diferite, dar formează un întreg - astfel încât să puteți stăpâni cu ușurință limba. Puteți începe o lecție în orice moment.",
      flexDown: [
        {
          title: "În timpul învățării, veți întâlni elemente precum:",
          text: `Înregistrări în care se învață pronunția corectă,\n
         `,
          text1: "fraze și propoziții din viața reală,\n",
          text2: "imagini care vă ajută să vă amintiți cuvintele și frazele \n",
          text3: "prin asocieri vizuale\n",
          text4: "...și multe, multe altele..",
        },
        {
          title: "Exerciții",
          text: `Indiferent dacă vă place să rezolvați zilnic cuvinte încrucișate sau, dimpotrivă, preferați să evitați astfel de activități, nu se poate nega faptul că aceste puzzle-uri mentale sunt excelente pentru a vă antrena memoria și pentru a vă permite să repetați materialul învățat.`,
        },
        {
          title: "Repetiții",
          text: `Cursanții mai experimentați pot repeta vocabularul și își pot testa cunoștințele în toate domeniile prin intermediul modului de învățare continuă. Acesta conține tot vocabularul disponibil, din toate categoriile. Un algoritm de învățare verifică constant nivelul de cunoștințe și ajustează următoarele lecții.`,
        },
      ],
    },
    learningWithPolyglot: {
      title: "Învățarea\n z  cu Smartado",
      cards: [
        {
          icon: learning1,
          headline: `Lecții scurte, dar sistematice`,
          text: `Cel mai important lucru atunci când înveți o limbă este consecvența. În același timp, este dificil pentru noi toți să găsim timpul de care avem nevoie. Având în vedere acest lucru, Smartado este conceput pentru a fi predat în lecții scurte, dar sistematice.
          `,
        },
        {
          icon: learning2,
          headline: `Posibilitatea învățării, fără a părăsi confortul propriei case
          `,
          text: `Nu aveți nevoie de nimic altceva în afară de telefon, calculator sau tabletă pentru a utiliza aplicația de învățare Smartado. Cu Smartado puteți învăța oriunde: atunci când așteptați la coadă, în autobuz și, mai ales, puteți învăța acasă, fără a fi nevoie să vă deplasați. Învățați în cadă sau în timp ce plimbați câinele? Cu Smartado, acest lucru este posibil!
          `,
          text2: "",
        },
        {
          icon: learning3,
          headline: ` Calitate de top `,
          text: `În timpul creării Smartado, o echipă de experți s-a concentrat pe unul dintre cele mai importante obiective: să permită metodei noastre să facă învățarea unei limbi străine cât mai rapidă (indiferent de vârsta cursantului!).
          `,
        },
        {
          newOne: true,
          icon: learning4,
          headline: ` Întrebați Expertul `,
          text: `Aveți o problemă legată de limbă sau aveți nevoie de ajutor cu un exercițiu? Trimiteți pur și simplu întrebarea dvs. și specialistul nostru în limbi va răspunde cu o soluție. Astfel, aveți întotdeauna sprijinul unui expert în călătoria dvs. de învățare a limbilor. Cu Întrebați Expertul, nicio întrebare nu rămâne fără răspuns și învățarea devine mai ușoară ca niciodată.
          `,
        },
      ],
    },
    whyPolyglot: {
      title: "De ce să învățați cu Smartado?",
      textUp:
        "O platformă cu o abordare multidirecțională a învățării care vă va ajuta să stăpâniți o nouă limbă în același mod în care ați face-o cu limba maternă - în mod natural.",
      textBottom:
        "Învățarea unei limbi străine vă poate schimba viața. Nu numai că vă deschide o portiță către o lume plină de oportunități profesionale și personale, dar vă dezvoltă mintea și vă sporește încrederea de sine. Pe măsură ce tehnologii precum internetul reduc distanțele și devenim membri ai unei comunități globale, abilitatea de a învăța o limbă devine o parte esențială a interacțiunii noastre cu ceilalți, dar și cu lumea succesului.",
      cards: [
        {
          headline: `Învățați fraze și expresii din viața de zi cu zi folosind tehnologia noastră avansată de învățare.
          `,
          text: `Lecțiile sunt adaptate în mod constant la nivelul dumneavoastră, astfel încât nu veți rămâne niciodată fără motivație și nu vă veți plictisi.

          `,
        },
        {
          headline: `Cufundați-vă în învățarea unei limbi străine. Încercați să înțelegeți pe deplin o limbă nouă pe baza cunoștințelor dumneavoastră contextuale.          `,
          text: `Dzięki tysiącom materiałów graficznych i dźwiękowych 
          zobaczysz i usłyszysz jak native speaker.
          `,
        },
        {
          headline: `Câștigați încredere în vorbire. Unul dintre scopurile învățării limbilor străine este și acela de a fi înțeles. `,
          text: `Cu ajutorul Smartado, tot ce trebuie să faceți este să repetați vocabularul pe care l-ați auzit - nu vă fie teamă să vorbiți! Sistemul nostru vă va ajuta.

          `,
        },
        {
          headline: `Nu permiteți uitarea anumitor cuvinte. Învățați printr-o varietate de exerciții.`,
          text: `Am conceput cu atenție elementele aplicației astfel încât mintea dumneavoastră să nu se plictisească niciodată. Pregătim și adăugăm în mod constant noi provocări la Smartado pentru a face învățarea distractivă.

          `,
        },
      ],
    },
  },
  aboutUs: {
    navbar: [
      { title: "Comandă un curs", path: "/course" },
      { title: "Autentificare", path: "" },
      { title: "Despre Smartado", path: "/product" },
      { title: "Despre noi", path: "/about" },
    ],
    mission: {
      title: "Misiune",
      text1:
        "Obiectivul nostru principal este acela de a pune la dispoziția tuturor, cu ajutorul tehnologiei, aceeași calitate de învățare ca și în cazul meditațiilor private. ",
      title2: "Acces universal",
      textTitle2:
        "Peste 1,2 miliarde de oameni învață limbi străine, iar majoritatea speră să obțină noi oportunități prin acest lucru. Din păcate, învățarea unei limbi străine este costisitoare, iar pentru mulți oameni accesul este limitat.        ",
      title3: "Punte culturală",
      textTitle3:
        "Credem că limba este un fel de punte între diferite culturi. Aceasta încurajează conversațiile, căutarea înțelegerii, încurajează urmărirea obiectivelor și realizarea de descoperiri. Pentru unii, este cheia succesului; pentru alții, este biletul către o lume nouă.",
      textBottom:
        "De aceea, misiunea Platformei de învățare Smartado este de a face învățarea limbilor străine mai eficientă și mai confortabilă. Prin valorificarea puterii tehnologiei, putem uni lumea prin intermediul limbilor străine.  ",
    },
    approach: {
      title: "Abordare",
      text1:
        "Din conversațiile noastre cu comunitatea, se pare că mulți utilizatori din întreaga lume apreciază platforma de limbi străine Smartado.          ",
      text2:
        "Misiunea noastră este de a sparge barierele de comunicare și de a aduce oamenii împreună prin învățarea limbilor străine.          ",
      text3:
        "Înțelegerea a ceea ce reprezentăm și a modului în care comunicăm cu comunitatea noastră este esențială pentru a ne adapta la nevoile comunității diverse pe care am construit-o. ",
      paragraphData: [
        {
          id: 1,
          title: "Personalizare",
          text: "Algoritmii de învățare automată lucrează în permanență pentru a oferi materiale de învățare la nivelul potrivit pentru fiecare persoană în parte.",
        },
        {
          id: 2,
          title: "O abordare echilibrată ",
          text: `Platforma Smartado permite celor care învață limbi străine să exploreze o varietate de modele pe cont propriu, fără a fi nevoie să se concentreze asupra regulilor lingvistice - exact așa cum am învățat prima noastră limbă în copilărie. Această abordare, numită „învățare latentă”, este ideală pentru dezvoltarea unei înțelegeri solide a unei limbi și a regulilor sale la un nivel de bază.`,
        },
        {
          id: 3,
          title: "Comunitate",
          text: "Limbile străine le permit oameni să socializeze. Explorarea limbilor încurajează oamenii să găsească înțelegere. Comunitatea platformei lingvistice Smartado unește oameni din întreaga lume.            ",
        },
        {
          id: 4,
          title: "Îmbunătățirea continuă",
          text: "Creăm în mod constant cursuri noi și îmbunătățite, ne extindem colecția de instrumente de învățare a limbilor străine și ne actualizăm materialele pe măsură ce limba evoluează, pentru a ne asigura că oferim în mod constant acces la cel mai bun învățământ lingvistic din lume. Precum limbile străine, nu ne oprim niciodată din evoluat.",
        },
      ],
    },
    brandValues: {
      title: "Valorile companiei",
      part2: {
        textInfo:
          "Wartości marki określają sposób naszej komunikacji, sposób pracy nad produktem oraz sposób, w jaki będziemy rozwijać platformę językową Smartado w nadchodzących latach.",
        brandValuesData: [
          {
            photo: brandValuesPhoto1,
            title: "Ambiție",
            text: "Ne dorim ca utilizatorii noștri să reușească, indiferent de obiectivele pe care și le stabilesc. Suntem ambițioși și împingem constant limitele. ",
          },
          {
            photo: brandValuesPhoto2,
            title: "Bucurie",
            text: "Credem că învățarea limbilor străine ar trebui să fie distractivă. Îi ajutăm pe oameni să obțină beneficii fascinante de pe urma abilității de a vorbi o limbă străină.",
          },
          {
            photo: brandValuesPhoto3,
            title: "Curiozitate",
            text: "Avem o sete constantă de cunoaștere și de dezvoltare. Suntem pasionați să învățăm despre alte culturi. Îi ajutăm pe oameni să descopere lumea prin intermediul limbilor.",
          },
          {
            photo: brandValuesPhoto4,
            title: "Eficiență",
            text: "Dorim ca oamenii să învețe limbile străine cu înțelepciune. Combinăm educația cu tehnologia. ",
          },
        ],
      },
    },
  },
  formSender: {
    formControll: {
      titleUp: "Comandați",
      titleDown: "un curs",
      pageLink: "https://viitor-si-competenta.eu/typ",
      title: "Sunteți interesat de un curs?",
      title1: "Ați dori mai multe informații?",
      subtitle: "Lăsați-ne numărul dumneavoastră de  –",
      subtitle2: "telefon, iar noi vă vom suna înapoi",
      button1: "Nume și prenume",
      button2: "Număr de telefon",
      button3: "E-mail",
      buttonSub: "Solicitați un apel > ",
      checkOne:
        "Prin trimiterea acestui formular, sunteți de acord cu termenii și condițiile și declarați că ați citit",
      checkOneTwo: "și",
      checkTwo: "Sunt de acord să fiu contactat în scopuri de marketing",
      textOne: "Vă informăm că: ",
      textTwo:
        "dministratorul datelor dumneavoastră cu caracter personal este Lingua Institute Spółka z ograniczoną odpowiedzialnością cu sediul social în Varșovia, ul. Nowogrodzka 50 / 515, 00-695, înscrisă la Registrul Comerțului în evidența Tribunalul Districtual din Varșovia sub KRS Nr. 0001003739, REGON: 523740380, NIP 7011116589 în legătură cu prelucrarea datelor dumneavoastră cu caracter personal, a cererii depuse prin acest formular de contact sau a executării contractului încheiat. Prin contactarea noastră, sunteți de acord cu prelucrarea datelor dumneavoastră cu caracter personal în scopurile menționate mai sus. În legătură cu prelucrarea datelor dumneavoastră de către noi, aveți dreptul de a accesa, rectifica, șterge sau restricționa prelucrarea, dreptul de a vă opune prelucrării și dreptul la portabilitatea datelor. De asemenea, aveți dreptul de a vă retrage consimțământul în orice moment, iar retragerea consimțământului nu afectează legalitatea prelucrării care a fost efectuată pe baza consimțământului anterior retragerii acestuia. De asemenea, aveți dreptul de a depune o plângere la o autoritate de supraveghere. Furnizarea datelor este, desigur, voluntară, dar poate fi necesară pentru procesarea cererii dumneavoastră sau în legătură cu executarea unui contract. Vom păstra datele dumneavoastră cu caracter personal atâta timp cât este necesar pentru scopurile de mai sus, dar nu pentru termenul de prescripție pentru orice revendicări legate de contractul public. Vă rugăm să citiți cu atenție politica de confidențialitate.",
      buttonMore: "citește mai mult",
      buttonLess: "Citiți mai puțin",
      subOne: "condițiile",
      subTwo: "și regulile de protecție a datelor cu caracter personal.",
      buttonTXT: "Vă mulțumim",
      buttonText:
        "Unul dintre consultanții noștri vă va contacta în următoarele câteva zile lucrătoare.",
      buttonText2: "Pentru a fi auzit!",
      privacyLink: "/Privacy_RO.pdf",
      rulesLink: "/TERMENI DE UTILIZARE.pdf",
    },
  },
  footer: {
    privacy: "Confidențialitate",
    privacyLink: "/Privacy_RO.pdf",
    rules: "Reglementări",
    rulesLink: "/TERMENI DE UTILIZARE.pdf",
  },
};
