
import ParamsChecker from "../../ParamsChecker";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { ProductDiv } from "./ProductWrapperCSS";

export function ProductWrapper({children, footerData, navbarData}) {
    return <ProductDiv>
    <ParamsChecker/>
        <Navbar data={navbarData}></Navbar>
        {children}
        <Footer data={footerData}></Footer>
    </ProductDiv>
}