import './ProgressBarBasic.css';
import { ProgressBar } from 'react-bootstrap';

function ProgressBarBasic(props) {
    return (
        <div className="basic-progress-bar-wrapper">
            <span className="basic-progress-label">{props.desc}</span>
            <ProgressBar
                now={props.percent}
                className="basic-progress"
            ></ProgressBar>
        </div>
    );
}

export default ProgressBarBasic;
