import { useState } from 'react';
import Store from '../../../../Store';
import MainButton from '../../Buttons/MainButton/MainButton';
import SecondaryButton from '../../Buttons/SecondaryButton/SecondaryButton';
import MainModal from '../../Modals/MainModal/MainModal';
import './RatePoll.css';
import api from '../../../api/api';

const POLL_ID = 'rate_poll';

function RatePoll({ isOpen, languagePairId, callback }) {
    const [currentView, setCurrentView] = useState(1);
    const [pollAnswers, setPollAnswers] = useState({
        1: null,
        2: null,
        3: null,
        4: null,
    });

    const setAnswer = (number, answer) =>
        setPollAnswers((prev) => ({
            ...prev,
            [number]: answer,
        }));

    const onSend = () => {
        api.post('/poll', {
            pollId: POLL_ID,
            languagePairId,
            pollAnswers,
        }).then(() => callback());
    };

    return (
        <MainModal isModalOpen={isOpen} setIsModalOpen={callback}>
            <div className="rate-poll">
                {currentView === 1 ? (
                    <div>
                        <h2 className="rate-poll__heading">
                            {Store.getText('pollHeading')}
                        </h2>
                        <p className="rate-poll__text">
                            {Store.getText('pollFirstParagraph')}
                        </p>
                        <p className="rate-poll__text">
                            {Store.getText('pollSecondParagraph')}
                        </p>
                        <ol className="rate-poll__question-list">
                            <li className="rate-poll__question-list-item">
                                1. {Store.getText('pollFirstQuestion')}
                                <div className="rate-poll__radio-wrapper">
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <div
                                            className={`rate-poll__radio-item ${
                                                pollAnswers[1] === index + 1
                                                    ? 'rate-poll__radio-item--checked'
                                                    : ''
                                            }`}
                                            key={index}
                                            onClick={() =>
                                                setAnswer(1, index + 1)
                                            }
                                        >
                                            {index + 1}
                                        </div>
                                    ))}
                                </div>
                            </li>
                            <li className="rate-poll__question-list-item">
                                2. {Store.getText('pollSecondQuestion')}
                                <div className="rate-poll__radio-wrapper">
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <div
                                            className={`rate-poll__radio-item ${
                                                pollAnswers[2] === index + 1
                                                    ? 'rate-poll__radio-item--checked'
                                                    : ''
                                            }`}
                                            key={index}
                                            onClick={() =>
                                                setAnswer(2, index + 1)
                                            }
                                        >
                                            {index + 1}
                                        </div>
                                    ))}
                                </div>
                            </li>
                            <li className="rate-poll__question-list-item">
                                3. {Store.getText('pollThirdQuestion')}
                                <div className="rate-poll__radio-wrapper">
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <div
                                            className={`rate-poll__radio-item ${
                                                pollAnswers[3] === index + 1
                                                    ? 'rate-poll__radio-item--checked'
                                                    : ''
                                            }`}
                                            key={index}
                                            onClick={() =>
                                                setAnswer(3, index + 1)
                                            }
                                        >
                                            {index + 1}
                                        </div>
                                    ))}
                                </div>
                            </li>
                        </ol>
                        <div className="rate-poll__buttons-wrapper">
                            <MainButton
                                className="rate-poll__button rate-poll__button-transparent"
                                onClick={callback}

                            >
                                {Store.getText('pollCancelButton')}
                            </MainButton>
                            <SecondaryButton
                                className="rate-poll__button"
                                onClick={() => setCurrentView(2)}
                            >
                                {Store.getText('pollNextButton')}
                            </SecondaryButton>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h2 className="rate-poll__heading">
                            {Store.getText('pollSecondHeading')}
                        </h2>
                        <h3 className="rate-poll__subheading">
                            {Store.getText('pollFourthQuestion')}
                        </h3>
                        <textarea
                            className="rate-poll__textarea"
                            value={pollAnswers[4]}
                            onChange={(e) => setAnswer(4, e.target.value)}
                            placeholder={Store.getText(
                                'pollTextareaPlaceholder'
                            )}
                        ></textarea>
                        <p className="rate-poll__text">
                            {Store.getText('pollThanksParagraph')}
                            <br />
                            {Store.getText('pollThanksSecondParagraph')}
                        </p>
                        <p className="rate-poll__text"></p>
                        <div className="rate-poll__buttons-wrapper">
                            <MainButton
                                className="rate-poll__button rate-poll__button-transparent"
                                onClick={callback}
                            >
                                {Store.getText('pollCancelButton')}
                            </MainButton>
                            <MainButton
                                className="rate-poll__button"
                                onClick={onSend}
                            >
                                {Store.getText('pollSendButton')}
                            </MainButton>
                        </div>
                    </div>
                )}
            </div>
        </MainModal>
    );
}

export default RatePoll;
