export const ArrowIcon = ({scale}) => <svg style={{transform: `scale(${scale})`}} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Arrow left" clip-path="url(#clip0_291_742)">
<path id="Vector" d="M30.7344 21C30.7344 21.9061 29.9999 22.6406 29.0938 22.6406H16.9217L20.5741 26.293C21.2148 26.9337 21.2148 27.9726 20.5741 28.6132C19.9333 29.254 18.8946 29.2538 18.2539 28.6132L11.8008 22.1601C11.1591 21.5183 11.1608 20.48 11.8008 19.8398L18.2539 13.3867C18.8947 12.7461 19.9334 12.746 20.5741 13.3867C21.2148 14.0274 21.2148 15.0663 20.5741 15.7069L16.9217 19.3594H29.0938C29.9999 19.3594 30.7344 20.0939 30.7344 21ZM42 12.1711V29.8289C42 35.4382 38.1961 38.2868 37.9495 38.6363C35.7806 40.8055 32.8965 42 29.8289 42H12.1711C9.1035 42 6.21953 40.8054 4.05046 38.6362C3.80633 38.2903 0 35.4386 0 29.8289V12.1711C0 6.56201 3.80404 3.71306 4.05054 3.36369C6.21953 1.19462 9.10358 0 12.1711 0H29.8289C32.8965 0 35.7806 1.19462 37.9495 3.36377C38.1933 3.70929 42 6.56127 42 12.1711ZM38.7188 12.1711C38.7188 8.06064 35.8787 6.03086 35.6293 5.68395C34.08 4.13446 32.02 3.28125 29.8289 3.28125H12.1711C9.98 3.28125 7.92004 4.13454 6.37071 5.68386C6.11215 6.04332 3.28125 8.03882 3.28125 12.1711V29.8289C3.28125 33.9394 6.12076 35.9687 6.37063 36.3161C7.92004 37.8655 9.98 38.7188 12.1711 38.7188H29.8289C32.02 38.7188 34.08 37.8655 35.6293 36.3161C35.8878 35.9567 38.7188 33.9612 38.7188 29.8289V12.1711Z" fill="var(--main-color)"/>
</g>
<defs>
<clipPath id="clip0_291_742">
<rect width="42" height="42" fill="white"/>
</clipPath>
</defs>
</svg>
