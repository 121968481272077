import React, { useEffect, useState } from 'react';
import { MailBoxConsultantData } from '../../../../consultant/pages/MailBox/customData';
import UserProfile from './UserProfile';
import api from '../../../api/api';
import { getExpertName } from '../helpers/getExpertName';
import moment from 'moment';
import Store from '../../../../Store';
const getTypeText = ({ type }) => {
    switch (type) {
        case 'question':
            return Store.getText('inboxAskExpert');
        case 'activity':
            return Store.getText('inboxTask');
        default:
            return '';
    }
};

export const CONVERSATION_STATUS = {
    NEW: 'New',
    COMPLETED: 'Completed',
    NEW_IN_CONVERSATION: 'New in conversation',
    SEEN: 'Seen',
};

const getRowClassName = ({ userStatus }) => {
    switch (userStatus) {
        case CONVERSATION_STATUS.NEW_IN_CONVERSATION:
            return "malibox-user-element--bold";
        default:
            return '';
    }
};

const MailboxList = ({ setConversation }) => {
    const [mailboxData, setMailboxData] = useState([]);

    const fetchMailboxData = async () => {
        api.get('/conversations').then((response) => {
            setMailboxData(response.data);
        });
    };

    useEffect(() => {
        fetchMailboxData();
    }, []);

    return (
        <>
            <div className="malibox-user-element__top_head_wrapper"  style={{display: 'grid', gridTemplateColumns: '2fr 5fr 3fr', gap: '10px'}}>
                <div className="malibox-user-element__text__top">{Store.getText('inboxSender')}</div>
                <div className="malibox-user-element__text__topic">{Store.getText('inboxSubject')}</div>
                <div
                    className="malibox-user-element__date"
                >
                    {Store.getText('inboxDate')}
                </div>
            </div>
            <div className="malibox-user-element__wrapper">
                {mailboxData.map((element) => (
                    <div
                        className={`malibox-user-element ${getRowClassName(element)}`}
                        onClick={() => setConversation(element)}
                        style={{display: 'grid', gridTemplateColumns: '2fr 5fr 3fr', gap: '10px'}}
                    >
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
                            {' '}
                            <UserProfile />
                            <div className="malibox-user-element__text__top">
                                {getExpertName()}
                            </div>
                        </div>

                        <div className="malibox-user-element__text__bottom">
                        <b>{getTypeText(element)}:</b>{' '}{element.name}
                        </div>
                        <div className="malibox-user-element__date">
                            {moment(element.timestamps.updatedAt).format(
                                'DD-MM-YYYY'
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default MailboxList;
