import { Route, Routes } from "react-router-dom";
import DashboardContent from "./Dashboard";
import LoginPage from "./pages/LoginPage/LoginPage";
import MailBox from "./pages/MailBox/MailBox";

export default (props) => {
  return (
    <Routes>
      <Route index element={<LoginPage {...props} />} />
      <Route path="dashboard" element={<DashboardContent {...props} />} />
      <Route path="mail-box" element={<MailBox {...props} />} />
    </Routes>
  );
};
