import { useEffect, useState } from "react";
import Store from "../../../Store";
import MainModal from "../Modals/MainModal/MainModal";
import "./ActivityFeedback.css";

function ActivityFeedback({ isCorrect, properAnswer, makeAnswer }) {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const onChangeIsModalOpen = (value) => {
    setIsModalOpen(value);
    makeAnswer(isCorrect);
  };

  useEffect(() => {
    if (isCorrect) {
      setTimeout(() => makeAnswer(isCorrect), 1500);
    }
  }, []);

  return isCorrect ? (
    <div className={`activity-feedback`}>
      <p className="activity-feedback__text">{Store.getText("goodAnswer")}</p>
    </div>
  ) : (
    <MainModal
      outerClassName="activity-feedback__modal"
      isModalOpen={isModalOpen}
      setIsModalOpen={onChangeIsModalOpen}
    >
      <div className={`activity-feedback activity-feedback--incorrect-answer`}>
        <p className="activity-feedback__text">{Store.getText("badAnswer")}</p>
      </div>
      <p className="activity-feedback__desc-text">
        {Store.getText("properAnswer")}
      </p>
      <p className="activity-feedback__answer">{properAnswer}</p>
    </MainModal>
  );
}

export default ActivityFeedback;
