import de from "./language-maps/de.png";
import ru from "./language-maps/ru.png";
import es from "./language-maps/es.png";
import fr from "./language-maps/fr.png";
import it from "./language-maps/it.png";
import nl from "./language-maps/nl.png";
import pl from "./language-maps/pl.png";
import en from "./language-maps/en.png";
import pt from "./language-maps/pt.png";

const languageMaps = {
  de,
  ru,
  es,
  fr,
  it,
  nl,
  pl,
  en,
  pt
};

const getLanguageMap = (short) => {
  return languageMaps[short] ? languageMaps[short] : languageMaps.en;
};

export default getLanguageMap;
