import Table from 'rc-table';
import { useEffect, useState } from 'react';
import api from '../../../platform/api/api';
import './WordPairTable.css';
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

import WordPairEditModal from '../../components/WordPairEditModal/WordPairEditModal';
import Store from '../../../Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const queryString = require('query-string');

const PER_PAGE = 50;

const Pagination = ({ offset, limit, allCount, changePage }) => {
    const [currentPage, setCurrentPage] = useState(
        Math.floor(offset / limit) + 1
    );

    useEffect(() => {
        setCurrentPage(Math.floor(offset / limit) + 1);
    }, [offset, limit]);

    const handleBlur = () => {
        if (allCount < 1) return;
        let value = currentPage;
        console.log(value);
        const min = 1;
        const max = Math.ceil(allCount / PER_PAGE);
        console.log(min, max);
        if (value < min) value = min;
        if (value > max) value = max;
        console.log(value);
        changePage(value);
    };

    return (
        <div className="word-pair-table-pagination-wrapper">
            {' '}
            <button
                disabled={offset <= 0}
                onClick={() => changePage(currentPage - 1)}
                className="word-pair-table-pagination-button"
            >
                Poprzednia strona
            </button>
            <div>
                <p>
                    Strona:{' '}
                    <input
                        style={{ width: '30px', textAlign: 'center' }}
                        onChange={(e) => setCurrentPage(e.target.value)}
                        onBlur={handleBlur}
                        value={Math.floor(currentPage)}
                    />{' '}
                    z {Math.ceil(allCount / limit)}
                </p>
                <p>
                    Wyświetlane słowa: {offset + 1} -{' '}
                    {offset + limit < allCount ? offset + limit : allCount} z{' '}
                    {allCount}
                </p>
            </div>
            <button
                disabled={offset + limit >= allCount}
                onClick={() => changePage(currentPage + 1)}
                className="word-pair-table-pagination-button"
            >
                Następna Strona
            </button>
        </div>
    );
};

function WordPairTable({setError}) {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: 'Image',
            dataIndex: 'wordLink',
            key: 'wordLink',
            width: 100,
            render: (row) => {
                return (
                    row?.image?.base64 && (
                        <img
                            style={{ width: '100px', height: 'auto' }}
                            src={row.image.base64}
                        />
                    )
                );
            },
        },
        {
            title: 'Expression from',
            dataIndex: 'wordFrom',
            key: 'wordFrom',
            width: 100,

            render: (row) => {
                return row.expression.text;
            },
        },
        {
            title: 'Sentence from',
            dataIndex: 'sentenceFrom',
            key: 'sentenceFrom',
            width: 100,
        },
        {
            title: 'Expression to',
            dataIndex: 'wordTo',
            key: 'wordTo',
            width: 100,

            render: (row) => {
                return row.expression.text;
            },
        },
        {
            title: 'Sentence to',
            dataIndex: 'sentenceTo',
            key: 'sentenceTo',
            width: 100,
        },
        {
            title: 'Level',
            dataIndex: 'languageLevel',
            key: 'languageLevel',
            width: 50,
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
            width: 50,
        },
        {
            title: 'Category',
            dataIndex: 'addionalData',
            key: 'wordTo',
            width: 100,

            render: (row) => {
                return row.category;
            },
        },
        {
            title: 'Sound processing',
            dataIndex: 'wordTo',
            key: 'wordTo',
            width: 50,
            render: (row) => {
                return <div style={{textAlign: 'center'}}>{row.expression.toProcess || row.sentence.toProcess ? 'x' : ''}</div>;
            },
        },

        {
            title: 'Akcje',
            dataIndex: 'id',
            key: 'id',

            render: (row) => {
                return (
                    <div className="user-table__buttons-wrapper">
                        <button
                            className="user-table__edit-button"
                            onClick={() => {
                                setEditWordPairModalOpen(true);
                                setCurrentWordPair(
                                    wordPairs.find((pair) => pair.id === row)
                                );
                            }}
                        >
                            Edytuj
                        </button>
                        <button
                            className="word-pair-table__sound-button"
                            onClick={() => {
                                setSoundToProcess(row)
                            }}
                        >
                            Ściągnij dźwięk
                        </button>
                    </div>
                );
            },
        },
    ];

    const [wordPairs, setWordPairs] = useState([]);
    const [languagePairs, setLanguagePairs] = useState([]);
    const [lessons, setLessons] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [languagePairId, setLanguagePairId] = useState();
    const [level, setLevel] = useState(1);
    const [lessonNumber, setLessonNumber] = useState();
    const [searchPhrase, setSearchPhrase] = useState();

    const [editWordPairModalOpen, setEditWordPairModalOpen] = useState(false);
    const [currentWordPair, setCurrentWordPair] = useState();

    useEffect(() => {
        loadLanguagePairs();
    }, []);

    const afterUpdate = async () => {
        await load(currentPage);
        setCurrentWordPair();
        setEditWordPairModalOpen(false);
    };

    const changePage = async (value) => {
        setCurrentPage(value);
        await load(value);
    };

    const findWordPairs = async () => {
        setCurrentPage(1);
        await load(1);
    };

    const loadLanguagePairs = async () => {
        const res = await api.get(`/language-pair?version=1`);
        const sorted = res.data.sort((a, b) => a.id - b.id);
        setLanguagePairs(sorted);
        setLanguagePairId(sorted[0].id);
    };

    useEffect(() => {
        if (languagePairId && level) {
            loadLessons(languagePairId, level);
        } 
        setLessonNumber(0)
        
    }, [languagePairId, level]);

    useEffect(() => {
        setLevel(0)        
    }, [languagePairId]);

    const loadLessons = async (languagePairId, level) => {
        const qsObj = {
            languagePairId,
            level,
        };

        const res = await api.get(`/lesson?${queryString.stringify(qsObj)}`);
        const sorted = res.data.sort((lessonA, lessonB) => {
            const numberDiff = lessonA.number - lessonB.number;
            if (numberDiff !== 0) return numberDiff;

            const subNumberDiff = lessonA.subNumber - lessonB.subNumber;
            return subNumberDiff;
        });
        setLessons(sorted);
    };

    const qsCommonParams = {
        languagePairId,
        lessonNumber: parseInt(lessonNumber) && parseInt(lessonNumber.split('.')[0]),
        level: level && level,
        lessonSubNumber: parseInt(lessonNumber) && parseInt(lessonNumber.split('.')[1]),
        version: 2,
        searchPhrase
    }

    const currentLangPair = languagePairs.find(({id}) => id === parseInt(languagePairId))


    const download = (filename, text) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
      }

    const downloadCsv = async () => {
        const res = await api.get(`/word-pair/csv?${queryString.stringify(qsCommonParams)}`);
        const {languageFrom, languageTo} = currentLangPair
        download(`${languageFrom.languageShort}-${languageTo.languageShort}-${Date.now()}.csv`,res.data);
    };

    const load = async (page) => {
        const qsObj = {
            offset: (page - 1) * PER_PAGE,
            limit: PER_PAGE,
           ...qsCommonParams
        };

        const res = await api.get(`/word-pair?${queryString.stringify(qsObj)}`);
        setCount(res.data.count);
        setWordPairs(res.data.wordPairs.map(pair => ({...pair, rate: pair.rates.reduce((accum, rate) => accum + rate.rate, 0)})));
    };

    const setSoundToProcess = async (id) => {
        api.post(`/word-pair/${id}/process-sound`).then(afterUpdate);
    };

    return (
        <div
            style={{
                backgroundColor: `#fff`,
                minHeight: '100vh',
                backgroundSize: 'cover',
            }}
        >
            <div className="word-pair-table">

                {currentWordPair && editWordPairModalOpen && (
                    <WordPairEditModal
                        wordPair={currentWordPair}
                        afterUpdate={afterUpdate}
                        closeModal={() => setEditWordPairModalOpen(false)}
                        setError={setError}
                    />
                )}

                <div>
                    <select
                        value={languagePairId}
                        onChange={(e) => setLanguagePairId(e.target.value)}
                    >
                        {languagePairs.map((pair) => (
                            <option value={pair.id}>
                                {pair.languageFrom.language}-
                                {pair.languageTo.language}
                            </option>
                        ))}
                    </select>
                    <select
                        value={level}
                        onChange={(e) => setLevel(parseInt(e.target.value))}
                    >
                        <option value={0}>All</option>
                        <option value={1}>Poziom 1</option>
                        <option value={2}>Poziom 2</option>
                        <option value={3}>Poziom 3</option>
                    </select>
                    <select
                        value={lessonNumber}
                        onChange={(e) => {
                            setLessonNumber(
                                e.target.value
                            );
                        }}
                    >
                        <option value={0}>All</option>

                        {lessons.map((lesson) => (
                            <option
                                value={lesson.number + '.' + lesson.subNumber}
                            >
                                {lesson.number + '.' + lesson.subNumber}{' '}
                                {Store.getLessonName(
                                    lesson.level,
                                    lesson.number,
                                    1,
                                    currentLangPair.languageTo.languageShort
                                )}
                            </option>
                        ))}
                    </select>
                    <input value={searchPhrase} onChange={(e) => setSearchPhrase(e.target.value)} placeholder="word"/>
                    <button
                        onClick={findWordPairs}
                        className="word-pair-table-button-std"
                    >
                        Znajdź
                    </button>
                </div>
                <div onClick={downloadCsv} className="word-pair-table-button-csv">
                    <FontAwesomeIcon
                        icon={faFileExcel}
                     />

                     <span style={{marginLeft: '5px'}}>csv</span>
                </div>
                <Pagination
                    offset={(currentPage - 1) * PER_PAGE}
                    limit={PER_PAGE}
                    allCount={count}
                    changePage={changePage}
                />

                <Table
                    data={wordPairs}
                    columns={columns}
                    onRow={(row) => {
                        return {
                            onClick: () => {
                                // setCurrentFlashcard(row);
                                // setIsModalOpen(true);
                            },
                        };
                    }}
                ></Table>

                <Pagination
                    offset={(currentPage - 1) * PER_PAGE}
                    limit={PER_PAGE}
                    allCount={count}
                    changePage={changePage}
                />
            </div>
        </div>
    );
}

export default WordPairTable;
