import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";

const Wrapper = styled.div`
  width: 500px;
  margin-bottom: 40px;
  border: 3px solid ${Colors.yellow};
  border-radius: 10px;
`;

const Box = ({ title, text }) => {
  return (
    <Wrapper>
      <div style={{ padding: "15px" }}>
        <p style={{color: Colors.darkBlue, fontWeight: "bold"}}> {title}</p>
        <p style={{ color: Colors.darkBlue }}>{text}</p>
      </div>
    </Wrapper>
  );
};

export default Box;
