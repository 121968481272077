import React, { useCallback, useEffect, useRef, useState } from 'react';
import './MailBox.css';
import { MailBoxConsultantData } from './customData';
import MailBoxTable from './components/MailBoxTable';
import BoxElement from './components/BoxElement';
import api from '../../../platform/api/api';
import { SearchIcon } from './icons/Search';
const queryString = require('query-string');

const MIN_EMAIL_LENGTH = 3;

const PER_PAGE = 50;

const Pagination = ({ offset, limit, allCount, currentPage, changePage }) => {
    return (
        <div className="consultant-mailbox-wrapper">
            {' '}
            <button
                disabled={offset <= 0}
                onClick={() => changePage(currentPage - 1)}
                className="consultant-mailbox-button"
            >
                Poprzednia strona
            </button>
            <div>
                <p>
                    Strona: {currentPage} z {Math.ceil(allCount / limit)}
                </p>
            </div>
            <button
                disabled={offset + limit >= allCount}
                onClick={() => changePage(currentPage + 1)}
                className="consultant-mailbox-button"
            >
                Następna Strona
            </button>
        </div>
    );
};

const MailBox = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [conversation, setConversation] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const includerRef = useRef();

    const handleSearchMails = (e) => {
        e && e.preventDefault();

        const lookingEmails = includerRef.current?.value;

        if (lookingEmails.length < MIN_EMAIL_LENGTH) {
            return;
        }

        setFilteredData(() =>
            data.conversations.filter((d) => d.user.email.includes(lookingEmails))
        );
    };

    const fetchMailboxData = useCallback(() => {
        const qsObj = {
            offset: (currentPage - 1) * PER_PAGE,
            limit: PER_PAGE,
        };
        api.get(
            `/conversations/consultant?${queryString.stringify(qsObj)}`
        ).then((response) => {
            setData(response.data);
            setFilteredData(response.data.conversations);
        });
    }, [currentPage]);

    useEffect(() => {
        if (!conversation) fetchMailboxData();
    }, [conversation, fetchMailboxData]);

    const changePage = async (value) => {
        setCurrentPage(value);
    };

    return (
        <div className="mailbox-wrapper">
            <div className="mailbox-wrapper__top-header">
                <div className="mailbox-conetent__setter"></div>
                <h3 className="mailbox-title">Skrzynka odbiorcza</h3>
                <form
                    className="mailbox-incluer__wrapper"
                    onSubmit={handleSearchMails}
                >
                    <input
                        className="mailbox-includer__input"
                        ref={includerRef}
                        placeholder="Wpisz adres email"
                        required={true}
                    />
                    <button type="submit" className="mailbox-includer__button">
                        <SearchIcon scale={0.7} />
                    </button>
                </form>
            </div>
            {!conversation && (
                <>
                    <MailBoxTable
                        data={filteredData}
                        setConversation={setConversation}
                    />
                    <Pagination
                        offset={(currentPage - 1) * PER_PAGE}
                        limit={PER_PAGE}
                        allCount={data.count}
                        changePage={changePage}
                        currentPage={currentPage}
                    />
                </>
            )}
            {conversation && (
                <BoxElement
                    setConversation={setConversation}
                    conversation={conversation}
                />
            )}
        </div>
    );
};

export default MailBox;
