import "./ActivityButton.css";

function ActivityButton(props) {
  return (
    <button type="button" {...props} className="activity-button">
      {props.children}
    </button>
  );
}

export default ActivityButton;
