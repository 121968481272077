import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
width: 150px;
overflow: hidden;
`

const Image = ({photo, style}) => {
  return (
    <Wrapper style={style}>
            <img
              src={photo}
              style={{ width: "100%", height: "auto" }}
              alt=""
            />
    </Wrapper>
  )
}

export default Image