import Store from '../../../Store';
import './PasswordRecoveryPage.css';
import api from '../../api/api';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import SecondaryButton from '../../components/Buttons/SecondaryButton/SecondaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faXmark } from '@fortawesome/free-solid-svg-icons';

function PasswordRecoveryPage(props) {
    const [inputEmail, setInputEmail] = useState('');
    const [isRecoveryStarted, setIsRecoveryStarted] = useState(false);

    const recover = async (email) =>
        api
            .post('/auth/password-recovery', {
                email: email,
            })
            .then(() => setIsRecoveryStarted(true));

    const handleReset = async (ev) => {
        ev.preventDefault();
        try {
            await recover(inputEmail);
        } catch (err) {
            props.setError(err.message);
        }
    };
    return (
        <div className="password-recovery-page">
            <form
                className="password-recovery-page__form"
                onSubmit={handleReset}
                autoComplete="on"
            >
                <div className="password-recovery-page__buttons-wrapper">
                    <Link to="/">
                        <button
                            type="button"
                            className="password-recovery-page__closing-button"
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </Link>
                    <button
                        type="button"
                        className="password-recovery-page__info-button"
                    >
                        ?
                        <div className="password-recovery-page__info-text-wrapper">
                            <p className="password-recovery-page__info-text">
                                {Store.getText('contactText')}
                                <span className="password-recovery-page__span-block">
                                    {Store.getText('contactEmail')}
                                </span>
                            </p>
                        </div>
                    </button>
                </div>
                <h2 className="password-recovery-page__form-title">
                    {Store.getText('passwordRecoveryHeader')}
                </h2>
                <p className="password-recovery-page__form-slogan">
                    {Store.getText('passwordRecoveryDescription')}
                </p>
                <div className="password-recovery-page__input-wrapper">
                    <label className="password-recovery-page__input-label">
                        {Store.getText('passwordRecoveryEmail')}
                    </label>
                    <input
                        className="password-recovery-page__input"
                        type="email"
                        onChange={(ev) => setInputEmail(ev.target.value)}
                        value={inputEmail}
                    ></input>
                </div>


                <SecondaryButton type="submit" disabled={isRecoveryStarted}>
                    {isRecoveryStarted ?  Store.getText('passwordRecoveryButtonAfterSent') : Store.getText('passwordRecoveryButton')}
                </SecondaryButton>
            </form>
        </div>
    );
}

export default PasswordRecoveryPage;
