import { useEffect, useMemo, useState } from "react";
import api from "../../api/api";
import "./TestPage.css";
import { useNavigate, useParams } from "react-router";
import getLevelColor from "../../common/functions/getLevelColor";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Store from "../../../Store";
import SecondaryButton from "../../components/Buttons/SecondaryButton/SecondaryButton";
import ProgressBarBasic from "../../components/ProgressBar/ProgressBarBasic";
import TestActivityPage from "./TestActivities/TestActivityPage";
import withParamColector from "../../stats/hoc/withParamCollector";

function TestContent({progress, bestTest, ...props}) {
  const params = useParams();

  const [testStatus, setTestStatus] = useState("before");
  const [sequence, setSequence] = useState([])

  const decimalProgress = useMemo(() => {
    return Math.floor((progress.filter(single => single).length / progress.length) * 100)
  }, [progress])

  useEffect(() => {
    api.get(`/test/${params.pairId}/${params.level}/sequence`)
    .then(response => {
      setSequence(response.data)
    })
  }, [])

  if (testStatus == "before") {
    props.setTestTitle(Store.getText("testRulesHeader"));
    return (
      <div className="test-page__test-content">
        <p className="test-page__test-content-text">
          {Store.getText("testWelcome")} {Store.getUsername()}!
        </p>
        <p className="test-page__test-content-text">
          {Store.getText("testDesc").replace('{{level}}', params.level)}
        </p>
        <p className="test-page__test-content-text">
          {Store.getText("testGoodLuck")}
        </p>
        <div className="test-page__test-content-buttons-wrapper">
          <SecondaryButton
            className="test-page__test-content-button"
            onClick={() => {
              setTestStatus("inProgress");
            }}
          >
            {Store.getText("testStartBtn")}
          </SecondaryButton>
        </div>
      </div>
    );
  } else if (testStatus == "inProgress") {
    props.setTestTitle();
    return sequence.length > 0 && <TestActivityPage sequence={sequence} onTestEnd={(progress) => {
      props.onTestEnd(progress)
      setTestStatus("completed");
    }}/>
  } else if (testStatus == "completed") {
    props.setTestTitle(Store.getText("testResultHeader"));
    return (
      <div className="test-page__test-content">
        <p className="test-page__test-content-text">
        {Store.getText("testActivitiesCompleted")}{" "}
          <span className="test-page__test-content-text-span-yellow">
            {progress.filter(single => single).length}/{progress.length}
          </span>
        </p>
        <div className="test-page__progress-bar-wrapper">
          <ProgressBarBasic percent={decimalProgress} desc={`${decimalProgress}%`}></ProgressBarBasic>
        </div>
        <p className="test-page__test-content-text">
        {Store.getText("testRepeatDesc")}
        </p>

        {bestTest && <p className="test-page__test-content-text">
        {Store.getText("testBestScore")}{" "}
          <span className="test-page__test-content-text-span-green">{bestTest.percent}%</span>
        </p>}
        <div className="test-page__test-content-buttons-wrapper">
          {" "}
          <SecondaryButton
            className="test-page__test-content-button test-page__test-content-button--transparent"
            onClick={() => {
              window.location.reload()
            }}
          >
              {Store.getText("testRepeaBtn")}
          </SecondaryButton>
          <Link to={`/platform/lessons/${params.pairId}/${params.level}`}>
          <SecondaryButton className="test-page__test-content-button">
          {Store.getText("testBackBtn")}
          </SecondaryButton>
          </Link>
        </div>
      </div>
    );
  }
}

function TestPage() {
  const [testTitle, setTestTitle] = useState();
  const navigate = useNavigate();
  const params = useParams();
  const level = parseInt(params.level);
  const [progress, setProgress] = useState([]);
  const [bestTest, setBestTest] = useState()

  const onTestEnd = (progress) => {
    setProgress(progress)
    api.post('/test', {
      percent: Math.floor((progress.filter(single => single).length / progress.length) * 100),
      languagePairId: parseInt(params.pairId),
      level: parseInt(params.level)
    })
  }

  useEffect(() => {
    api.get(`/test/${params.pairId}/${params.level}`)
    .then(response => {
      setBestTest(response.data)
    })
  }, [])

  return (
    <div className="test-page">
      <button className="test-page__close-button" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
      <div
        className="test-page__lesson-bar"
        style={{ background: getLevelColor(level) }}
      >
        <h1 className="test-page__lesson-header">
          {Store.getText("testMainHeader")} {level}
        </h1>
      </div>
      <div className="test-page__content-wrapper">
        <div className="test-page__test-title-wrapper">
          <Link to={`/platform/lessons/${params.pairId}/${params.level}`}>
            <button className="test-page__close-button test-page__close-button--mobile">
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </Link>
          {testTitle && <><div
            className="test-page__square-div"
            style={{ background: getLevelColor(level) }}
          ></div>
          <div className="test-page__test-title-div">
            <p className="test-page__test-title">{testTitle}</p>
          </div></>}
        </div>

        <TestContent
          testTitle={testTitle}
          setTestTitle={setTestTitle}
          onTestEnd={onTestEnd}
          progress={progress}
          bestTest={bestTest}
        ></TestContent>

        {/* <div className="test-page__squares-wrapper">{progressComponents}</div> */}
      </div>
    </div>
  );
}

export default withParamColector(TestPage);
