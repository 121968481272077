import "./ChooseLanguageFromPage.css";
import { useEffect, useState } from "react";
import api from "../../api/api";

import { Link,  useNavigate, useSearchParams } from "react-router-dom";
import Store from "../../../Store";



function ChooseLanguageFromPage(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate()
  const load = async () => {
  
      const languagePairAvailable = await api.get("/language-pair/available");
  
      const availableShortFromPairs = [];
  
      languagePairAvailable.data.forEach((pair) => {
        if (availableShortFromPairs.indexOf(pair.languageFrom.languageShort) === -1) availableShortFromPairs.push(pair.languageFrom.languageShort);
      });
      Store.setUserPlatformLanguage(availableShortFromPairs[0]);
      if(searchParams.get('afterRegister'))   navigate(`/platform/${availableShortFromPairs[0]}?afterRegister=1`)
      else navigate(`/platform/${availableShortFromPairs[0]}`)
    
  }

  useEffect(() => {
    load()
  }, []);

  return <div></div>
}

export default ChooseLanguageFromPage;
