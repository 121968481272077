import React, { useContext, useEffect } from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { LanguageContext } from "./common/contexts/LanguageContext";
import Store, { POSSIBLE_LANGS } from "./Store";

const ParamsChecker = () => {
  const { pathname } = useLocation();
  const {lang} = useParams();
  const navigate = useNavigate();
  const {setLang} = useContext(LanguageContext)

  useEffect(() => {
    if (!lang || ![...POSSIBLE_LANGS, 'cz'].includes(lang)) {
      const pathParts = pathname.split("/");
      pathParts[1] = Store.getUserLangShort() === 'cs' ? 'cz' : Store.getUserLangShort()
      navigate(pathParts.join('/'));
    } else if (lang) {
      setLang(lang === 'cz' ? 'cs' : lang)
      Store.setUserLanguage(lang === 'cz' ? 'cs' : lang);
    }
  }, [pathname]);

  return <></>;
};

export default ParamsChecker;
