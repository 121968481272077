import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import YourActivityBox from "../../YourActivityBox/YourActivityBox";
import "./YourActivityModal.css";

function YourActivityModal(props) {
  return (
    <div
      className={
        "your-activity-modal" +
        (props.isModalOpen ? " your-activity-modal--active" : "")
      }
    >
      
      <div
        className="your-activity-modal__closing-div"
        onClick={() => props.setIsModalOpen(false)}
      ></div>
      <div  className="your-activity-modal__activity-box">       <button
          className="your-activity-modal__closing-button"
          type="button"
          onClick={() => props.setIsModalOpen(false)}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="your-activity-modal__closing-button-icon"
          />
        </button>
        <YourActivityBox
        pairId={props.pairId}></YourActivityBox></div>
    
    </div>
  );
}

export default YourActivityModal;
