import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
max-width: 400px;
overflow: hidden;
`

const Image = ({photo}) => {
  return (
    <Wrapper>
            <img
              src={photo}
              style={{ width: "100%", height: "auto" }}
              alt=""
            />
    </Wrapper>
  )
}

export default Image