export const languageDictionary = {
  pl: {
    pl: "Polski",
    hu: "Lengyel",
    sk: 'Poľština',
    cs: "Polština",
    en: "Polish",
    ro: "Poloneză",
  },

  en: {
    pl: "Angielski",
    hu: "Angol",
    sk: 'Angličtina',
    cs: "Angličtina",
    en: "English",
    ro: "Engleză",
  },

  de: {
    pl: "Niemiecki",
    hu: "Német",
    sk: 'Nemčina',
    cs: "Němčina",
    en: "German",

    ro: "Germană",
  },

  es: {
    pl: "Hiszpański",
    hu: "Spanyol",
    sk: 'Španielčina',
    cs: "Španělština",
    en: "Spanish",

    ro: "Spaniolă",
  },

  fr: {
    pl: "Francuski",
    hu: "Francia",
    sk: 'Francúzsky',
    cs: "Francouzština",
    en: "French",

    ro: "Franceză",
  },

  it: {
    pl: "Włoski",
    hu: "Olasz",
    sk: 'Taliančina',
    cs: "Italština",
    en: "Italian",

    ro: "Italiană",
  },

  no: {
    pl: "Norweski",
    hu: "Norvég",
    sk: 'Nórsky',
    cs: "Norský",
    en: "",

    ro: "Norvegian",
  },

  sv: {
    pl: "Szwedzki",
    hu: "Svéd",
    sk: 'Švédsky',
    cs: "Švédský",
    en: "",

    ro: "Suedez",
  },

  nl: {
    pl: "niderlandzki",
    hu: "Holland",
    sk: 'Holandčina',
    cs: "Nizozemština",
    en: "Dutch",

    ro: "Olandeză",
  },

  ru: {
    pl: "Rosyjski",
    hu: "Orosz",
    sk: 'Ruský',
    cs: "Ruština",
    en: "Russian",

    ro: "Rusă",
  },

  cs: {
    pl: "Czeski",
    hu: "Cseh",
    sk: 'Český',
    cs: "Čeština",
    en: "",

    ro: "cCh",
  },

  sk: {
    pl: "Słowacki",
    sk: 'Slovenský',
    cs: "Slovák",
    en: "",
    hu: "Szlovák",

    ro: "Slovacă",
  },

  sk2: {
    pl: "Słowacki",
    sk: 'Slovenský',
    cs: "Slovák",
    en: "",
    hu: "Szlovák",

    ro: "Slovacă",
  },

  pt: {
    pl: "Portugalski",
    sk: 'Portugalčina',
    cs: "Portugalština",
    en: "Portuguese",
    hu: "Portugál",
    ro: "Portugheză",
  },

  hu: {
    pl: "Węgierski",
    cs: "Maďarský",
    sk: "Maďarský",
    hu: "Magyar",
    en: "Hungarian",
    ro: "Maghiară",

  },

  sv: {
    pl: "Szwedzki",
    cs: "Svédský",
    sk: "Švédsky",
    hu: "Svéd",
    en: "Swedish",
    ro: "Suedez",

  },

  no: {
    pl: "Norweski",
    cs: "Norský",
    sk: "Nórsky",
    hu: "Norvég",
    en: "Norwegian",
    ro: "Norvegian",

  },

  el: {
    pl: "Grecki",
    cs: "Recký",
    sk: "Grécky",
    hu: "Görög",
    en: "Greek",
    ro: "Greacă",

  }
};
