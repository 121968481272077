import ProgressBar from "@ramonak/react-progress-bar";
import ColouredSpeechBubble from "../../components/SpeechBubbles/ColouredSpeechBubble/ColouredSpeechBubble";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import api from "../../api/api";
import YourActivityBox from "../../components/YourActivityBox/YourActivityBox";
import "./MainPage.css";
import MainModal from "../../components/Modals/MainModal/MainModal";
import Store from "../../../Store";
import flag from "./dymek_angielski.png";
import getLanguageFlag from "../../common/functions/getLanguageFlag";
import levelOne from "./dymek-lvl1.png";
import levelTwo from "./dymek-lvl2.png";
import levelThree from "./dymek-lvl3.png";
import MainPageDesktop from "./MainPageDesktop/MainPageDesktop";
import MainPageMobile from "./MainPageMobile/MainPageMobile";
import StatsContext from "../../stats/context/statsContext";

function MainPage(props) {
  const [currentLanguagePair, setCurrentLanguagePair] = useState();
  const [currentLevel, setCurrentLevel] = useState();

  const [currentLanguageShort, setCurrentLanguageShort] = useState();

  const [languagesAvailable, setLanguagesAvailable] = useState([]);
  const [notAvailableLanguages, setNotAvailableLanguages] = useState([]);

  const [currentProgress, setCurrentProgress] = useState(0);

  const { setLanguagePairId } = useContext(StatsContext);

  const pageRef = useRef();

  const params = useParams();

  const navigate = useNavigate();

  function handleLogOut() {
    Store.removeToken();
    navigate("/"); // whichever component you want it to route to
  }

  function appendWeatherWidget() {
    const weatherCode = `<div id="ww_f31608fd064da" v='1.20' loc='auto' a='{"t":"horizontal","lang":"en","ids":[],"cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_sot":"celsius","sl_ics":"one_a","font":"Arial","el_phw":3}'>Weather Data Source: <a href="https://sharpweather.com/" id="ww_f31608fd064da_u" target="_blank">sharpweather.com</a></div>`;

    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://srv2.weatherwidget.org/js/?id=ww_f31608fd064da"
    );
    document.getElementById("weather-widget").innerHTML = weatherCode;
    document.getElementById("weather-widget").appendChild(script);
  }

  async function loadData() {
    const lastLesson = await api.get("/lesson/last-lesson");
    const langAvailableRes = await api.get(
      `/language-pair/language-from/${params.languageShort}/available`
    );
    const langAllRes = await api.get(
      `/language-pair/language-from/${params.languageShort}`
    );

    setLanguagesAvailable(langAvailableRes.data);
    const availableIds = langAvailableRes.data.map((lang) => lang.id);
    setNotAvailableLanguages(
      langAllRes.data.filter((lang) => availableIds.indexOf(lang.id) === -1)
    );

    if (lastLesson.data) {
      setCurrentLanguageShort(
        lastLesson.data.languagePair.languageTo.languageShort
      );
      setCurrentLanguagePair(lastLesson.data.languagePair.id);
      setCurrentLevel(lastLesson.data.level);
    } else {
      setCurrentLanguageShort(
        langAvailableRes.data[0].languageTo.languageShort
      );
      setCurrentLanguagePair(langAvailableRes.data[0].id);
      setCurrentLevel(1);
    }
  }

  function selectLanguage(id, level) {
    const language = languagesAvailable.find((lang) => lang.id === id);
    setCurrentLanguageShort(language.languageTo.languageShort);
    setCurrentLanguagePair(language.id);
    setCurrentLevel(level);
    setIsModalOpen(false);
  }

  async function loadProgress(pairId, level) {
    const resLessons = await api.get(
      `/lesson/language-pair/${pairId}/level/${level}`
    );
    let sumProgress = 0;
    resLessons.data.forEach((lesson) => {
      sumProgress += lesson.progress;
    });
    setCurrentProgress((sumProgress * 100) / resLessons.data.length);
  }

  function getBackgroundImageClass(languageShort) {
    let short = languageShort;
    if (["en", "es", "de"].indexOf(short) === -1) short = "en";
    return `main-page__background--${short}`;
  }

  useEffect(() => {
    Store.setUserPlatformLanguage(params.languageShort);
    loadData();
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      appendWeatherWidget();
    }
  }, [pageRef.current]);

  useEffect(() => {
    if (currentLevel && currentLanguagePair) {
      loadProgress(currentLanguagePair, currentLevel);
    }
  }, [currentLevel, currentLanguagePair]);

  useEffect(() => {
    setLanguagePairId(currentLanguagePair);
  }, [currentLanguagePair])

  const [isModalOpen, setIsModalOpen] = useState(false);

  return window.innerWidth > 850 ? (
    <MainPageDesktop setError={props.setError} />
  ) : (
    <MainPageMobile setError={props.setError} />
  );
}

export default MainPage;
