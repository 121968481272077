import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";
import { faRegular } from "@fortawesome/fontawesome-free-regular";

import styledComponents from "styled-components";
import { Colors } from "../../../colors/Colors";


export const PhotoWraper = styledComponents.div`
width: 70px;
height: 70px;
margin-bottom: 10px;
border-radius: 50%;
overflow: hidden;
`;

export const Card = styledComponents.div`
width: 350px;
border-radius: 10px;
background-color: white;

padding: 20px;
height: 380px;
display: flex;
flex-direction: column;

@media (max-width: 930px) {
  width: 320px;
  height: 420px;
}
`;

export const Wraper = styledComponents.div`
display: flex;
align-items: center;
justify-content: center;
height: 380px;
margin-top: 20px;
margin-bottom: 30px;

@media (max-width: 930px) {
  height: 380px;
}
`;

export const StarWraper = styledComponents.div`
display: flex;
gap: 10px;
justify-content: center;
font-size: 24px;
@media (max-width: 930px) {
  font-size: 14px;
}
`;

export const Date = styledComponents.div`
margin-top: -2px;
font-size: 14px;
@media (max-width: 930px) {
  font-size: 10px;
  margin-top: 1px;
}
`;

export const TitleWrapper = styledComponents.div`
color: ${Colors.darkBlue};
text-align: left;
font-weight: 900;
font-size: 1.5em;
width: 100%;
margin-bottom: 0px;
padding-top: 8px;
padding-bottom: 5px;
@media (max-width: 930px) {
  font-weight: bold;
  font-size: 1.1em;
}
`;

export const TextWrapper = styledComponents.p`
margin-bottom: 0px;
@media (max-width: 930px) {
  font-size: 0.9em;
}
`;

const TestimonialElement = ({ data }) => {
  return (
    <Wraper>
      <Card>
        <div style={{display: "flex", justifyContent: "center", width: "100%", marginBottom: "10px"}}>
      <PhotoWraper>
        <img
          style={{
            width: "100%",
            height: "auto",
            marginTop: "10px",
          }}
          src={data.photo}
          alt="photo"
        />
      </PhotoWraper>
      </div>
        <TextWrapper>{data.text}</TextWrapper>

        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: "auto",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              paddingTop: "8px",
            }}
          >
            <TextWrapper>{data.bottomInfo}</TextWrapper>
          </div>
        </div>
      </Card>
     

    </Wraper>
  );
};

export default TestimonialElement;
