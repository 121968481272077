import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import hero from "../../../images/zdj_2.jpg";
import titleCloud from "../../../images/boxes/dymek_nagłówek-krótszy.png";
import Image from "./Image";
import {
  Background,
  Cloud,
  ColorChanger,
} from "../../reusable/exportedStyles/exportedStyles";

const TitleBrand = styled.h1`
  font-weight: lighter;
  font-size: 40px;
  max-width: 200px;
  height: auto;
  padding: 10px 0px 20px 10px;
  margin: 0px;
  padding-left: 8px;
  text-align: center;
  color: ${Colors.darkBlue};
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  background-color: white;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  margin: 50px 20px 50px 20px;
`;

const TextBox = styled.div`
  border: 3px solid ${Colors.yellow};
  padding: 10px;
  max-width: 400px;
  height: 180px;
  margin-top: 20px;
  border-radius: 10px;
`;

const BrandValues = ({ data }) => {
  return (
    <>
      <Background img={hero}>
        <ColorChanger>
          <div style={{ width: "250px", alignSelf: "flex-end"}}>
            <Cloud img={titleCloud}>
              <TitleBrand>{data.title}</TitleBrand>
            </Cloud>
          </div>
        </ColorChanger>
      </Background>
      <Flex>
        <Box >
          {data.part2.brandValuesData.map((data) => {
            return (
              <div >
                <Image photo={data.photo} />
                <TextBox>
                <p style={{ color: Colors.darkBlue , fontWeight: "bold"}}>{data.title}</p>
                  <p style={{ color: Colors.darkBlue }}>{data.text}</p>
                </TextBox>
              </div>
            );
          })}
        </Box>
      </Flex>
    </>
  );
};

export default BrandValues;
