import { useState } from 'react';
import './TaskLessonModal.css';
import api from '../../../../platform/api/api';

function TaskLessonModal({
    lesson,
    closeModal,
    afterUpdate,
    pairId,
    level,
    setError,
}) {
    const [data, setData] = useState(
        lesson
            ? {
                  name: lesson.name,
                  taskPlaceholder: lesson.taskPlaceholder,
                  taskExample: lesson.taskExample,
                  taskContent: lesson.taskContent,
              }
            : {}
    );

    const onInputChange = (value, key) => {
        setData({
            ...data,
            [key]: value,
        });
    };

    const onSave = async () => {
        const toSend = lesson
            ? { ...data }
            : { ...data, pairId: parseInt(pairId), level };

        const url = lesson
            ? `/lesson/task-lesson/${lesson.id}`
            : '/lesson/task-lesson';
        const method = lesson ? 'patch' : 'post';

        try {
            await api[method](url, toSend);
        } catch (e) {
            console.log(e);
            setError(null, 'Something went wrong');
        }
        await afterUpdate();
    };

    return (
        <>
            <div className="task-lesson-modal-hero" onClick={closeModal}></div>

            <div className="task-lesson-modal">
                <label className="task-lesson-modal-label">Number</label>
                <input
                    className="task-lesson-modal-input"
                    value={lesson ? lesson.number : data.number}
                    onChange={(ev) =>
                        onInputChange(parseInt(ev.target.value), 'number')
                    }
                    disabled={!!lesson}
                />
                <label className="task-lesson-modal-label">Name</label>
                <input
                    className="task-lesson-modal-input"
                    value={data.name}
                    onChange={(ev) => onInputChange(ev.target.value, 'name')}
                />

                <label className="word-pair-edit-label">Content</label>

                <textarea
                    className="task-lesson-modal-input"
                    value={data.taskContent}
                    onChange={(ev) =>
                        onInputChange(ev.target.value, 'taskContent')
                    }
                />

                <label className="word-pair-edit-label">Placeholder</label>

                <textarea
                    className="task-lesson-modal-input"
                    value={data.taskPlaceholder}
                    onChange={(ev) =>
                        onInputChange(ev.target.value, 'taskPlaceholder')
                    }
                />

                <label className="word-pair-edit-label">Example</label>

                <textarea
                    className="task-lesson-modal-input"
                    value={data.taskExample}
                    onChange={(ev) =>
                        onInputChange(ev.target.value, 'taskExample')
                    }
                />

                <button onClick={onSave} className="task-lesson-modal-button">
                    Zapisz
                </button>
            </div>
        </>
    );
}

export default TaskLessonModal;
