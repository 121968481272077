import { useEffect, useState } from 'react';
import api from '../../../../platform/api/api';
import { getIsCertificateEnabled } from '../../../../platform/components/Modals/CertificateModal/helpers/getIsCertificateEnabled/getIsCertificateEnabled';
import MainModal from '../../../../platform/components/Modals/MainModal/MainModal';

export const SendCertificateModal = ({
    userId,
    users,
    setIsModalOpen,
    isModalOpen,
    afterUpdate,
}) => {
    const [name, setName] = useState('');
    const [languagePairs, setLanguagePairs] = useState([]);
    const [languagePairId, setLanguagePairId] = useState();

    useEffect(() => {
        Promise.all([
            api.get(`/language-pair?version=1`),
        ]).then(([resV1]) => {
            const sorted = [
                ...resV1.data.sort((a, b) => a.id - b.id),
            ].filter((pair) => getIsCertificateEnabled(pair.languageFrom.languageShort))

            setLanguagePairs(sorted);
            setLanguagePairId(sorted[0]?.id);
        });
    }, []);

    const onSendCert = async (e) => {
        e.preventDefault();

        console.log({
            name,
            languagePairId,
            userId,
        })
        await api.post(`/certificate/create-by-admin`, {
            name,
            languagePairId,
            userId,
        });
        setIsModalOpen(false)
        setName('')
        await afterUpdate();
    };

    return (
        <MainModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}>
            <form onSubmit={onSendCert}>
                {userId && (
                    <p>{users.find((user) => user.id === userId).email}</p>
                )}
                <input
                    placeholder="imię i nazwisko"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                ></input>
                <select
                    value={languagePairId}
                    onChange={(e) => setLanguagePairId(parseInt(e.target.value))}
                >
                    {languagePairs.map((pair) => (
                        <option value={pair.id}>
                            {pair.languageFrom.language}-
                            {pair.languageTo.language}
                        </option>
                    ))}
                </select>
                <button
                    type="submit"
                    className="user-table-button-std"
                    disabled={!name}
                >
                    Wyślij
                </button>
            </form>
        </MainModal>
    );
};
