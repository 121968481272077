import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import hero from "../../../images/zdj_2.jpg";
import titleCloud from "../../../images/boxes/dymek_nagłówek-krótszy.png"
import cloud from "../../../images/kariera/kariera-2.png"
import { Background, Cloud, ColorChanger } from "../../reusable/exportedStyles/exportedStyles";

const H1 = styled.div`
font-weight: lighter;
font-size: 40px;
max-width: 100px;
height: auto;
padding: 10px 10px 20px 20px;
text-align: center;
color: ${Colors.darkBlue};
`

const GridBox = styled.div`
max-width: 100vw;
display: grid;
justify-content: center;
margin-top: 40px; 
margin-bottom: 40px;

@media (max-width: 600px){
  display: flex;
  flex-direction: column;
  justify-content: center;
}
`

const P = styled.p`
max-width: 800px;
text-align: center;
color: ${Colors.darkBlue};
font-weight: bold;
font-style: italic;

@media (max-width: 600px){
  margin: 0 10px;
}
`
const Title = styled.h1`
  font-weight: lighter;
  font-size: 40px;
  max-width: 100px;
  height: auto;
  padding: 10px 0px 20px 10px;
  margin: 0px;
  padding-left: 8px;
  text-align: center;
  color: ${Colors.darkBlue};
`;

const P2 = styled.p`
@media (max-width: 600px){
  font-size: 14px;
}
`

const Mission = ({data}) => {
  return (
    <>
      <Background img={hero} >
        <ColorChanger>
            <div style={{alignSelf: "flex-end"}}>
              <Cloud img={titleCloud} >
              <Title>{data.title}</Title>
              </Cloud>
            </div>
        </ColorChanger>
      </Background>
      <GridBox>
        <P>
        {data.text1}
        </P>
        <div style={{marginBottom: "40px"}}>
        <div style={{maxWidth: "500px", float: "right",margin: "40px 10px 0 10px" }}>
          <Cloud img={cloud}>
          <div style={{display: "flex", gap: "30px", padding: "10px", alignItems: "center"}}>
          <div>
            <p style={{color: Colors.darkBlue, fontSize: "50px", fontWeight: "bold"}}>1,2</p>
          </div>
          <div>
            <h4 style={{fontWeight: "bold"}}>{data.title2}</h4>
            <P2 > {data.textTitle2}</P2>
          </div>
          </div>
          </Cloud>
        </div>
        </div>
        <div style={{maxWidth: "500px", margin: "0 10px 40px 10px"}}>
          <Cloud img={cloud}>
          <div style={{ padding: "15px"}}>
          <h4 style={{fontWeight: "bold"}}>{data.title3}</h4>
            <P2 style={{color: Colors.darkBlue}}>{data.textTitle3}</P2>
          </div>
          </Cloud>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
        <p style={{ maxWidth: "500px", textAlign: "center", color: Colors.darkBlue }}>
        {data.textBottom}
        </p>
        </div>
      </GridBox>
    </>
  );
};

export default Mission;
