export const SearchIcon = ({scale}) => <svg style={{transform: `scale(${scale})`}} width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Magnifying glass" clip-path="url(#clip0_312_133)">
<g id="Group">
<path id="Vector" d="M19.3666 3.20382C14.9426 -1.06762 7.74224 -1.06762 3.31825 3.20382C-1.10478 7.47619 -1.10478 14.4273 3.31825 18.6997C7.25793 22.5026 13.3938 22.91 17.8149 19.9405C17.9079 20.3655 18.1208 20.7711 18.4632 21.1016L24.9059 27.3222C25.8448 28.2269 27.362 28.2269 28.2961 27.3222C29.234 26.4166 29.234 24.9518 28.2961 24.049L21.8534 17.8265C21.5129 17.4987 21.0919 17.2922 20.6517 17.2024C23.7292 12.9328 23.3072 7.0095 19.3666 3.20382ZM17.3325 16.7358C14.0296 19.9247 8.65427 19.9247 5.35234 16.7358C2.05137 13.5468 2.05137 8.3577 5.35234 5.1687C8.65427 1.98063 14.0296 1.98063 17.3325 5.1687C20.6354 8.3577 20.6354 13.5468 17.3325 16.7358Z" fill="var(--light-main-color2)"/>
</g>
</g>
<defs>
<clipPath id="clip0_312_133">
<rect width="29" height="28" fill="white"/>
</clipPath>
</defs>
</svg>
