import { createContext } from 'react';

const noop = () => {};

const defaultContext = {
    setLanguagePairId: noop,
    setLessonId: noop,
    setLevel: noop,
};

const StatsContext = createContext(defaultContext);

export default StatsContext;